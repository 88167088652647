import React, { useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { Container } from 'react-bootstrap';
import Constant from '../../networking/Constant'

import { useIntl } from 'react-intl';

function FailedPublicEvent(props) {
  const intl = useIntl();


  useEffect(() => {

    fetch(Constant.IP_BACKEND_DNS + '/updatePublicEventStatus',{
      method:'POST',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
      body:JSON.stringify({paymentToken: props.match.params.token, price: props.match.params.price, name: props.match.params.name, idEvent: props.match.params.idEvent, source:props.match.params.source, status: 'false', method: "Stripe"})
    }).then(response=>response.json()).then(res=>{
      if(res.error === false){
        console.log("G")
      } else {
        console.log("F")
      }
    })
  })


  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
      <Fade>
      <div style={{backgroundColor: "#F6F7FB", paddingTop: 180, paddingBottom: 40}}>
        <Container className="shadow" style={{backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
        <h1>{intl.formatMessage({id: 'CancelPayment'})}</h1>
        </Container>
      </div>

      </Fade>
    </div>
  );
}

export default FailedPublicEvent;

import React, { useEffect, useState,useRef } from 'react';
import './Sponsor.css';
import {Row, Container, Col, Image} from 'react-bootstrap';
import Constant from '../../networking/Constant'
import { connect } from 'react-redux';
import fetch from 'node-fetch'
import { useIntl } from 'react-intl';
import 'bootstrap/dist/css/bootstrap.min.css'

function Sponsor(props) {
  const intl = useIntl();

  const [image, setImage] = useState(null)
  const [temp, setTemp] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let isMounted = true; // Ajouter le flag

    const getSponsor =  () => {
      fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/GetAllSponsor/small', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
      }).then(response => response.json()).then(res => {
        if (isMounted) { // Vérifier le flag ici
          if (res.error === true) {
            setLoading(false)
          } else if (res.error === false) {
            setImage(res.data)
            setTemp(res.data)
            startFilterSponsor(res.data)
            setLoading(false)
          }
        }
      }).catch((e) => {
        if (isMounted) { // Vérifier le flag ici
          // Traitez l'erreur comme vous le souhaitez
        }
      });
    }

    if (image === null && temp === null)
      getSponsor();
    // Fonction de nettoyage
    return () => {
      isMounted = false; // Réinitialiser le flag
    };
  }, [image, temp]);

/*
  const getSponsor =  () => {
    fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/GetAllSponsor/small' ,{
      method:'GET',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
    }).then(response=>response.json()).then(res=>{
      if(res.error === true){
        setLoading(false)
      }
      else if(res.error === false){
        setImage(res.data)
        setTemp(res.data)
        startFilterSponsor(res.data)
        setLoading(false)
      }
    }).catch((e) => {
    })
    }*/

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    }

    const prevAmount = usePrevious(localStorage.getItem('region'))

    const startFilterSponsor = async (data) => {
      setLoading(true)
      if(data)
        var newArray = data
      else
        var newArray = temp
      newArray = newArray.filter((item) => item.RegionB19.includes(localStorage.getItem('region')))
      setLoading(false)
      setImage(newArray)
    }

  return (
    <Container className="App" style={{paddingBottom: 20, backgroundColor: 'white', fontFamily: 'Typold Regular'}}>

    <h3 style={{marginTop: 80, marginBottom: 30}}>{intl.formatMessage({id: 'Part'})}</h3>

    <Row style={{display: 'flex', justifyContent:'center', marginBottom: 20}}>
      {image && image.filter(res => res.Category === "Platinium").map((item, index) => {
        return(
          <Col xl={2} lg={2} md={3} sm={3} xs={6} key={index}>
            <a href={item.Link}>
              <Image style={{objectFit: 'contain', width: "100%"}} src={item.Logo}  />
          </a>
        </Col>
        )
      })}
    </Row>
    <Row style={{display: 'flex', justifyContent:'center', marginBottom: 15}}>
      {image && image.filter(res => res.Category !== "Platinium").map((item, index) => {
        return(
          <Col xl={2} lg={2} md={3} sm={3} xs={6} key={index}>
            <a href={item.Link} style={{width: "100%"}}>
              <Image style={{objectFit: 'cover', width: "70%"}} src={item.Logo}  />
            </a>
          </Col>
        )
      })}
    </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    region : state._state_reducer_home_page.region,
    firstTime : state._state_reducer_home_page.firstTime
  }
}

export default connect(mapStateToProps)(Sponsor);

import React, { useState, useRef, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import Constant from '../../networking/Constant'
import { CircularProgress } from '@material-ui/core';
import Navigation from '../navBar/NavDark'
import Sponsor from '../sponsor/Sponsor'
import Footer from '../footer/Footer'
import Condition from '../../image/ConditionsGenerales_B19-merged.pdf'
import { loadStripe } from '@stripe/stripe-js';
import { useIntl } from 'react-intl';
import './Subscription.css'
import { AiOutlineCheck } from "react-icons/ai";



const stripePromiseBxl = loadStripe('pk_live_51J4hxHL0SWLMklZ3E53r2s856WjJNqxvY0ElyxyQQm8c62OAXkBDqKc1AmuN9auWfldnDdV0Oe0uCjYqR03Ffo2100uIY1TDh3');
//const stripePromise = loadStripe('pk_test_51J4hxHL0SWLMklZ3MgLaZGEqlDloUu4AMByjzBiI9d4PtY8idpytAHddFYCbs40FmSyx99pMmDHT9c6xcSrv4Xic00kTljgnSm');
const stripePromiseLuik = loadStripe('pk_live_51JCOAJEbzcAf2J36UHWIEnEMW0euy8JWvuhjLUtvAfq3iP5K2UqsKuhS7FECNvCfEwS8IZhW8vCbPyCxkq0mWnUt00BjxzCUih');
const stripePromiseLux = loadStripe('pk_live_51JMVepEXYjxp2I0yYzxmdPpezg4Y7P1dpcOID8tNswUwzTvLNwpE9aTVJXtGiGLB1gw0PuIXLUifI7milrZ7nemh00qcKghPGM');
const ApiKeyGeocoding = "AIzaSyDVQOr0Gcg3-No7G7OF2Rn75iWasIEKCUY"


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

/*
var addressValidator = require('address-validator');
const Config = require('../../config')

const Geocoding = async (address) => {
  addressValidator.setOptions({
    key: Config.ApiKeyGeocoding,
  });

  var promise = await new Promise((resolve, reject) => {
    addressValidator.validate(address, addressValidator.match.unknown, function(err, exact, inexact){
      if (err)
        resolve({error: true, message: err})
      else
        resolve({error: false, data: {ExactMacth: exact, InexactMatch: inexact}, message: "success"})
    });
  })
  return (promise)
}
*/

function MyVerticallyCenteredModal(props) {
  const { language } = useParams();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.intl.formatMessage({id: 'AskForAdhesion'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({id: 'Succes'})}</h4>
        <p style={{marginTop: 25}}>
          {props.intl.formatMessage({id: 'InscriptionSucces'})}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button href={`/login/${language}`}style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={props.onHide}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModalBusiness(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.intl.formatMessage({id: 'AskForAdhesion'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({id: 'Succes'})}</h4>
        <p style={{marginTop: 25}}>
          {props.intl.formatMessage({id: 'InscriptionSuccesBusiness'})}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button href="www.b19.be"style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={props.onHide}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Subscription(props) {

const intl = useIntl();

const location = useLocation();

const { language } = useParams();

const refSelectSubscritpion = useRef(null)
const refPrivate = useRef(null)
const refColleague = useRef(null)
const refFacturation = useRef(null)
const refMotivation = useRef(null)
const refScroll = useRef(null);

const executeScrollSubscription = () => refSelectSubscritpion.current.scrollIntoView()
const executeScrollPrivate = () => refPrivate.current.scrollIntoView()
const executeScrollColleague = () => refColleague.current.scrollIntoView()
const executeScrollFacturation = () => refFacturation.current.scrollIntoView()
const executeScrollMotivation = () => refMotivation.current.scrollIntoView()
const executeScroll = () => refScroll.current.scrollIntoView()

const [tvaRight, setTvaRight] = useState(false)
const [validatedTVA, setValidatedTVA] = useState(false)
const [tvaCountry, setTvaCountry] = useState("BE")
const [tvaNumber, setTvaNumber] = useState("")
const [loadingTva, setLoadingTva] = useState(false)

const [motivation, setMotivation] = useState(null)


const [loading, setLoading] = useState(false)
const [validated, setValidated] = useState(false);

const [form, setForm] = useState('false')
const [bill, setBill] = useState(null)
const [modalShow, setModalShow] = useState(false);
const [modalShowBusiness, setModalShowBusiness] = useState(false);

const [redirectLogin, setRedirectLogin] = useState(false)
const [check, setCheck] = useState(true)
const [error, setError] = useState({
  network: "",
  registrationType: "",
  firstName: "",
  lastName: "",
  email: "",
  date: "",
  tel: "",
  society: "",
  tva: "",
  tvaFacturation: "",
  zipCode: "",
  city: "",
  streetNumber: "",
  street: "",
  region: "",
  firstNameColleague1: "",
  lastNameColleague1: "",
  emailColleague1: "",
  fistNameColleague2: "",
  lastNameColleague2: "",
  emailColleague2: "",
  fistNameColleague3: "",
  lastNameColleague3: "",
  emailColleague3: "",
  checkBoxEngagement: "",
  checkBoxGeneralConditionOfUse: "",
  facturationZipCode: "",
  facturationCity: "",
  facturationStreet: "",
  facturationStreetNumber: "",
})

const [registrationType, setRegistrationType] = useState(null)

const [formularyTva ,setFormularyTva] = useState(false)

const [gender, setGender] = useState(null)
const [firstName, setFirstName] = useState(null)
const [lastName, setLastName] = useState(null)
const [email, setEmail] = useState(null)
const [day, setDay] = useState(null)
const [mounth, setMounth] = useState(null)
const [year, setYear] = useState(null)

const [tel, setTel] = useState(null)
const [society, setSociety] = useState(null)
const [tva, setTva] = useState(null)
const [zipCode, setZipCode] = useState(null)
const [city, setCity] = useState(null)
const [street, setStreet] = useState(null)
const [streetNumber, setStreetNumber] = useState(null);
const [region, setRegion] = useState(null)
const [sponsor, setSponsor] = useState(null)

const [firstNameColleague1, setFirstNameColleague1] = useState(null)
const [lastNameColleague1, setLastNameColleague1] = useState(null)
const [emailColleague1, setEmailColleague1] = useState(null)

const [firstNameColleague2, setFirstNameColleague2] = useState(null)
const [lastNameColleague2, setLastNameColleague2] = useState(null)
const [emailColleague2, setEmailColleague2] = useState(null)

const [firstNameColleague3, setFirstNameColleague3] = useState(null)
const [lastNameColleague3, setLastNameColleague3] = useState(null)
const [emailColleague3, setEmailColleague3] = useState(null)

const [method, setMethod] = useState(null)
const [checkBoxEngagement, setCheckBoxEngagement] = useState(false)

const [facturation, setFacturation] = useState(false)
const [facturationSociety, setFacturationSociety] = useState(null)
const [facturationStreetNumber, setFacturationStreetNumber] = useState(null)
const [facturationStreet, setFacturationStreet] = useState(null)
const [facturationZipCode, setFacturationZipCode] = useState(null)
const [facturationCity, setFacturationCity] = useState(null)
const [facturationTva, setFacturationTva] = useState(null)

const [tvaCountryFacturation, setTvaCountryFacturation] = useState("BE")
const [vatNumberFacturation, setVatNumberFacturation] = useState(null)
const [tvaNumberFacturation, setTvaNumberFacturation] = useState("")
const [validatedTVAFacturation, setValidatedTVAFacturation] = useState(false)
const [responseApiFacturation, setResponseApiFacturation] = useState(false)
const [countryFacturation, setCountryFacturation] = useState(null)
const [typeTvaFacturation, setTypeTvaFacturation] = useState(null)
const [loadingTvaFacturation, setLoadingTvaFacturation] = useState(false)
const [productOrderFacturation, setProductOrderFacturation] = useState(null)

const [typeContactFacturation, setTypeContactFacturation] = useState('Society')
const [emailFacturationTo, setEmailFacturationTo] = useState(null)
const [productOrder, setProductOrder] = useState(null)
const [country, setCountry] = useState(null)
const [typeTva, setTypeTva] = useState(null)
const [vatNumber, setVatNumber] = useState(null)
const [responseApi, setResponseApi] = useState(false)


const [from, setFrom] = useState("")
const [trust, setTrust] = useState(false)


const [checkBoxGeneralConditionOfUse, setCheckBoxGeneralConditionOfUse] = useState(false)

const [checkParrain, setCheckParrain] = useState(false)

const { setLocale } = props;

useEffect(() => {
  const query = new URLSearchParams(location.search);
  const parrainParam = query.get('parrain');
 
  if (parrainParam) {
    setSponsor(parrainParam);
    setCheckParrain(true);
  }
  if (language === "fr") {
    setLocale(language);
  } else if (language === "nl") {
    setLocale(language);
  }
}, [location, language, setLocale]);

const selectRegistrationType = (pack) => {
  setRegistrationType(pack)
  if(pack === "Young"){
    setBill(null)
    setFacturation(false)
    setCheckBoxEngagement(false)}
}

const selectPayment = (info) => {
  setMethod(info)
}

const setFormulary = (info) => {
  setFormularyTva(null)
  if (info === 'business')
    setForm(true)
  else
    setForm(false)
}

const _checkIfTva = (type) => {
  if (type === 'true'){
    setFormularyTva('true')
    setTypeContactFacturation('Society')
  }
  else if (type === 'false'){
    setFormularyTva('false')
    setTypeContactFacturation('Private')
  }
}

const setBilling = (info) => {
  if (info === 'Bill')
    setBill('Bill')
  else
    setBill('Stripe')
}

const setInfoTvaError = () => {
  setCountry("BE")
  setTypeTva("eu_vat")
  _setFormatTva ()
}

const tvaAPI = async () => {
    setError({tva : ""})
    var onlyNumber = tvaNumber.replace(/\D/g, "");
    if (onlyNumber.length !== 10){
      setError({tva : "ErrorTva"})
      setLoading(false)
      setLoadingTva(false)
      return ;
    }
    fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/GetVat' ,{
      method:'POST',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
      body:JSON.stringify({Vat: tvaNumber, VatCountry: tvaCountry})
    }).then(response=>response.json()).then(res=>{
      if(res.error === false){
        // mauvais num tva => CountryCode or Vat is invalid
        // api down => Service vat unavailable
        if (res.data.address && res.data.address.streetNumber !== undefined){
          setStreetNumber(res.data.address.streetNumber)
        }
        //console.log("API TVA", res.data)
        setResponseApi(true)
        setCountry(res.data.country_code)
        setTypeTva(res.data.type)
        setCity(res.data.address.city)
        setStreet(res.data.address.street)
        setZipCode(res.data.address.postalCode)
        setSociety(res.data.name)
        setLoading(false)
        setTvaRight(true)
        setLoadingTva(false)
        _setFormatTva ()
        setResponseApi(true)
        setTrust(true)
      }else if (res.error === true && res.message === "One argument or more are not valid") {
        setError({tva : "ErrorTva"})
        setLoading(false)
        setTvaRight(true)
        setLoadingTva(false)
        setResponseApi(true)
        setInfoTvaError()
        return ;
      }
      else if (res.error === true && res.message === "CountryCode or Vat is invalid"){
        setError({tva : "ErrorTva"})
        setLoading(false)
        setTvaRight(true)
        setLoadingTva(false)
        setResponseApi(true)
        setInfoTvaError()
        return ;
      } else if (res.error === true && res.message === "Service vat unavailable") {
        setError({tva : "ErrorTvaApi"})
        setLoading(false)
        setTvaRight(true)
        setLoadingTva(false)
        setResponseApi(true)
        setInfoTvaError()
        return ;
      }
      else {
        setError({tva : "ErrorTvaApi"})
        setLoading(false)
        setTvaRight(true)
        setLoadingTva(false)
        setResponseApi(true)
        setInfoTvaError()
        return ;
      }
    }).catch((e) => {
      setLoading(false)
      setTvaRight(true)
      setLoadingTva(false)
      setResponseApi(true)
      setInfoTvaError()
      setError({tva: "ErrorTvaApi"})
    })
}

const setInfoTvaFacturationError = () => {
  setCountryFacturation("BE")
  setTypeTvaFacturation("eu_vat")
  _setFormatTvaFacturation ()
}

const tvaAPIFacturation = async () => {
  setError({tvaFacturation : ""})

  var onlyNumber = tvaNumberFacturation.replace(/\D/g, "");
  if (onlyNumber.length !== 10){
    setError({tvaFacturation : "ErrorTva"})
    setLoadingTvaFacturation(false)
    return;
  }


  fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/GetVat' ,{
    method:'POST',
    headers:{
    'Content-Type': 'application/json',
    'Accept': 'application/json'},
    body:JSON.stringify({Vat: tvaNumberFacturation, VatCountry: tvaCountryFacturation})
  }).then(response=>response.json()).then(res=>{
    if(res.error === false){
      // mauvais num tva => CountryCode or Vat is invalid
      // api down => Service vat unavailable
      if (res.data.address && res.data.address.streetNumber !== undefined){
        setFacturationStreetNumber(res.data.address.streetNumber)
      }
      setResponseApiFacturation(true)
      setCountryFacturation(res.data.country_code)
      setTypeTvaFacturation(res.data.type)
      setFacturationCity(res.data.address.city)
      setFacturationStreet(res.data.address.street)
      setFacturationZipCode(res.data.address.postalCode)
      setFacturationSociety(res.data.name)
      setLoadingTvaFacturation(false)
      _setFormatTvaFacturation ()
    }else if (res.error === true && res.message === "One argument or more are not valid") {
      setError({tvaFacturation : "ErrorTva"})
      setLoadingTvaFacturation(false)
      setResponseApiFacturation(true)
      _setFormatTvaFacturation ()
      return ;
    }
    else if (res.error === true && res.message === "CountryCode or Vat is invalid"){
      setError({tvaFacturation : "ErrorTva"})
      setLoadingTvaFacturation(false)
      setResponseApiFacturation(true)
      _setFormatTvaFacturation ()
      return ;
    } else if (res.error === true && res.message === "Service vat unavailable") {
      setError({tvaFacturation : "ErrorTvaApi"})
      setLoadingTvaFacturation(false)
      setResponseApiFacturation(true)
      _setFormatTvaFacturation ()
      return ;
    }
    else {
      setError({tvaFacturation : "ErrorTvaApi"})
      setLoadingTvaFacturation(false)
      setResponseApiFacturation(true)
      _setFormatTvaFacturation ()
      return ;
    }
  }).catch((e) => {
    setLoadingTvaFacturation(false)
    setResponseApiFacturation(true)
    _setFormatTvaFacturation ()
    setError({tvaFacturation: "ErrorTvaApi"})
  })
}

if(redirectLogin === true){
  return <Redirect push to={`/login`}/>
}

const _checkArg = () => {
  if(registrationType === null){
    setError({registrationType : "RegistrationTypeNotSelect"})
    executeScrollSubscription()
    setLoading(false)
    return (1)}
  if (registrationType === "Business"){
    var emailColleagueClean = []
    var emailColleague = [emailColleague1, emailColleague2, emailColleague3, email];
    for (var i = 0; i < emailColleague.length; i++){
      if (emailColleague[i] !== null && emailColleague[i] !== "")
        emailColleagueClean.push(emailColleague[i])
    }
    var emailColleagueWithoutDouble = [...new Set(emailColleagueClean)]

    if (emailColleagueWithoutDouble.length !== emailColleagueClean.length){
      setError({email: "DoubleEmail"})
      executeScrollPrivate()
      return (1);
    }
  }
  setError({email: ""})

    return (0)
}

const _setFormatTva = () => {
  try{
    var country = tvaCountry.toUpperCase()
    var countryWithoutSpace = country.replace(/[\W_]/g, '');
    setTvaCountry(countryWithoutSpace)
    if(countryWithoutSpace === "BE"){
      var onlyNumber = tvaNumber.replace(/\D/g, "");
      if (onlyNumber.length !== 10)
        return('ERREUR')
      var result = onlyNumber.substring(0,4) + "." + onlyNumber.substring(4,7) + "." + onlyNumber.substring(7);
      setVatNumber(result)
    } /*else {
      var onlyNumber = tvaNumber.replace(/\D/g, "");
      setVatNumber(tvaNumber)
    }*/
  }catch(e){
    setError({network: "ErrorNetwork"})
  }
}

const _setFormatTvaFacturation = () => {
  try{
    var country = tvaCountryFacturation.toUpperCase()
    var countryWithoutSpace = country.replace(/[\W_]/g, '');
    setTvaCountryFacturation(countryWithoutSpace)
    if(countryWithoutSpace === "BE"){
      var onlyNumber = tvaNumberFacturation.replace(/\D/g, "");
      if (onlyNumber.length !== 10)
        return('ERREUR')
      var result = onlyNumber.substring(0,4) + "." + onlyNumber.substring(4,7) + "." + onlyNumber.substring(7);
      setVatNumberFacturation(result)
    } /*else {
      var onlyNumber = tvaNumberFacturation.replace(/\D/g, "");
      setVatNumberFacturation(onlyNumber)
    }*/
  }catch(e){
    setError({network: "ErrorNetwork"})
  }
}

const registerYoung = () => {
  setLoading(true)
  fetch(Constant.IP_BACKEND_DNS + '/register',{
    method:'POST',
    headers:{
    'Content-Type': 'application/json',
    'Accept': 'application/json'},
    body:JSON.stringify({
      registrationType: registrationType,
      motivation: motivation,
      firstName : firstName,
      lastName: lastName,
      email : email,
      day : day,
      mounth : mounth,
      year : year,
      tel: tel,
      society : society,
      tva: tva,
      zipCode : zipCode,
      city: city,
      street : street,
      region: region,
      sponsor: sponsor,
      colleague: [
        {
        firstName: firstNameColleague1,
        lastName: lastNameColleague1,
        email: emailColleague1,
        society: society,
        },
        {
        firstName: firstNameColleague2,
        lastName: lastNameColleague2,
        email: emailColleague2,
        society: society,
        },
        {
        firstName: firstNameColleague3,
        lastName: lastNameColleague3,
        email: emailColleague3,
        society: society,
        },
    ],
      method: method,
      facturation: facturation,
      facturationSociety: facturationSociety,
      facturationTva: facturationTva,
      checkBoxEngagement : checkBoxEngagement,
      facturationZipCode: facturationZipCode,
      facturationCity: facturationCity,
      facturationStreet: facturationStreet,
      checkBoxGeneralConditionOfUse: checkBoxGeneralConditionOfUse,
      language: intl.locale,
      })
  }).then(response=>response.json()).then(res=>{
    if(res.error === false && bill === 'Stripe')
       payment(res.data.accesToken)
    else if(res.error === false && (registrationType === 'Young' || bill === 'Bill')){
      setModalShow(true)
    }
    else{
      setLoading(false)
      setError({
        registrationType: res.message.registrationType,
        firstName: res.message.firstName,
        lastName: res.message.lastName,
        email: res.message.email,
        date: res.message.date,
        tel: res.message.tel,
        society: res.message.society,
        tva: res.message.tva,
        zipCode: res.message.zipCodePrivate,
        city: res.message.cityPrivate,
        street: res.message.streetPrivate,
        region: res.message.region,
        firstNameColleague1: res.message.firstNameColleague1,
        lastNameColleague1: res.message.lastNameColleague1,
        emailColleague1: res.message.emailColleague1,
        firstNameColleague2: res.message.firstNameColleague2,
        lastNameColleague2: res.message.lastNameColleague2,
        emailColleague2: res.message.emailColleague2,
        firstNameColleague3: res.message.firstNameColleague3,
        lastNameColleague3: res.message.lastNameColleague3,
        emailColleague3: res.message.emailColleague3,
        method: res.message.method,
        facturationZipCode: res.message.facturationZipCode,
        facturationCity: res.message.facturationCity,
        facturationStreet: res.message.facturationStreet,
        checkBoxEngagement: res.message.checkBoxEngagement,
        checkBoxGeneralConditionOfUse: res.message.checkBoxGeneralConditionOfUse,
      })
      if(res.message.checkMotivation === 1)
        executeScrollMotivation()
      else if(res.message.checkPrivate === 1)
        executeScrollPrivate()
      else if(res.message.checkColleague === 1)
        executeScrollColleague()
      else if(res.message.checkFacturation === 1)
        executeScrollFacturation()
      return ;
    }
  }).catch((e) => {
    setLoading(false)
    setError({network: "ErrorNetwork"})
  })
}

const register = () => {
    if(_checkArg() === 1)
      return;
    var tvaConc = tvaCountry + vatNumber
    var tvaConcFacturation = tvaCountryFacturation + vatNumberFacturation
    //var streetConc = streetNumber + " " + street
    setLoading(true)
    fetch(Constant.IP_BACKEND_DNS + '/registerV2',{
      method:'POST',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
      body:JSON.stringify({
        registrationType: registrationType,
        gender: gender,
        firstName : firstName,
        lastName: lastName,
        email : email,
        day : day,
        mounth : mounth,
        year : year,
        tel: tel,
        society : society,
        tva: tvaConc,
        zipCode : zipCode,
        city: city,
        streetNumber: streetNumber,
        street : street,
        region: region,
        sponsor: sponsor,
        colleague: [
          {
          firstName: firstNameColleague1,
          lastName: lastNameColleague1,
          email: emailColleague1,
          society: society,
          },
          {
          firstName: firstNameColleague2,
          lastName: lastNameColleague2,
          email: emailColleague2,
          society: society,
          },
          {
          firstName: firstNameColleague3,
          lastName: lastNameColleague3,
          email: emailColleague3,
          society: society,
          },
      ],
        method: method ? method : " ",
        facturation: facturation,
        country: country,
        typeTva: typeTva,
        countryFacturation: countryFacturation,
        typeTvaFacturation: typeTvaFacturation,
        vatNumber: vatNumber,
        vatNumberFacturation: vatNumberFacturation,
        emailFacturationTo: emailFacturationTo,
        productOrder: productOrder,
        productOrderFacturation: productOrderFacturation,
        typeContactFacturation: typeContactFacturation,
        facturationSociety: facturationSociety ? facturationSociety : society,
        facturationTva: countryFacturation ? tvaConcFacturation : tvaConc,
        checkBoxEngagement : checkBoxEngagement,
        facturationZipCode: facturationZipCode ? facturationZipCode : zipCode,
        facturationCity: facturationCity ? facturationCity : city,
        facturationStreetNumber: facturationStreetNumber,
        facturationStreet: facturationStreet,
        checkBoxGeneralConditionOfUse: checkBoxGeneralConditionOfUse,
        language: intl.locale,
        from: from
        })
    }).then(response=>response.json()).then(res=>{
    //  console.log("RESSSSSS", res)
      if(res.error === false && registrationType === "SingleA")
        window.lintrk('track', { conversion_id: 7704404 })

      if(res.error === false && registrationType === "business")
        window.lintrk('track', { conversion_id: 7704412 })

      if(res.error === false && bill === 'Stripe')
         payment(res.data.accesToken)
      else if(res.error === false && (registrationType === 'Young' || bill === 'Bill')){
        setModalShow(true)
      }
      else{
        setLoading(false)
        setError({
          registrationType: res.message.registrationType,
          firstName: res.message.firstName,
          lastName: res.message.lastName,
          email: res.message.email,
          date: res.message.date,
          tel: res.message.tel,
          society: res.message.society,
          tva: res.message.tva,
          zipCode: res.message.zipCodePrivate,
          city: res.message.cityPrivate,
          streetNumber: res.message.streetPrivateNumber,
          street: res.message.streetPrivate,
          region: res.message.region,
          firstNameColleague1: res.message.firstNameColleague1,
          lastNameColleague1: res.message.lastNameColleague1,
          emailColleague1: res.message.emailColleague1,
          firstNameColleague2: res.message.firstNameColleague2,
          lastNameColleague2: res.message.lastNameColleague2,
          emailColleague2: res.message.emailColleague2,
          firstNameColleague3: res.message.firstNameColleague3,
          lastNameColleague3: res.message.lastNameColleague3,
          emailColleague3: res.message.emailColleague3,
          method: res.message.method,
          facturationZipCode: res.message.facturationZipCode,
          facturationCity: res.message.facturationCity,
          facturationStreet: res.message.facturationStreet,
          facturationStreetNumber: res.message.facturationStreetNumber,
          checkBoxEngagement: res.message.checkBoxEngagement,
          checkBoxGeneralConditionOfUse: res.message.checkBoxGeneralConditionOfUse,
        })
        if(res.message === "Trouble with PutNewUser")
          setError({network: "ErrorNetwork"})
        if(res.message.checkRegistrationType === 1)
          executeScrollSubscription()
        else if(res.message.checkPrivate === 1)
          executeScrollPrivate()
        else if(res.message.checkColleague === 1)
          executeScrollColleague()
        else if(res.message.checkFacturation === 1)
          executeScrollFacturation()
        return ;
      }
    }).catch((e) => {
      //console.log("CATACACTCAT", e)
      setLoading(false)
      setError({network: "ErrorNetwork"})
    })
}

const payment = async (accesToken) => {
  let stripe = null
  if(region === "bxl" || region === "anv" || region === "fl"){
    stripe = await stripePromiseBxl;
  }
  if (region === "luik"){
    stripe = await stripePromiseLuik;
  }
  if (region === "lux"){
    stripe = await stripePromiseLux;
  }
  const response = await fetch(Constant.IP_BACKEND_DNS + '/payment', { method: 'POST',
  headers:{
  'Content-Type': 'application/json',
  'Accept': 'application/json'},
  body:JSON.stringify({email: email, accesToken: accesToken, registrationType: registrationType, region: region})
 });
  const session = await response.json();
  if (session.error === true) {
      setLoading(false)
      alert(intl.formatMessage({id: "ErrorHappened" }))
  }
  const result = await stripe.redirectToCheckout({
    sessionId: session.id,
  });
  if (result.error) {
      setLoading(false)
      alert(intl.formatMessage({id: "ErrorHappened" }))
  }
};

const registerBusiness = () => {
  setLoading(true)
    fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/ContactSubscription',{
      method:'POST',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
      body:JSON.stringify({
       email: email,
       tel: tel,
       first_name: firstName,
       last_name: lastName,
       region: region,
        })
    }).then(response=>response.json()).then(res=>{
     // console.log("resAPI", res)
      if(res.error === false){
        setModalShowBusiness(true)
      }
       else{
        setError({
          firstName: res.message.FirstName,
          lastName: res.message.LastName,
          email: res.message.Email,
          tel: res.message.Tel,
          region: res.message.Region,
        })
       }
       setLoading(false)
    }).catch((e) => {
     // console.log("CATACACTCAT", e)
      setLoading(false)
      setError({network: "ErrorNetwork"})
    })
}

const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (registrationType === "Young")
      registerYoung()
    else if (registrationType === "Business")
      registerBusiness()
    else
      register()
    setCheck(true)
    if (registrationType === "Young")
      setCheck(false)
    if (form.checkValidity() === false)
      event.stopPropagation();
    setValidated(true);
  };

  const handleSubmitTVA = async (event) => {
      setLoadingTva(true)
      event.preventDefault();
      const form = event.currentTarget;
      tvaAPI()
      if (form.checkValidity() === false)
        event.stopPropagation();
      setValidatedTVA(true);
    };

  const handleSubmitTVAFacturation = async (event) => {
  setLoadingTvaFacturation(true)
  event.preventDefault();
  const form = event.currentTarget;
  tvaAPIFacturation()
  if (form.checkValidity() === false)
    event.stopPropagation();
  setValidatedTVAFacturation(true);
  };

  const _renderTypeOfSubscription = () => {
    return(
    <>
    <h6 style={{textAlign: 'left', color:'#FC8016'}}>{intl.formatMessage({id: "TypeOfInscription" })}</h6>
    <p style={{textAlign: 'left', color:'red'}}>{intl.formatMessage({id: error.registrationType ? error.registrationType : "Space" })}</p>
  
      <Row>
      <Col xl={4} lg={12} md={12} sm={12} style={{marginTop: 20}}>
        <div style={{borderRadius: '20px', height: '100%', border: registrationType === "SingleA" ? '1.5px solid #FC8016': '1.5px solid #E6E6E6', padding: 20} }>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <p style={{textAlign: 'left', fontSize: '1.5rem'}}>Single</p>         
          </div>
         
            <p style={{textAlign: 'left'}}>{intl.formatMessage({id: "BeneficiezAcces" })}</p>
            <p style={{textAlign: 'left'}}><span style={{fontSize: '1.8rem'}}>600€</span>/{intl.formatMessage({id: "Year" })}</p>
            <div style={{width: '100%'}}>
            <Button
                onClick={() => (setFormulary("SingleA"), selectRegistrationType("SingleA"), executeScroll())}
                style={{
                  backgroundColor: "#082331",
                  color: "#082331",
                  borderWidth: 0,
                  padding: 10,
                  fontFamily: "Typold Bold",
                  color: "white",
                  width: '100%'
                }}
              >
                {intl.formatMessage({id: "Selectionner" })}
              </Button>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 30}}>
            <AiOutlineCheck/>
            <p style={{margin: 0, padding: 0, marginLeft: 25, textAlign: 'left'}}>1 membership</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
            <AiOutlineCheck/>
            <p style={{margin: 0, padding: 0, marginLeft: 25, textAlign: 'left'}}>{intl.formatMessage({id: "MeerDan" })}</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
            <AiOutlineCheck/>
            <p style={{margin: 0, padding: 0, marginLeft: 25, textAlign: 'left'}}>{intl.formatMessage({id: "AcessMobile" })}</p>
            </div>
          </div>
        </Col>
        <Col xl={4} lg={12} md={12} sm={12} style={{marginTop: 20}}>
        <div style={{borderRadius: '20px', height: '100%', border: registrationType === "Business" ? '1.5px solid #FC8016': '1.5px solid #E6E6E6', padding: 20}}>
            <p style={{textAlign: 'left', fontSize: '1.5rem'}}>Business</p>
            <p style={{textAlign: 'left'}}>{intl.formatMessage({id: "BusinessDescript" })}</p>
            <p style={{textAlign: 'left'}}><span style={{fontSize: '1.8rem'}}>{intl.formatMessage({id: "PriceDemande" })}</span></p>
            <div style={{width: '100%'}}>
            <Button
                onClick={() => (setFormulary("business"), selectRegistrationType("Business"), executeScroll())}
                style={{
                  backgroundColor: "#082331",
                  color: "#082331",
                  borderWidth: 0,
                  padding: 10,
                  fontFamily: "Typold Bold",
                  color: "white",
                  width: '100%'
                }}
              >
                {intl.formatMessage({id: "Selectionner" })}
              </Button>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 30}}>
            <AiOutlineCheck/>
            <p style={{margin: 0, padding: 0, marginLeft: 25, textAlign: 'left'}}>4 memberships (minimum)</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
            <AiOutlineCheck/>
            <p style={{margin: 0, padding: 0, marginLeft: 25, textAlign: 'left'}}>{intl.formatMessage({id: "MeerDan" })}</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
            <AiOutlineCheck/>
            <p style={{margin: 0, padding: 0, marginLeft: 25, textAlign: 'left'}}>{intl.formatMessage({id: "AcessMobile" })}</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
            <AiOutlineCheck/>
            <p style={{margin: 0, padding: 0, marginLeft: 25, textAlign: 'left'}}>{intl.formatMessage({id: "VisibleMag" })}</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
            <AiOutlineCheck/>
            <p style={{margin: 0, padding: 0, marginLeft: 25, textAlign: 'left'}}>{intl.formatMessage({id: "InvitePeople" })}</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
            <AiOutlineCheck/>
            <p style={{margin: 0, padding: 0, marginLeft: 25, textAlign: 'left'}}>And more…</p>
            </div>
            
          </div>
        </Col>  
        <Col xl={4} lg={12} md={12} sm={12} style={{marginTop: 20}}>
          <div style={{borderRadius: '20px', height: '100%', border: registrationType === "Young" ? '1.5px solid #FC8016': '1.5px solid #E6E6E6', padding: 20}}>
            <p style={{textAlign: 'left', fontSize: '1.5rem'}}>Young</p>
            <p style={{textAlign: 'left'}}>{intl.formatMessage({id: "UnderTwentyFive" })}</p>
            <p style={{textAlign: 'left'}}><span style={{fontSize: '1.8rem'}}>0€</span>/{intl.formatMessage({id: "Year" })}</p>
            <div style={{width: '100%'}}>
            <Button
                onClick={() => (setFormulary("Young"), selectRegistrationType("Young"), executeScroll())}
                style={{
                  backgroundColor: "#082331",
                  color: "#082331",
                  borderWidth: 0,
                  padding: 10,
                  fontFamily: "Typold Bold",
                  color: "white",
                  width: '100%'
                }}
              >
                {intl.formatMessage({id: "Selectionner" })}
              </Button>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 30}}>
            <AiOutlineCheck/>
            <p style={{margin: 0, padding: 0, marginLeft: 25, textAlign: 'left'}}>1 membership</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
            <AiOutlineCheck/>
            <p style={{margin: 0, padding: 0, marginLeft: 25, textAlign: 'left'}}>{intl.formatMessage({id: "MeerDan" })}</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
            <AiOutlineCheck/>
            <p style={{margin: 0, padding: 0, marginLeft: 25, textAlign: 'left'}}>{intl.formatMessage({id: "AcessMobile" })}</p>
            </div>
          </div>
        </Col>
    
      </Row>
      
      </>
    )
  }

  const _renderTvaQuestion = () => {
    return (
      <Fade>
        <Row style={{marginTop: 20}}>
          <Col xl={3} lg={3} sm={12} md={12}>
            <div style={{display: 'flex', textAlign: 'left', alignItems: 'center'}}>
              <p style={{fontWeight: 'bold'}}>{intl.formatMessage({id: 'HaveYouTva'})}</p>
            </div>
            <Form>
              {['radio'].map((type) => (
                <div key={`inline-${type}`} style={{textAlign: 'left'}}>
                  <Form.Check
                  onClick={() => _checkIfTva('true')}
                    inline
                    label={intl.formatMessage({id: 'Yes'})}
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label={intl.formatMessage({id: 'No'})}
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  onClick={() => _checkIfTva('false')}
                  />
                </div>
              ))}
            </Form>
          </Col>
        </Row>
      </Fade>
    )
  }

  const _renderTvaApi = () => {
    return (
      <Form noValidate validated={validatedTVA} onSubmit={handleSubmitTVA} style={{marginTop: 25}}>
      <Row>
        <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "NumeroTva"})}<span style={{color: 'red'}}> *</span></p>
        <Col xl={1} lg={2} sm={2} md={2} style={{margin: 2}}>
          <Form.Control
          required
          value={tvaCountry}
          
          />
        </Col>
        <Col xl={5} lg={5} sm={6} md={4} style={{margin: 2}}>
          <Form.Control
          required
          value={tvaNumber}
          placeholder="0123"
          onChange={(event) => {setResponseApi(false) ; setTvaNumber(event.target.value)}}
          />
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.tva ? error.tva : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.tva ? error.tva : "Space" })}
          </Form.Control.Feedback>
        </Col>

        <Col xl={3} lg={3} sm={6} md={6} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 2}}>
        <Button
        type="submit"
        style={{backgroundColor: '#082331', fontSize: "0.90rem", color: "white", fontWeight: 'bold', padding: 8,  border: "none"}}
        >
        {
          loadingTva === true ?
          <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
              <CircularProgress color="inherit" size={20}/>
            </div>
            <div>
              <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: "Verify"})}</p>
            </div>
          </div>
          :
          <p style={{margin: 0}}>{intl.formatMessage({id: "Verify"})}</p>
        }
        </Button>
        </Col>
      </Row>
    </Form>
    )
  }

  const _renderTvaApiFacturation = () => {
    return (
      <Form noValidate validated={validatedTVAFacturation} onSubmit={handleSubmitTVAFacturation} style={{marginTop: 25}}>
      <Row>
        <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "NumeroTva"})}<span style={{color: 'red'}}> *</span></p>
        <Col xl={1} lg={2} sm={2} md={2} style={{margin: 2}}>
          <Form.Control
          required
          value={tvaCountryFacturation}
          placeholder="BE"
          onChange={(event) => setTvaCountryFacturation(event.target.value)}
          />
        </Col>
        <Col xl={5} lg={5} sm={6} md={4} style={{margin: 2}}>
          <Form.Control
          required
          value={tvaNumberFacturation}
          placeholder="0123"
          onChange={(event) => {setResponseApiFacturation(false) ; setTvaNumberFacturation(event.target.value)}}
          />
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.tvaFacturation ? error.tvaFacturation : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.tvaFacturation ? error.tvaFacturation : "Space" })}
          </Form.Control.Feedback>
        </Col>

        <Col xl={3} lg={3} sm={6} md={6} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 2}}>
        <Button
        type="submit"
        style={{backgroundColor: '#082331', fontSize: "0.90rem", color: "white", fontWeight: 'bold', padding: 8,  border: "none"}}
        >
        {
          loadingTvaFacturation === true ?
          <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
              <CircularProgress color="white" size={20}/>
            </div>
            <div>
              <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: "Verify"})}</p>
            </div>
          </div>
          :
          <p style={{margin: 0}}>{intl.formatMessage({id: "Verify"})}</p>
        }
        </Button>
        </Col>
      </Row>
    </Form>
    )
  }

  const _renderFacturationInfo = () => {
    return (
      <>
      {_renderTvaApiFacturation()}
      {
        responseApiFacturation === true ?
        <>
        <Row>
          <Form.Group as={Col} xl={8} lg={6} sm={12} md={12} controlId="formGridSociety" >
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Societe"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required = {check}
            type="text"
            placeholder={intl.formatMessage({id: "EntrezSociete"})}
            value={facturationSociety}
            onChange={(event) => setFacturationSociety(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.setFacturationSociety ? error.setFacturationSociety : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.setFacturationSociety ? error.setFacturationSociety : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col} xl={1} lg={6} sm={12} md={12} controlId="formGridCity">

          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "NumeroRue"})}<span style={{color: 'red'}}> *</span></p>
          <Form.Control
          required
          value={facturationStreetNumber}
          placeholder="00"
          onChange={(event) => setFacturationStreetNumber(event.target.value)}
          />
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.facturationStreetNumber ? error.streetNumber : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.facturationStreetNumber ? error.streetNumber : "Space" })}
          </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={7} lg={6} sm={12} md={12} controlId="formGridCity">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "AdressePostale"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            placeholder={intl.formatMessage({id: "EntrezAdressePostal"})}
            value={facturationStreet}
            onChange={(event) => setFacturationStreet(event.target.value)}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationStreet ? error.street : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationStreet ? error.street : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridZip">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "CodePostal"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="number"
            placeholder={intl.formatMessage({id: "EntrezCodePostal"})}
            value={facturationZipCode}
            onChange={(event) => setFacturationZipCode(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationZipCode ? error.zipCode : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationZipCode ? error.zipCode : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridCity">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Ville"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            placeholder={intl.formatMessage({id: "EntrezVille"})}
            value={facturationCity}
            onChange={(event) => setFacturationCity(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationCity ? error.city : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationCity ? error.city : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          </Row>

          {/*VERIFIER SI EMAIL VALIDEEEEEEEEEEEEE*/}
          <Row>
            <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridCity">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "EnterPo"})}<span style={{color: 'red'}}> </span></p>
              <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({id: "Po"})}
              value={productOrderFacturation}
              onChange={(event) => setProductOrderFacturation(event.target.value)}/>
            </Form.Group>
          </Row>
        </>
        :
        <>
        </>
      }
      </>
    )
  }

  const _renderParticular = () => {
    return(
      <Fade> 
      <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 15}}>
        <Row>
          <Form.Group as={Col} xl={1} lg={6} sm={12} md={12} controlId="formGridCity">

          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "NumeroRue"})}<span style={{color: 'red'}}> *</span></p>
          <Form.Control
          required
          value={streetNumber}
          placeholder="00"
          onChange={(event) => setStreetNumber(event.target.value)}
          />
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.streetNumber ? error.streetNumber : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.streetNumber ? error.streetNumber : "Space" })}
          </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={7} lg={6} sm={12} md={12} controlId="formGridCity">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "AdressePostale"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            placeholder={intl.formatMessage({id: "EntrezAdressePostal"})}
            value={street}
            onChange={(event) => setStreet(event.target.value)}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.street ? error.street : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.street ? error.street : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridZip">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "CodePostal"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="number"
            placeholder={intl.formatMessage({id: "EntrezCodePostal"})}
            value={zipCode}
            onChange={(event) => setZipCode(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.zipCode ? error.zipCode : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.zipCode ? error.zipCode : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridCity">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Ville"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            placeholder={intl.formatMessage({id: "EntrezVille"})}
            value={city}
            onChange={(event) => setCity(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.city ? error.city : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.city ? error.city : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          </Row>
          
          <Row>
          <Form.Group as={Col} xl={8} lg={6} sm={12} md={12} controlId="formGridCity">
          <p style={{textAlign: 'left', marginTop: 10, fontWeight: 'bold'}}>{intl.formatMessage({id: "MaRegion"})}<span style={{color: 'red'}}> *</span></p>

                 <Form.Control value={region ? region : ""} required as="select" onChange={(event) => setRegion(event.target.value)}>
                   <option hidden value="">
                    {intl.formatMessage({id: "RegionIndiquez"})}
                   </option>
                   <option value="bxl">
                    {intl.formatMessage({id: "Bxl"})}
                   </option>
                   <option value="luik">
                    {intl.formatMessage({id: "Luik"})}
                   </option>
                   {/*
                     <option value="anv">
                     {intl.formatMessage({id: "Anv"})}
                   </option>
                     */}
                   <option value="fl">
                     {intl.formatMessage({id: "Fl"})}
                   </option>
             
             </Form.Control>
             <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
               {intl.formatMessage({id: error.region ? error.region : "Space" })}
             </Form.Control.Feedback>
             <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
               {intl.formatMessage({id: error.region ? error.region : "Space" })}
             </Form.Control.Feedback>
            </Form.Group>

            </Row>
{/*
 <Row>
            <Form.Group as={Col} xl={8} lg={6} sm={12} md={12} controlId="formGridSponsor">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "ParrainMarraine"})}</p>
              <Form.Control
              type="text"
              placeholder={intl.formatMessage({id: "EntrezParrainMarraine"})}
              value={sponsor}
              onChange={(event) => setSponsor(event.target.value)}/>
            </Form.Group>
          </Row>
*/}
           

          <Row>
          <Form.Group as={Col} xl={8} lg={6} sm={12} md={12} controlId="formGridFrom">
          <p style={{textAlign: 'left', marginTop: 10, fontWeight: 'bold'}}>{intl.formatMessage({id: "Source"})}</p>
                 <Form.Control value={from ? from : ""} required as="select" onChange={(event) => setFrom(event.target.value)}>
                  <option hidden value="">
                    
                   </option>
                   <option value="Ichec">
                    {intl.formatMessage({id: "Ichec"})}
                   </option>
                   <option value="David Loyd">
                    {intl.formatMessage({id: "DavidLloyd"})}
                   </option>
                   <option value="Nathalie Ducobu">
                    {intl.formatMessage({id: "NathalieDucobu"})}
                   </option>
                  <option value="John Bogaerts">
                     {intl.formatMessage({id: "JohnBogaerts"})}
                   </option>
                   <option value="MeetDistrict">
                     {intl.formatMessage({id: "MeetDistrict"})}
                   </option>
                   <option value="Autres">
                     {intl.formatMessage({id: "Autres"})}
                   </option>
             </Form.Control>
            </Form.Group>

            </Row>

          <div style={{marginTop: 30}}>
          <h6 style={{textAlign: 'left', color:'#FC8016'}}>{intl.formatMessage({id: "MoyenPaiement"})}</h6>
          <p style={{textAlign: 'left', color:'red', marginTop: 10}}>{intl.formatMessage({id: error.method ? error.method : "Space" })}</p>
          <fieldset>
          <Form.Group as={Row}>
            <Col>

              <div style={{textAlign: 'left', marginTop: 20}}>
                <Form.Check
                  onClick={() => setBilling("Stripe")}
                  required
                  type="radio"
                  label={intl.formatMessage({id: "PaiementImmediat"})}
                  name="formHorizontalRadios"
                  id="formHorizontalRadios1"
                  onChange={() => selectPayment("Stripe")}
                />
              </div>

              <div style={{textAlign: 'left', marginTop: 20}}>
                <Form.Check
                  onClick={() => setBilling("Bill")}
                  required
                  type="radio"
                  label={intl.formatMessage({id: "Facture"})}
                  name="formHorizontalRadios"
                  id="formHorizontalRadios2"
                  onChange={() => selectPayment("Billing")}
                />
            </div>
            </Col>
          </Form.Group>
          </fieldset>

          </div>

        <div style={{marginTop: 40}}>
        <h6 style={{textAlign: 'left', color:'#FC8016'}}>{intl.formatMessage({id: "Condition"})}</h6>
        <p style={{textAlign: 'left', color:'red', marginTop: 10}}>{intl.formatMessage({id: error.checkBoxGeneralConditionOfUse ? error.checkBoxGeneralConditionOfUse : "Space" })}</p>
        <p style={{textAlign: 'left', color:'red', marginTop: 10}}>{intl.formatMessage({id: error.checkBoxEngagement ? error.checkBoxEngagement : "Space" })}</p>
        {
          bill === 'Bill' ?
          <div style={{textAlign: 'left'}}>
          <input
          type="checkbox"
          id="exampleCheck1"
          onChange={() => setCheckBoxEngagement(!checkBoxEngagement)}/>
        <label style={{color: 'black', marginLeft: 10, fontWeight: 'bold'}}>{intl.formatMessage({id: "EngagementFacture"})}</label>
          </div>
          :
          <>
          </>
        }
        <div style={{textAlign: 'left', marginTop: 15}}>
        <input
          type="checkbox"
          id="exampleCheck2"
          onChange={() => setCheckBoxGeneralConditionOfUse(!checkBoxGeneralConditionOfUse)}/>
        <label style={{marginLeft: 10}}>{intl.formatMessage({id: "LuAccepteCondition1"})}<span>   <a href={Condition} target="_blank" style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>{intl.formatMessage({id: "LuAccepteCondition2"})}</a> </span> {intl.formatMessage({id: "LuAccepteCondition3"})}</label>
        </div>
        </div>

        <div className="centerButton" style={{marginBottom: 15}}>
          <p style={{textAlign: 'center', color:'red', fontWeight: 'bold', marginTop: 20}}>{intl.formatMessage({id: error.network ? error.network : "Space" })}</p>
        <Button
        type="submit"
        style={{marginTop: 40, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0,}}
        >
        {
          loading === true ?
          <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
              <CircularProgress color="white" size={20}/>
            </div>
            <div>
              <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: "Continuer"})}</p>
            </div>
          </div>
          :
          <p style={{margin: 0}}>{intl.formatMessage({id: "Continuer"})}</p>
        }
        </Button>

        <MyVerticallyCenteredModal
          show={modalShow}
          intl={intl}
          onHide={() => setModalShow(false)}
        />

        </div>
      


      </Form>
      </Fade>
    )
  }

  const _renderCompany = () => {
    return(
      <>
   
       {_renderTvaApi()}
       
       {
         responseApi === false ?
         <>
         </>
         :
        <>
        <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 25}}>

        {tvaRight === true ?
          <Fade>
          <Row>
          <Form.Group as={Col} xl={8} lg={6} sm={12} md={12} controlId="formGridSociety" >
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Societe"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required = {check}
            type="text"
            placeholder={intl.formatMessage({id: "EntrezSociete"})}
            value={society}
            onChange={(event) => setSociety(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.society ? error.society : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.society ? error.society : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col} xl={1} lg={6} sm={12} md={12} controlId="formGridCity">

          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "NumeroRue"})}<span style={{color: 'red'}}> *</span></p>
          <Form.Control
          required
          value={streetNumber}
          placeholder="00"
          onChange={(event) => setStreetNumber(event.target.value)}
          />
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.streetNumber ? error.streetNumber : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.streetNumber ? error.streetNumber : "Space" })}
          </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={7} lg={6} sm={12} md={12} controlId="formGridCity">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "AdressePostale"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            placeholder={intl.formatMessage({id: "EntrezAdressePostal"})}
            value={street}
            onChange={(event) => setStreet(event.target.value)}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.street ? error.street : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.street ? error.street : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridZip">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "CodePostal"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="number"
            placeholder={intl.formatMessage({id: "EntrezCodePostal"})}
            value={zipCode}
            onChange={(event) => setZipCode(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.zipCode ? error.zipCode : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.zipCode ? error.zipCode : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridCity">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Ville"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            placeholder={intl.formatMessage({id: "EntrezVille"})}
            value={city}
            onChange={(event) => setCity(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.city ? error.city : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.city ? error.city : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          </Row>

          {/*VERIFIER SI EMAIL VALIDEEEEEEEEEEEEE*/}
          <Row>
            <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridZip">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "EnterEmailFacturationTo"})}<span style={{color: 'red'}}></span></p>
              <Form.Control
              required
              type="email"
              placeholder={intl.formatMessage({id: "EmailFacturationTo"})}
              value={emailFacturationTo}
              onChange={(event) => setEmailFacturationTo(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.emailFacturationTo ? error.emailFacturationTo : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.emailFacturationTo ? error.emailFacturationTo : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridCity">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "EnterPo"})}<span style={{color: 'red'}}> </span></p>
              <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({id: "Po"})}
              value={productOrder}
              onChange={(event) => setProductOrder(event.target.value)}/>
            </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col} xl={8} lg={6} sm={12} md={12} controlId="formGridCity">
          <p style={{textAlign: 'left', marginTop: 10, fontWeight: 'bold'}}>{intl.formatMessage({id: "MaRegion"})}<span style={{color: 'red'}}> *</span></p>

                <Form.Control value={region ? region : ""} required as="select" onChange={(event) => setRegion(event.target.value)}>
                  <option hidden value="">
                    {intl.formatMessage({id: "RegionIndiquez"})}
                  </option>
                  <option value="bxl">
                    {intl.formatMessage({id: "Bxl"})}
                  </option>
                  <option value="luik">
                    {intl.formatMessage({id: "Luik"})}
                  </option>
                  {/*
                    <option value="anv">
                    {intl.formatMessage({id: "Anv"})}
                  </option>
                    */}
                  <option value="fl">
                    {intl.formatMessage({id: "Fl"})}
                  </option>
          
            </Form.Control>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.region ? error.region : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.region ? error.region : "Space" })}
            </Form.Control.Feedback>
            </Form.Group>

            </Row>

            <Row>
            <Form.Group as={Col} xl={8} lg={6} sm={12} md={12} controlId="formGridSponsor">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "ParrainMarraine"})}</p>
              <Form.Control
              type="text"
              placeholder={intl.formatMessage({id: "EntrezParrainMarraine"})}
              value={sponsor}
              onChange={(event) => setSponsor(event.target.value)}/>
            </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col} xl={8} lg={6} sm={12} md={12} controlId="formGridFrom">
          <p style={{textAlign: 'left', marginTop: 10, fontWeight: 'bold'}}>{intl.formatMessage({id: "Source"})}</p>
                 <Form.Control value={from ? from : ""} required as="select" onChange={(event) => setFrom(event.target.value)}>
                 <option hidden value="">
                    
                    </option>
                   <option value="Ichec">
                    {intl.formatMessage({id: "Ichec"})}
                   </option>
                   <option value="David Loyd">
                    {intl.formatMessage({id: "DavidLloyd"})}
                   </option>
                   <option value="Nathalie Ducobu">
                    {intl.formatMessage({id: "NathalieDucobu"})}
                   </option>
                  <option value="John Bogaerts">
                     {intl.formatMessage({id: "JohnBogaerts"})}
                   </option>
                   <option value="MeetDistrict">
                     {intl.formatMessage({id: "MeetDistrict"})}
                   </option>
                   <option value="Autres">
                     {intl.formatMessage({id: "Autres"})}
                   </option>
             </Form.Control>
            </Form.Group>

            </Row>

          </Fade>
          :
          <>
          </>
        }


          <div style={{marginTop: 30}}>
          <h6 style={{textAlign: 'left', color:'#FC8016'}}>{intl.formatMessage({id: "MoyenPaiement"})}</h6>
          <p style={{textAlign: 'left', color:'red', marginTop: 10}}>{intl.formatMessage({id: error.method ? error.method : "Space" })}</p>
          <fieldset>
          <Form.Group as={Row}>
            <Col>

              <div style={{textAlign: 'left', marginTop: 20}}>
                <Form.Check
                  onClick={() => setBilling("Stripe")}
                  required
                  type="radio"
                  label={intl.formatMessage({id: "PaiementImmediat"})}
                  name="formHorizontalRadios"
                  id="formHorizontalRadios1"
                  onChange={() => selectPayment("Stripe")}
                />
              </div>

              <div style={{textAlign: 'left', marginTop: 20}}>
                <Form.Check
                  onClick={() => setBilling("Bill")}
                  required
                  type="radio"
                  label={intl.formatMessage({id: "Facture"})}
                  name="formHorizontalRadios"
                  id="formHorizontalRadios2"
                  onChange={() => selectPayment("Billing")}
                />
            </div>
            </Col>
          </Form.Group>
          </fieldset>

          <fieldset>
          <Form.Group as={Row}>
            <Col ref={refFacturation} sm={10}>
              <div style={{textAlign: 'left', marginTop: 20}}>
              <Form.Check
                style={{fontWeight: 'bold'}}
                label={intl.formatMessage({id: "CliquezIciFacturation"})}
                onChange={() => setFacturation(!facturation)}
              />
            </div>
            </Col>
          </Form.Group>
          </fieldset>
          </div>

        </Form>
        {
          facturation === true ?
          <>
          {_renderFacturationInfo()}
          </>
          :
          <>
          </>
        }
        <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 25}}>
        <div style={{marginTop: 40}}>
        <h6 style={{textAlign: 'left', color:'#FC8016'}}>{intl.formatMessage({id: "Condition"})}</h6>
        <p style={{textAlign: 'left', color:'red', marginTop: 10}}>{intl.formatMessage({id: error.checkBoxGeneralConditionOfUse ? error.checkBoxGeneralConditionOfUse : "Space" })}</p>
        <p style={{textAlign: 'left', color:'red', marginTop: 10}}>{intl.formatMessage({id: error.checkBoxEngagement ? error.checkBoxEngagement : "Space" })}</p>
        {
          bill === 'Bill' ?
          <div style={{textAlign: 'left'}}>
          <input
          type="checkbox"
          id="exampleCheck1"
          onChange={() => setCheckBoxEngagement(!checkBoxEngagement)}/>
        <label style={{color: 'black', marginLeft: 10, fontWeight: 'bold'}}>{intl.formatMessage({id: "EngagementFacture"})}</label>
          </div>
          :
          <>
          </>
        }
        <div style={{textAlign: 'left', marginTop: 15}}>
        <input
          type="checkbox"
          id="exampleCheck2"
          onChange={() => setCheckBoxGeneralConditionOfUse(!checkBoxGeneralConditionOfUse)}/>
        <label style={{marginLeft: 10}}>{intl.formatMessage({id: "LuAccepteCondition1"})}<span>   <a href={Condition} target="_blank" style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>{intl.formatMessage({id: "LuAccepteCondition2"})}</a> </span> {intl.formatMessage({id: "LuAccepteCondition3"})}</label>
        </div>
        </div>

        <div className="centerButton" style={{marginBottom: 15}}>
          <p style={{textAlign: 'center', color:'red', fontWeight: 'bold', marginTop: 20}}>{intl.formatMessage({id: error.network ? error.network : "Space" })}</p>
        <Button
        type="submit"
        style={{marginTop: 40, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0,}}
        >
        {
          loading === true ?
          <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
              <CircularProgress color="white" size={20}/>
            </div>
            <div>
              <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: "Continuer"})}</p>
            </div>
          </div>
          :
          <p style={{margin: 0}}>{intl.formatMessage({id: "Continuer"})}</p>
        }
        </Button>

        <MyVerticallyCenteredModal
          show={modalShow}
          intl={intl}
          onHide={() => setModalShow(false)}
        />

        </div>

        </Form>
                </>
              }     
                
                    

              </>
            )
  }

  const _renderBusinessCompany = () => {
    return(
      <>
       <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 25}}>
       <Row>
          <h6  style={{textAlign: 'left', color:'#FC8016'}}>{intl.formatMessage({id: "CoordonneesPrivees"})}</h6>
          <Row style={{marginTop: 20}}>
           
          <Col>
            <p style={{textAlign: 'right', fontSize: '0.8rem'}}><span style={{color: 'red'}}> *</span> {intl.formatMessage({id: "FieldObligatory"})}</p>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12}  controlId="formGridlastName">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Nom"})}<span style={{color: 'red'}}> *</span></p>
              <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({id: "EntrezNom"})}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
                {intl.formatMessage({id: error.lastName ? error.lastName : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
                {intl.formatMessage({id: error.lastName ? error.lastName : "Space" })}
              </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridfirstName">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Prénom"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            placeholder={intl.formatMessage({id: "EntrezPrenom"})}
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
              {intl.formatMessage({id: error.firstName ? error.firstName : "Space" })}
              </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
              {intl.formatMessage({id: error.firstName ? error.firstName : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridTel">
            <p style={{ textAlign: "left", marginTop: 10 }}>
              {intl.formatMessage({ id: "Telephone" })}
              <span style={{ color: "red" }}> *</span>
            </p>
            <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({ id: "EnterTelephone"})}
              value={tel}
              onChange={(event) => setTel(event.target.value)}/>
            <Form.Control.Feedback
              type="invalid"
              style={{ textAlign: "left", color: "red" }}>
              {intl.formatMessage({id: error.tel ? error.tel : "Space"})}
            </Form.Control.Feedback>
            <Form.Control.Feedback
              type="valid"
              style={{ textAlign: "left", color: "red" }}>
              {intl.formatMessage({id: error.tel ? error.tel : "Space"})}
            </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridfirstMail">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Email"})}<span style={{color: 'red'}}> *</span></p>
              <Form.Control
              required
              type="email"
              placeholder={intl.formatMessage({id: "EntrezEmail"})}
              value={email}
              onChange={(event) => setEmail(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                  {intl.formatMessage({id: error.email ? error.email : "Space" })}
                </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.email ? error.email : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "MaRegion"})}<span style={{color: 'red'}}> *</span></p>
              <Form.Control value={region ? region : ""} required as="select" onChange={(event) => setRegion(event.target.value)}>
                <option hidden value="">
                  {intl.formatMessage({id: "RegionIndiquez"})}
                </option>
                <option value="bxl">
                  {intl.formatMessage({id: "Bxl"})}
                </option>
                <option value="luik">
                  {intl.formatMessage({id: "Luik"})}
                </option>
                {/*
                  <option value="anv">
                  {intl.formatMessage({id: "Anv"})}
                </option>
                  */}
                <option value="fl">
                  {intl.formatMessage({id: "Fl"})}
                </option>
             
          </Form.Control>
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.region ? error.region : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.region ? error.region : "Space" })}
          </Form.Control.Feedback>
        </Form.Group>



        </Row>
        <div  style={{marginBottom: 15,  display: 'flex', alignItems: 'flex-start'}}>
          <p style={{textAlign: 'center', color:'red', fontWeight: 'bold'}}>{intl.formatMessage({id: error.network ? error.network : "Space" })}</p>
          <Button
          type="submit"
          style={{marginTop: 20, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0,}}
          >
          {
            loading === true ?
            <div style={{position: 'relative'}}>
              <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                <CircularProgress color="white" size={20}/>
              </div>
              <div>
                <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: "Continuer"})}</p>
              </div>
            </div>
            :
            <p style={{margin: 0}}>{intl.formatMessage({id: "Envoyer"})}</p>
          }
          </Button>

          <MyVerticallyCenteredModalBusiness
            show={modalShowBusiness}
            intl={intl}
            onHide={() => setModalShowBusiness(false)}
          />

          </div>
        </Form>


   
        
      </>
    )
  }

  const _renderYoung = () => {
    return(
      <div>
        <div ref={refPrivate} style={{marginTop: 30}}>
          <h6 style={{textAlign: 'left', color:'#FC8016'}}>{intl.formatMessage({id: "TypeOfMotivation" })}</h6>
        </div>
        <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 25}}>
        <Form.Group as={Row}>

        <Form.Group controlId="exampleForm.ControlTextarea1">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "VotreMessage" })}</p>
          <Form.Control
          required
          as="textarea"
          rows={8}
          placeholder={intl.formatMessage({id: "TypeOfMotivationPlaceholder"})}
          value={motivation}
          onChange={(event) => setMotivation(event.target.value)}/>
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
            {intl.formatMessage({id: error.message ? error.message : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
            {intl.formatMessage({id: error.message ? error.message : "Space" })}
          </Form.Control.Feedback>
        </Form.Group>

        </Form.Group>

          <Row>
          <h6  style={{textAlign: 'left', color:'#FC8016', marginTop: '30px'}}>{intl.formatMessage({id: "CoordonneesPrivees"})}</h6>
          <p style={{textAlign: 'right', fontSize: '0.8rem'}}><span style={{color: 'red'}}> *</span> {intl.formatMessage({id: "FieldObligatory"})}</p>
            <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridlastName">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Nom"})}<span style={{color: 'red'}}> *</span></p>
              <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({id: "EntrezNom"})}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
                {intl.formatMessage({id: error.lastName ? error.lastName : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
                {intl.formatMessage({id: error.lastName ? error.lastName : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridfirstName">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Prénom"})}<span style={{color: 'red'}}> *</span></p>
              <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({id: "EntrezPrenom"})}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
              {intl.formatMessage({id: error.firstName ? error.firstName : "Space" })}
              </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
              {intl.formatMessage({id: error.firstName ? error.firstName : "Space" })}
            </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridfirstMail">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Email"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="email"
            placeholder={intl.formatMessage({id: "EntrezEmail"})}
            value={email}
            onChange={(event) => setEmail(event.target.value)}/>
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.email ? error.email : "Space" })}
            </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.email ? error.email : "Space" })}
          </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridTel">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Telephone"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            placeholder={intl.formatMessage({id: "EnterTelephone"})}
            value={tel}
            onChange={(event) => setTel(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.tel ? error.tel : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.tel ? error.tel : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridSociety">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Societe"})}</p>
            <Form.Control
            required = {check}
            type="text"
            placeholder={intl.formatMessage({id: "EntrezSociete"})}
            value={society}
            onChange={(event) => setSociety(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.society ? error.society : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.society ? error.society : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridTVA">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "NumeroTva"})}</p>
            <Form.Control
            required = {check}
            type="text"
            placeholder={intl.formatMessage({id: "EntrezNumeroTva"})}
            value={tva}
            onChange={(event) => setTva(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.tva ? error.tva : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.tva ? error.tva : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridZip">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "CodePostal"})}<span style={{color: 'red'}}> *</span></p>
              <Form.Control
              required
              type="number"
              placeholder={intl.formatMessage({id: "EntrezCodePostal"})}
              value={zipCode}
              onChange={(event) => setZipCode(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.zipCode ? error.zipCode : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.zipCode ? error.zipCode : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridCity">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Ville"})}<span style={{color: 'red'}}> *</span></p>
              <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({id: "EntrezVille"})}
              value={city}
              onChange={(event) => setCity(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.city ? error.city : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.city ? error.city : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridAddress">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "AdressePostale"})}<span style={{color: 'red'}}> *</span></p>
              <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({id: "EntrezAdressePostal"})}
              value={street}
              onChange={(event) => setStreet(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.street ? error.street : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.street ? error.street : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "MaRegion"})}<span style={{color: 'red'}}> *</span></p>
              <Form.Control value={region ? region : ""} required as="select" onChange={(event) => setRegion(event.target.value)}>
                <option hidden value="">
                  {intl.formatMessage({id: "RegionIndiquez"})}
                </option>
                <option value="bxl">
                  {intl.formatMessage({id: "Bxl"})}
                </option>
                <option value="luik">
                  {intl.formatMessage({id: "Luik"})}
                </option>
                {/*
                  <option value="anv">
                  {intl.formatMessage({id: "Anv"})}
                </option>
                  */}
                <option value="fl">
                  {intl.formatMessage({id: "Fl"})}
                </option>
       
          </Form.Control>
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.region ? error.region : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.region ? error.region : "Space" })}
          </Form.Control.Feedback>
        </Form.Group>

          </Row>
          <Row>
            <Form.Group as={Col} xl={4} lg={4} sm={12} md={12} controlId="formGridDay">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "DateNaissance"})}<span style={{color: 'red'}}> *</span></p>
              <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({id: "Jour"})}
              value={day}
              onChange={(event) => setDay(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.date ? error.date : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.date ? error.date : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xl={4} lg={4} sm={12} md={12} controlId="formGridMounth">
              <p style={{textAlign: 'left', marginTop: 10, color:"transparent"}}>{intl.formatMessage({id: "DateNaissance"})}</p>
              <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({id: "Mois"})}
              value={mounth}
              onChange={(event) => setMounth(event.target.value)}/>
            </Form.Group>
            <Form.Group as={Col} xl={4} lg={4} sm={12} md={12} controlId="formGridYear">
              <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>{intl.formatMessage({id: "DateNaissance"})}</p>
              <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({id: "Annee"})}
              value={year}
              onChange={(event) => setYear(event.target.value)}/>
            </Form.Group>
          </Row>

            <Row>
              <Form.Group as={Col} controlId="formGridSponsor">
                <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "ParrainMarraine"})}</p>
                <Form.Control
                type="text"
                placeholder={intl.formatMessage({id: "EntrezParrainMarraine"})}
                value={sponsor}
                onChange={(event) => setSponsor(event.target.value)}/>
              </Form.Group>
            </Row>

          {
            form === true ?
            <Form noValidate validated={validated} style={{marginTop: 25}}>
              <Row>
              <h6 ref={refColleague} style={{textAlign: 'left', color:'#FC8016'}}>{intl.formatMessage({id: "Collegue1"})}</h6>
                <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridlastName">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Nom"})}</p>
                  <Form.Control
                  required
                  type="text"
                  placeholder={intl.formatMessage({id: "EntrezNom"})}
                  value={lastNameColleague1}
                  onChange={(event) => setLastNameColleague1(event.target.value)}
                  />
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.lastNameColleague1 ? error.lastNameColleague1 : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.lastNameColleague1 ? error.lastNameColleague1 : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridfirstName">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Prénom"})}</p>
                  <Form.Control
                  required
                  type="text"
                  placeholder={intl.formatMessage({id: "EntrezPrenom"})}
                  value={firstNameColleague1}
                  onChange={(event) => setFirstNameColleague1(event.target.value)}/>
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.firstNameColleague1 ? error.firstNameColleague1 : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.firstNameColleague1 ? error.firstNameColleague1 : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formGridfirstMail">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Email"})}</p>
                  <Form.Control
                  required
                  type="email"
                  placeholder={intl.formatMessage({id: "EntrezEmail"})}
                  value={emailColleague1}
                  onChange={(event) => setEmailColleague1(event.target.value)}/>
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.emailColleague1 ? error.emailColleague1 : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.emailColleague1 ? error.emailColleague1 : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
              <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>{intl.formatMessage({id: "Collegue2"})}</h6>
                <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridfirstName">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Nom"})}</p>
                  <Form.Control
                  required
                  type="text"
                  placeholder={intl.formatMessage({id: "EntrezNom"})}
                  value={lastNameColleague2}
                  onChange={(event) => setLastNameColleague2(event.target.value)}/>
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.lastNameColleague2 ? error.lastNameColleague2 : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.lastNameColleague2 ? error.lastNameColleague2 : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridlastName">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Prénom"})}</p>
                  <Form.Control
                  required
                  type="text"
                  placeholder={intl.formatMessage({id: "EntrezPrenom"})}
                  value={firstNameColleague2}
                  onChange={(event) => setFirstNameColleague2(event.target.value)}/>
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.firstNameColleague2 ? error.firstNameColleague2 : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.firstNameColleague2 ? error.firstNameColleague2 : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formGridfirstMail">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Email"})}</p>
                  <Form.Control
                  required
                  type="email"
                  placeholder={intl.formatMessage({id: "EntrezEmail"})}
                  value={emailColleague2}
                  onChange={(event) => setEmailColleague2(event.target.value)}/>
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.emailColleague2 ? error.emailColleague2 : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.emailColleague2 ? error.emailColleague2 : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
              <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>{intl.formatMessage({id: "Collegue3"})}</h6>
                <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridlastName">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Nom"})}</p>
                  <Form.Control
                  required
                  type="text"
                  placeholder={intl.formatMessage({id: "EntrezNom"})}
                  value={lastNameColleague3}
                  onChange={(event) => setLastNameColleague3(event.target.value)}/>
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.lastNameColleague3 ? error.lastNameColleague3 : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.lastNameColleague3 ? error.lastNameColleague3 : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridfirstName">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Prénom"})}</p>
                  <Form.Control
                  required
                  type="text"
                  placeholder={intl.formatMessage({id: "EntrezPrenom"})}
                  value={firstNameColleague3}
                  onChange={(event) => setFirstNameColleague3(event.target.value)}/>
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.firstNameColleague3 ? error.firstNameColleague3 : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.firstNameColleague3 ? error.firstNameColleague3 : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formGridfirstMail">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Email"})}</p>
                  <Form.Control
                  required
                  type="email"
                  placeholder={intl.formatMessage({id: "EntrezEmail"})}
                  value={emailColleague3}
                  onChange={(event) => setEmailColleague3(event.target.value)}/>
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.emailColleague3 ? error.emailColleague3 : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                    {intl.formatMessage({id: error.emailColleague3 ? error.emailColleague3 : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Form>
            :
            <>
            </>
          }

        
          <div style={{marginTop: 40}}>
          <h6 style={{textAlign: 'left', color:'#FC8016'}}>{intl.formatMessage({id: "Condition"})}</h6>
          <p style={{textAlign: 'left', color:'red', marginTop: 10}}>{intl.formatMessage({id: error.checkBoxGeneralConditionOfUse ? error.checkBoxGeneralConditionOfUse : "Space" })}</p>
          <p style={{textAlign: 'left', color:'red', marginTop: 10}}>{intl.formatMessage({id: error.checkBoxEngagement ? error.checkBoxEngagement : "Space" })}</p>

          <div style={{textAlign: 'left', marginTop: 15}}>
          <input
            type="checkbox"
            id="exampleCheck1"
            onChange={() => setCheckBoxGeneralConditionOfUse(!checkBoxGeneralConditionOfUse)}/>
          <label style={{marginLeft: 10}}>{intl.formatMessage({id: "LuAccepteCondition1"})}<span>   <a href={Condition} target="_blank" style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>{intl.formatMessage({id: "LuAccepteCondition2"})}</a> </span> {intl.formatMessage({id: "LuAccepteCondition3"})}</label>
          </div>
          </div>

          <div className="centerButton" style={{marginBottom: 15}}>
            <p style={{textAlign: 'center', color:'red', fontWeight: 'bold', marginTop: 20}}>{intl.formatMessage({id: error.network ? error.network : "Space" })}</p>
          <Button
          type="submit"
          style={{marginTop: 40, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0,}}
          >
          {
            loading === true ?
            <div style={{position: 'relative'}}>
              <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                <CircularProgress color="white" size={20}/>
              </div>
              <div>
                <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: "Continuer"})}</p>
              </div>
            </div>
            :
            <p style={{margin: 0}}>{intl.formatMessage({id: "Continuer"})}</p>
          }
          </Button>

          <MyVerticallyCenteredModal
            show={modalShow}
            intl={intl}
            onHide={() => setModalShow(false)}
          />

          </div>

        </Form>

      </div>
    )
  }


  const _render = () => {
    return (
      <>
      <Form ref={refPrivate} noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 25}}>
       <Row>
          <h6  style={{textAlign: 'left', color:'#FC8016'}}>{intl.formatMessage({id: "CoordonneesPrivees"})}</h6>
          <Row style={{marginTop: 20}}>
            <Col xl={1} lg={2} sm={12} md={12}>
              <div style={{textAlign: 'left', marginBottom: 10, display: 'flex'}}>
                <Form.Check
                  required
                  type="radio"
                  label={intl.formatMessage({id: "Miss"})}
                  name="formHorizontalRadiosGender"
                  id="formHorizontalRadios2Gender"
                  value={gender}
                  onChange={()=> setGender("Madame")}
                />
                <span style={{color: 'red'}}> *</span>
              </div>
            </Col>
            <Col xl={1} lg={2} sm={12} md={12}>
              <div style={{textAlign: 'left', marginBottom: 10, display: 'flex'}}>
                <Form.Check
                  type="radio"
                  label={intl.formatMessage({id: "Mister"})}
                  name="formHorizontalRadiosGender"
                  id="formHorizontalRadios2Gender"
                  onChange={()=> setGender("Monsieur")}
                />
                <span style={{color: 'red', marginLeft: 4}}> *</span>
              </div>
            </Col>
          <Col>
            <p style={{textAlign: 'right', fontSize: '0.8rem'}}><span style={{color: 'red'}}> *</span> {intl.formatMessage({id: "FieldObligatory"})}</p>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12}  controlId="formGridlastName">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Nom"})}<span style={{color: 'red'}}> *</span></p>
              <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({id: "EntrezNom"})}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
                {intl.formatMessage({id: error.lastName ? error.lastName : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
                {intl.formatMessage({id: error.lastName ? error.lastName : "Space" })}
              </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridfirstName">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Prénom"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            placeholder={intl.formatMessage({id: "EntrezPrenom"})}
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
              {intl.formatMessage({id: error.firstName ? error.firstName : "Space" })}
              </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
              {intl.formatMessage({id: error.firstName ? error.firstName : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridTel">
            <p style={{ textAlign: "left", marginTop: 10 }}>
              {intl.formatMessage({ id: "Telephone" })}
              <span style={{ color: "red" }}> *</span>
            </p>
            <Form.Control
              required
              type="text"
              placeholder={intl.formatMessage({ id: "EnterTelephone"})}
              value={tel}
              onChange={(event) => setTel(event.target.value)}/>
            <Form.Control.Feedback
              type="invalid"
              style={{ textAlign: "left", color: "red" }}>
              {intl.formatMessage({id: error.tel ? error.tel : "Space"})}
            </Form.Control.Feedback>
            <Form.Control.Feedback
              type="valid"
              style={{ textAlign: "left", color: "red" }}>
              {intl.formatMessage({id: error.tel ? error.tel : "Space"})}
            </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridfirstMail">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Email"})}<span style={{color: 'red'}}> *</span></p>
              <Form.Control
              required
              type="email"
              placeholder={intl.formatMessage({id: "EntrezEmail"})}
              value={email}
              onChange={(event) => setEmail(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                  {intl.formatMessage({id: error.email ? error.email : "Space" })}
                </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.email ? error.email : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Row>

        {_renderTvaQuestion()}

        </Form>
      </>
    )
  }


  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
      <Navigation/>
        <Fade>
          <div style={{backgroundColor: "#F6F7FB", paddingTop: "150px"}}>

            
        <Container className="shadow" style={{backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
        
        <Form.Group as={Row}>
        <h2 ref={refSelectSubscritpion} style={{textAlign: 'left'}}>{intl.formatMessage({id: "becomeMemberNoMaj" })}</h2>
        </Form.Group>
     
        {
       checkParrain && checkParrain === true ?
       <>
       <div style={{display: 'flex', justifyContent: 'flex-start', }}>
         <h5 style={{marginTop: 10}}>{intl.formatMessage({id: "VotreParrain" })} <span style={{color: "grey"}}>{sponsor}</span> </h5>
       </div>
       </>
       :
       <>
       </>
     }

        <div style={{marginTop: 30}}>
          {_renderTypeOfSubscription()}
        </div>

        <div ref={refScroll}/>

        {
          registrationType === 'Business' ?
          <>{_renderBusinessCompany()}</>
          :
          <></>
        }
        {
          registrationType === 'SingleA' ?
          <>{_render()}</>
          :
          <>
          </>
        }
         {
          registrationType === 'Young' ?
          <>{_renderYoung()}</>
          :
          <>
          </>
        }
        {
          formularyTva === 'true' ?
          <>{_renderCompany()}</>
          :
          <></>
        }
        {
          formularyTva === 'false' ?
          <>{_renderParticular()}</>
          :
          <></>
        }
  

      </Container>



      <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%', marginTop: 80}}/>
        </div>
        <div style={{backgroundColor: '#FFFFFF'}}>
      <Sponsor/>
      </div>
        <Footer/>
      </Fade>
    </div>
  );
}

export default Subscription;

export const apiAuthRoute = [
    "/login",
    "/stripeRegister",
    "/registerV2",
    "/register",
    "/payment",
    "/updateRegisterStatus",
    "/paymentEvent",
    "/updateEventStatus",
    "/updatePublicEventStatus",
    "/updateSubscriptionStatus",
    "/checkStatusJWT",
    "/getIdAccesToken",
    "/createNewAccesToken",
    "/paymentPublicEvent/",
    "/paymentSubscription",
    "/getMemberInfo",
    "/updateMemberInfo",
    "/updatePassword",
    "/updateEmail",
    "/searchMember",
    "/contact",
    "/publicEvent",
]

export const apiBackendRoute = [
    "/GetEvents",
    "/GetListClubs",
    "/GetAllAmbassador/:SizeImage",
    "/GetAllSponsor/:SizeImage",
    "/GetGallery",
    "/GetSpecificGallery",
    "/SubscribeEvents",
    "/UnSubscribeEvents",
    "/GetSpecificEvent",
    "/GetHeadLinerEvents",
    "/IsBlocked",
    "/ForgetPassword/First/Step",
    "/ForgetPassword/Second/Step",
    "/GetVat",
    "/DeleteAccount"
]


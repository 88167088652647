import axios from 'axios'
import { ClearToken,  SetToken, GetToken} from './ManageToken';
import {apiAuthRoute} from './Route'
import Constant from './Constant'

var Request = axios.create({
  baseURL: Constant.IP_BACKEND_DNS_CLIENT,
})

Request.defaults.headers.post['Content-Type'] = 'application/json';
Request.defaults.headers.common['Accept'] = 'application/json';
Request.defaults.timeout = 7000; //7 seconde

var requestsToRefresh = []
var isRefreshRequesting = false

Request.interceptors.request.use(config => {
  try {
     if (apiAuthRoute.includes(config.url)){
         config.baseURL = Constant.IP_BACKEND_DNS
     }
     if (GetToken().access_token && !config.headers.Authorization) {
      const Token = GetToken().access_token
      if (Token)
        config.headers.Authorization = "Bearer " + Token
    }
    return config

  } catch (e) {
    console.log(e)
  }
 
 
}, err => Promise.reject(err))

Request.interceptors.response.use(null, (err) => {
  const { response, config } = err

  if (response.status === 401) {
    // If we have not logged in before, it makes no sense
    // to try to get a new token
    if (!GetToken().access_token) {
      return Promise.reject(err)
    }

    // User is auth, probably token is expired, try renew
    // And send all failed requests again
    if (!isRefreshRequesting) {
      isRefreshRequesting = true
      Request.defaults.headers['Authorization'] = 'Bearer ' + GetToken().refresh_token
      Request.put('/refreshAcces')
        .then(({ data }) => {
          SetToken(data.data)
          requestsToRefresh.forEach((cb) => cb(data.data))
        })
        .catch(() => {
          // If you have a closed system, you can also
          // redirect/router to the login page
          ClearToken()
          //REDIRIGER
          requestsToRefresh.forEach((cb) => cb(null))
        })
        .finally(() => {
          requestsToRefresh = []
          isRefreshRequesting = false
        })
    }

    return new Promise((resolve, reject) => {
      // In our variable (requests that expect a new token
      // from the first request), we add a callback,
      // which the first request to execute
      requestsToRefresh.push((token) => {
        if (token) {
          config.headers.Authorization = 'Bearer ' + token.AccesToken
          resolve(Request(config))
        }

        // If the first request could not update the token, we
        // must return the basic request processing logic
        reject(err)
      })
    })
  }

  return Promise.reject(err)
})

export default Request
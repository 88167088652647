import React, { useEffect, useState } from 'react';
import "./Consulting.css"
import { Fade } from "react-awesome-reveal";
import { Button, Row, Container, Col, Image} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { Redirect, useParams, useHistory  } from 'react-router-dom'
import Constant from '../../networking/Constant'
import { CircularProgress } from '@material-ui/core';
import Cookies from 'universal-cookie'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
} from "react-share";
import { useLoading, Rings } from '@agney/react-loading';
import HelmetMetaData from "../HelmetMetaData/HelmetMetaData";
import LogoB19 from '../../image/ThumbLogo.png'
import Navigation from '../navBar/NavDark'
import Footer from '../footer/Footer'
import { loadStripe } from '@stripe/stripe-js';
import { useIntl } from 'react-intl';

const cookies = new Cookies();
const stripePromiseBxl = loadStripe('pk_live_51J4hxHL0SWLMklZ3E53r2s856WjJNqxvY0ElyxyQQm8c62OAXkBDqKc1AmuN9auWfldnDdV0Oe0uCjYqR03Ffo2100uIY1TDh3');
//const stripePromise = loadStripe('pk_test_51J4hxHL0SWLMklZ3MgLaZGEqlDloUu4AMByjzBiI9d4PtY8idpytAHddFYCbs40FmSyx99pMmDHT9c6xcSrv4Xic00kTljgnSm');
const stripePromiseLuik = loadStripe('pk_live_51JCOAJEbzcAf2J36UHWIEnEMW0euy8JWvuhjLUtvAfq3iP5K2UqsKuhS7FECNvCfEwS8IZhW8vCbPyCxkq0mWnUt00BjxzCUih');
const stripePromiseLux = loadStripe('pk_live_51JMVepEXYjxp2I0yYzxmdPpezg4Y7P1dpcOID8tNswUwzTvLNwpE9aTVJXtGiGLB1gw0PuIXLUifI7milrZ7nemh00qcKghPGM');

function MyVerticallyCenteredModal(props) {

  const disconnect = () => {
    cookies.remove('access', {path: '/'})
    props.onHide()
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.intl.formatMessage({id: 'Cotisation'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({id: 'CotisationMessage'})}</h4>
        <p style={{marginTop: 25}}>
          {props.intl.formatMessage({id: 'CotisationIndication'})}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button href="/login" style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={() => disconnect()}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}


function Consulting(props) {
  const intl = useIntl();

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Rings width="100" color="#FC8016" />,
  });

  const { language, idEvent } = useParams(); // 'eventId' correspond au segment de l'URL
  const history = useHistory();

  const [data, setData] = useState(null)
  const [subscribe, setSubscripe] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(true)
  const [block, setBlock] = useState(false)
  const [payment, setPayment] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [bgd, setBgd] = useState(true)

  const { setLocale } = props;

  const checkRequest = async () => {
    setLoading(true)
    let stripe = null
    if(cookies.get('access') === undefined){
      props.history.push({pathname: `/login/${language}/${idEvent}`, state: [{data: {} }] })
      return ;
    }
    if(subscribe === 'pending' || payment === true){
      const response = await fetch(Constant.IP_BACKEND_DNS + '/paymentEvent', { method: 'POST',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
      body:JSON.stringify({accesToken: cookies.get('access'), idEvent: data.Id, source: "Website"})
     });
      const session = await response.json();
      if (session.error === true) {
        if(session.message === "User is blocked"){
          setModalShow(true)
          setLoading(false)
          return;}
        setLoading(false)
        alert(intl.formatMessage({id: 'ErrorHappened'}))
      }
      if(session.region === "bxl" || session.region === "anv" || session.region === "fl"){
        stripe = await stripePromiseBxl;}
      if (session.region === "luik"){
        stripe = await stripePromiseLuik;}
      if (session.region === "lux"){
        stripe = await stripePromiseLux;}
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (result.error) {
        setLoading(false)
        alert(intl.formatMessage({id: 'ErrorHappened'}))
      }
    setLoading(false)
    return ;
    }
    // REQUEST SUBSCRIBTION WHEN SUBSCRIBE IS FALSE //
    if(subscribe === 'false'){
      fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/SubscribeEvents/',{
        method:'POST',
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json'},
        body:JSON.stringify({Jwt : cookies.get('access'), IdEvent: data.Id, Source: "Website"})
      }).then(response=>response.json()).then(res=>{
        if(res.error === true){
          if(res.message === "User is blocked"){
            setModalShow(true)
            setLoading(false)
            return ;
          }
          setLoading(false)
          setSubscripe('false')
        }
        else if(res.error === false){
          setSubscripe('true')
          setLoading(false)
        }
      }).catch((e) => {
        setLoading(false)
        setSubscripe('false')
      })
    }
    // REQUEST UNSUBSCRIBE WHEN SUBSCRIBE IS TRUE //
    if(subscribe === 'true'){
      fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/UnSubscribeEvents/',{
        method:'POST',
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json'},
        body:JSON.stringify({Jwt : cookies.get('access'), IdEvent: data.Id, Source: "Website"})
      }).then(response=>response.json()).then(res=>{
        if(res.error === true){
          setLoading(false)
          setSubscripe('true')
        }
        else if(res.error === false){
          setLoading(false)
          setSubscripe('false')
        }
      }).catch((e) => {
        setLoading(false)
        setSubscripe('true')
      })
    }
}

const ChangeLanguage = (lg) => {
  if(cookies.get('language') === "fr" || cookies.get('language') === "nl")
    return;
  else {
    localStorage.setItem("language", lg);
    if (lg === "fl")
      props.setLocale("nl");
    else
      props.setLocale("fr");    
  }
};
/*
  const fetchData = () => {
    fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/GetSpecificEvent/',{
      method:'POST',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
      body:JSON.stringify({Jwt : cookies.get('access'), IdEvent: props.match.params.idEvent, Language: intl.locale, SizeImage: 'large'})
    }).then(response=>response.json()).then(res=>{
     ChangeLanguage(res.data.Location.RegionB19)
      if(res.error === true){
        alert(intl.formatMessage({id: 'ErrorHappened'}))
      }
      else if(res.error === false){
        setData(res.data)
        setLoadingPage(false)
      //  console.log("res", res.data)
      }
    }).catch((e) => {
    })
  }
*/

  const checkButtonSubscribe = () => {
    if(subscribe === 'pending' && payment === true){
      var SubscribeTitle = intl.formatMessage({id: 'PaymentRequestMAJ'});
      var message = SubscribeTitle + '(' + data.Price + "€)"
      return(message)
    }
    if(subscribe === 'true'){
      return(intl.formatMessage({id: 'UnSubscribeMAJ'}))
    }
    if(subscribe === 'false' && payment === true){
      var SubscribeTitle = intl.formatMessage({id: 'SubscribeMaj'});
      var message = SubscribeTitle + '(' + data.Price + "€)"
      return(message)
    }
    if(subscribe === 'false' && payment === false){
      return(intl.formatMessage({id: 'SubscribeMaj'}))
    }
    return(intl.formatMessage({id: 'SubscribeMaj'}))
  }

   useEffect(() => {
    let isMounted = true;

    if (language === "fr") {
      setLocale(language);
    } else if (language === "nl") {
      setLocale(language);
    }

    const fetchData = () => {
      fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/GetSpecificEvent/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ Jwt: cookies.get('access'), IdEvent: props.match.params.idEvent, Language: intl.locale, SizeImage: 'large' })
      })
      .then(response => response.json())
      .then(res => {
        console.log("RES", res)
        if (!isMounted) return;  // Si le composant est démonté, sortir de la fonction
  
       // ChangeLanguage(res.data.Location.RegionB19);
        if (res.error === true) {
          alert(intl.formatMessage({ id: 'ErrorHappened' }));
        } else if (res.error === false) {
          setData(res.data);
          setLoadingPage(false);
          if(res.data.IsSubscribe === "pending"){
            setSubscripe('pending')
            setPayment(true)
            setBlock(false)
            return ;
          }
          if(res.data.IsSubscribe === "unsubscribe" && res.data.isFree === false && res.data.Closed === false){
            setSubscripe('false')
            setPayment(true)
            setBlock(false)
            return ;
          }
          if(res.data.IsSubscribe === "unsubscribe" && res.data.Closed === true){
            setSubscripe('false')
            setBlock(true)
            return ;
          }
          if(res.data.IsSubscribe === "unsubscribe"){
            setSubscripe('false')
            setBlock(false)
            return ;
          }
          if(res.data.IsSubscribe === "subscribe"  && res.data.Closed === "true" && res.data.IsFree === "false"){
            setSubscripe('true')
            setBlock(true)
            return ;
          }
          if(res.data.IsSubscribe === "subscribe" && res.data.Closed === "true" && res.data.IsFree === "true"){
            setSubscripe('true')
            setBlock(false)
            return ;
          }
          if(res.data.IsSubscribe === "subscribe"  && res.data.isFree === false){
            setSubscripe('true')
            setBlock(true)
            return ;
          }
          if(res.data.IsSubscribe === "subscribe" && res.data.isFree === true){
            setSubscripe('true')
            setBlock(false)}
          else
            setSubscripe('false')
        }
      })

      
      .catch((e) => {
        // Gérer l'erreur ici si nécessaire
      });
    };
    fetchData();  
    return () => {
      isMounted = false; // Mettre le drapeau à false lors du démontage du composant
    };
  }, [language, setLocale]);


  if(props.match.params.idEvent === undefined){
    return <Redirect push to={`/agenda`}/>
  }

  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
    <Navigation/>
      <Fade>
      <div style={{paddingTop: "100px", backgroundColor: "#F6F7FB", paddingBottom: 100}}>
      <Container>
      <div style={{textAlign: 'left'}}>
      <h2>{intl.formatMessage({id: 'EventTitle'})}</h2>
      </div>
      {
        loadingPage === true ?
        <div className="height"> <section {...containerProps}> {indicatorEl} </section></div>
        :
        <Row>
          <Col className="shadow" style={{backgroundColor: '#FFFFFF', padding: 15, borderRadius: 5, margin: 10, marginTop: 30}}>
            <HelmetMetaData
            title={data.Location.Name}
            description={data.Name}
            image={data.Image}
            ></HelmetMetaData>
            {
              data.Closed === true ?
              <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', right: 0, backgroundColor: 'grey', borderRadius: 5  }}>
                  <p style={{color: 'white', fontWeight: 'bold', margin: 8,  }}>{intl.formatMessage({id: 'Complet'})}</p>
                </div>
              </div>
              :
              <>
              </>
            }

          <MyVerticallyCenteredModal
            show={modalShow}
            intl={intl}
            onHide={() => setModalShow(false)}
          />

          <div style={{textAlign: 'center', paddingBottom: 10}}>
          </div>
          <div style={{display:'flex', flexDirection: 'row', marginTop: 40,  justifyContent: 'center' }}>
            <Image className="widthLogoB19" src={LogoB19}  />
            <div style={{display:'flex', alignItems: 'flex-end', marginLeft: 10}}>
            </div>
          </div>

          <div style={{marginTop: 20}}>
            <h3>{data.Location.Name}</h3>
          </div>

          <div style={{display:'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <div style={{display:'flex', alignItems: 'center', marginLeft: 10, marginTop: 12}}>
            <p>{data.DateDisplayEvent}</p>
          </div>
        </div>

          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 20}} >
            <div style={{ width: bgd === true ? '60%' : '100%'}}>
            <Image thumbnail style={{objectFit: "contain", padding: 5, borderRadius: 10, width: "100%"}} src={data.Image}  />
            <div style={{textAlign: 'center', marginBottom: 30, marginTop: 30}}>
            {
              data && data.Id === "3dd0efa4-5959-4d33-84bc-9e32897ad647" ?

              <>
              </>

              :
              <Button disabled={block} onClick={() => checkRequest()} style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0, marginTop: 10}}>
              {
                loading === true ?
                <div style={{position: 'relative'}}>
                  <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                    <CircularProgress color="white" size={20}/>
                  </div>
                  <div>
                    <p style={{margin: 0, color: 'transparent'}}>{checkButtonSubscribe()}</p>
                  </div>
                </div>
                :
                <p style={{margin: 0}}>{checkButtonSubscribe()}</p>
              }
              </Button>
            }

         
            </div>


            <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{ display: 'flex', width:'65%', justifyContent: 'center'}}>
            <h5 style={{}}>{data.Name}</h5>
            </div>
            </div>

            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 40}}>
            <div style={{backgroundColor: '#ff5f00', height: 2, width: '90%'}}/>
            </div>

            <div style={{textAlign: 'left', marginTop: 50}}>
       
            </div>


            <div style={{textAlign: 'justify', marginTop: 20}} dangerouslySetInnerHTML={{__html: data.DescriptionHTML}} />


            <p style={{textAlign: 'left', fontWeight: 'bold'}}>{intl.formatMessage({id: 'AddressDoublePoint'})}</p>
            <p style={{textAlign: 'left'}}>{data.Location.Street}, {data.Location.Number} - {data.Location.PostalCode} {data.Location.City}</p>
            <div style={{marginTop: 50}}>



            <FacebookShareButton
                url={`https://newb19.be/agenda/${props.match.params.idEvent}`}
                style={{margin: 5}}>
            <FacebookIcon size={32} round/>
            </FacebookShareButton>

            <LinkedinShareButton url={`https://newb19.be/agenda/${props.match.params.idEvent}`} style={{margin: 5}}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>

            <EmailShareButton url={`https://newb19.be/agenda/${props.match.params.idEvent}`} style={{margin: 5}}>
            <EmailIcon size={32} round />
            </EmailShareButton>
            </div>
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 50}}>
            <div style={{backgroundColor: '#ff5f00', height: 2, width: '90%'}}/>
            </div>
            <Row style={{display: 'flex', justifyContent:'center', marginBottom: 15}}>
              {
                data.Sponsor.length !== 0 ?
                <h3 style={{marginTop: 25, marginBottom: 50}}>{intl.formatMessage({id: 'SponsorBy'})}</h3>
                :
                <>
                </>
              }

              {data.Sponsor && data.Sponsor.map((item) => {
                return(
                  <Col xl={2} lg={2} md={2} sm={3} key={item.Id}>
                    <a href={item.Link} >
                      <Image style={{margin: 2, objectFit: 'cover', width: "100%"}} src={item.LogoLarge}  />
                    </a>
                </Col>
                )
              })}

            </Row>

            </div>
          </div>

          </Col>
        </Row>
      }

      </Container>
      </div>
        <Footer/>
      </Fade>
    </div>
  );
}

export default Consulting;

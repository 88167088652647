import React, { useEffect, useState, useRef } from 'react';
import { Fade } from "react-awesome-reveal";
import { Row, Container, Col, Image, Form } from 'react-bootstrap';
import Constant from '../../networking/Constant'
import { useLoading, Rings } from '@agney/react-loading';
import Navigation from '../navBar/NavDark'
import Footer from '../footer/Footer'
import MapProps from '../Map/MapChartProps'
import { FiMapPin } from "react-icons/fi";
import './Ambassador.css'

function Map(props) {

  const refAmbassador = useRef(null)
  const regionArray = ["bxl", 'luik', 'anv', 'fl', 'lux']

  const executeScrollAmbassador = () => refAmbassador.current.scrollIntoView()

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Rings width="100" color="#FC8016" />,
  });

  const [ambassador, setAmbassador] = useState(null)
  const [temp, setTemp] = useState(null)
  const [loading, setLoading] = useState(true)
  const [filterExecute, setFilterExecute] = useState(false)
  const [region, setRegion] = useState(null)

  const getAmbassador = async () => {
    try{
      fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/GetAllAmbassador/Medium',{
        method:'GET',
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json'},
      }).then(response=>response.json()).then(res=>{
        if(res.error === true){
          setLoading(false)
        }
        else if(res.error === false){
          setAmbassador(res.data)
          setTemp(res.data)
          setRegion(props.match.params.region)
          setLoading(false)
        }
      }).catch((e) => {
        console.log(e)
      })
    } catch(e) {
      console.log(e)
    }
  }

const startFilter = async () => {
  var newArray = temp
  if(regionArray.indexOf(props.match.params.region) !== -1){
    newArray = newArray.filter((item) => item.Region.indexOf(props.match.params.region) !== -1)
  }
  setFilterExecute(true)
  setAmbassador(newArray)
}

const startFilterProps = async (info) => {
  setRegion(info)
  setLoading(true)
  var newArray = temp
  if(regionArray.indexOf(info) !== -1){
    newArray = newArray.filter((item) => item.Region.indexOf(info) !== -1)
  }
  setLoading(false)
  setAmbassador(newArray)
}

const checkRegion = (info) => {
  if(info)
    setRegion(info)
  if(region === "bxl")
    return("Région de Bruxelles-Capitale, Province du Brabant-Wallon, Province de Namur")
  if(region === "luik")
    return("Province de Liège")
  if(region === "anv")
    return("Province d'Anvers, Province du Limbourg")
  if(region === "fl")
    return("Flandre occidentale, Flandre orientale")
  if(region === "lux")
    return("Grand-Duché de Luxembourg")
  return("Belgique et Grand-Duché de Luxembourg")
}

  useEffect(() => {

    if(ambassador === null && temp === null)
      getAmbassador()
    if(refAmbassador.current && loading === false){
      if(regionArray.indexOf(region) !== -1)
        executeScrollAmbassador()
      if(filterExecute === false)
        startFilter()
    }

  }, [ambassador, temp, loading, filterExecute, region]);

  return (
    <div>
    <Navigation/>
    <Fade>

      <div className="test" style={{paddingTop: 130}}>
        <Container>
          <h3 style={{textAlign: 'left', color: '#002348'}}>Ambassadeurs</h3>
          <p style={{textAlign: 'left', color: "#002348"}}>Sélectionnez une zone pour voir nos ambassadeurs.</p>
          <MapProps
          setRegion={setRegion}
          checkRegion={checkRegion}
          startFilterProps={startFilterProps}/>
        </Container>
      {
        loading === true ?
        <div  className="height"> <section {...containerProps}> {indicatorEl} </section></div>
        :
        <div>
          <Container>
            <Form.Group style={{display: "flex", flexDirection: 'row', alignItems: 'center' }}>
              <FiMapPin size={30}/>
              <h4 ref={refAmbassador} style={{textAlign: 'left', marginLeft: 10, marginTop: 5}}>{checkRegion()}</h4>
            </Form.Group>
            <Row style={{marginTop: 50,}}>
                {ambassador && ambassador.map((item) => {
                  return(
                    <Col xl={4} lg={4} md={6} sm={12} key={item.Id}  style={{paddingBottom: 20}}>
                      <Fade>
                    <div className="shadow" style={{backgroundColor: 'white', borderRadius: 25, padding: 10}}>
                    <a href={item.Linkedin} style={{color: 'black', textDecoration: 'none'}}><Image style={{borderRadius: 360, width: "200px"  }} src={item.Image}  /> </a>
                    <h6 style={{fontWeight: 'bold', marginTop: 15, }}>{item.FirstName.toUpperCase()} {item.LastName.toUpperCase()}</h6>
                    <div className='align-me'>
                      <p  style={{textAlign: 'center', width:'60%', borderRadius: 25, color: '#002348'}}>{item.Function}</p>
                    </div>
                    <div className='align-me'>
                      <p  style={{textAlign: 'center', width:'60%', color: "#002348", }}>{item.Society}</p>
                    </div>
                  </div>
                    </Fade>
                    </Col>
                  )
                })}
            </Row>
          </Container>
        </div>
      }
      {/*<Sponsor/>*/}
          </div>
      <Footer/>
    </Fade>
    </div>
  );
}

export default Map;

/*
<Container>
<h3 style={{textAlign: 'left'}}>Ambassadeur</h3>
  <MapProps
  setRegion={setRegion}
  checkRegion={checkRegion}
  startFilterProps={startFilterProps}/>
</Container>

*/

import React, { useState, useEffect, useRef } from 'react';
import { Fade } from "react-awesome-reveal";
import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import Constant from '../../networking/Constant'
import { CircularProgress } from '@material-ui/core';
import moment from 'moment'
import { Redirect, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Navigation from '../navBar/NavDark'
import Sponsor from '../sponsor/Sponsor'
import Footer from '../footer/Footer'
import UpdateImage from './UpdateImage'
import { useIntl } from 'react-intl';

const cookies = new Cookies();

function MyVerticallyCenteredModal(props) {
  const { language } = useParams();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.intl.formatMessage({id: 'ModifyProfilMin'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({id: 'Approuve'})}</h4>
        <p style={{marginTop: 25}}>
          {props.intl.formatMessage({id: 'ProfilUpdate'})}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button href={`/profil/${language ? language : "fr"}`} style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={props.onHide}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Modify(props) {
const intl = useIntl();

const { language } = useParams();

const refImage = useRef(null)
const refPrivate = useRef(null)
const refPro = useRef(null)
const refProDate = useRef(null)

const executeScrollImage = () => refImage.current.scrollIntoView()
const executeScrollPrivate = () => refPrivate.current.scrollIntoView()
const executeScrollPro = () => refPro.current.scrollIntoView()
const executeScrollProDate = () => refProDate.current.scrollIntoView()

const [tvaCountryFacturation, setTvaCountryFacturation] = useState("")
const [vatNumberFacturation, setVatNumberFacturation] = useState(null)
const [tvaNumberFacturation, setTvaNumberFacturation] = useState("")
const [validatedTVAFacturation, setValidatedTVAFacturation] = useState(false)
const [responseApiFacturation, setResponseApiFacturation] = useState(true)
const [countryFacturation, setCountryFacturation] = useState(null)
const [typeTvaFacturation, setTypeTvaFacturation] = useState(null)
const [loadingTvaFacturation, setLoadingTvaFacturation] = useState(false)
const [productOrderFacturation, setProductOrderFacturation] = useState("")

const [typeContactFacturation, setTypeContactFacturation] = useState(true)
const [emailFacturationTo, setEmailFacturationTo] = useState("")
const [productOrder, setProductOrder] = useState(null)
const [country, setCountry] = useState(null)
const [typeTva, setTypeTva] = useState(null)
const [vatNumber, setVatNumber] = useState(null)
const [responseApi, setResponseApi] = useState(false)

const [loading, setLoading] = useState(false)
const [validated, setValidated] = useState(false);
const [modalShow, setModalShow] = useState(false);

const [registrationType, setRegistrationType] = useState("")
// Personnal Information

const [image, setImage] = useState("")
const [firstName, setFirstName] = useState("")
const [lastName, setLastName] = useState("")
const [zipCode, setZipCode] = useState("")
const [city, setCity] = useState("")
const [street, setStreet] = useState("")
const [streetNumber, setStreetNumber] = useState("");
const [tel, setTel] = useState("")
const [email, setEmail] = useState("")
const [day, setDay] = useState('')
const [mounth, setMounth] = useState('')
const [year, setYear] = useState('')
const [commentary, setCommentary] = useState("")

// Profesionnal Information

const [society, setSociety] = useState("")
const [tva, setTva] = useState("")
const [status, setStatus] = useState("")
const [sector, setSector] = useState("")
const [zipCodePro, setZipCodePro] = useState("")
const [cityPro, setCityPro] = useState("")
const [streetPro, setStreetPro] = useState("")
const [telPro, setTelPro] = useState("")
const [emailPro, setEmailPro] = useState("")


/*Sector : Immobilier,
           Banques, assurances, finances,
           Industriel,
           IT,
           Biens et services divers aux entreprises(BtoB),
           Commerces (BtoC),
           Avocats et autres professions libérales,
           Consultance/formation,
           Autres*/

//Complementary Information

const [dayPro, setDayPro] = useState("")
const [mounthPro, setMounthPro] = useState("")
const [yearPro, setYearPro] = useState("")
const [localisation, setLocalisation] = useState("")
const [sizeOfSociety, setSizeOfSociety] = useState("")

/*localisation: Uniquement en Belgique,
                Europe,
                International*/

/*sizeOfSociety: Moins de 10 travailleurs,
                Entre 10 et 50 travailleurs,
                De 50 à 200 travailleurs,
                Plus de 200 travailleurs,*/

//VisibleCheckBox

const [memberView, setMemberView] = useState(false)

const [facturation, setFacturation] = useState(false)
const [facturationSociety, setFacturationSociety] = useState("")
const [facturationStreetNumber, setFacturationStreetNumber] = useState("")
const [facturationStreet, setFacturationStreet] = useState("")
const [facturationZipCode, setFacturationZipCode] = useState("")
const [facturationCity, setFacturationCity] = useState("")
const [facturationTva, setFacturationTva] = useState("")


const [registerButton, setRegisterButton] = useState(true)


const [error, setError] = useState({
  network: "",
  firstName: "",
  lastName: "",
  zipCode: "",
  city: "",
  street: "",
  tel: "",
  email: "",
  day: "",
  mounth: "",
  year: "",
  date: "",
  commentary: "",
  society: "",
  tva: "",
  status: "",
  sector: "",
  zipCodePro: "",
  cityPro: "",
  streetPro: "",
  telPro: "",
  emailPro: "",
  datePro: "",
  localisation: "",
  sizeOfSociety: "",
})

const clear = () => {
  setError({
    network: "",
    firstName: "",
    lastName: "",
    zipCode: "",
    city: "",
    street: "",
    tel: "",
    email: "",
    day: "",
    mounth: "",
    year: "",
    commentary: "",
    society: "",
    tva: "",
    status: "",
    sector: "",
    zipCodePro: "",
    cityPro: "",
    streetPro: "",
    telPro: "",
    emailPro: "",
    dayPro: "",
    mounthPro: "",
    yearPro: "",
    localisation: "",
    sizeOfSociety: "",
    datePro: "",
    date: "",
  })
}


const splitDate = (date) => {
  var separator = "/"
  var arrayOfDay = date.split(separator)
  setDay(arrayOfDay[0])
  setMounth(arrayOfDay[1])
  setYear(arrayOfDay[2])
}

const splitDatePro = (date) => {
  if(date !== 'null/null/null'){
    var separator = "/"
    var arrayOfDay = date.split(separator)
    setDayPro(arrayOfDay[0])
    setMounthPro(arrayOfDay[1])
    setYearPro(arrayOfDay[2])}
  else{
    setDayPro("")
    setMounthPro("")
    setYearPro("")}
}

const setInfo = (data) => {
  setFirstName(data.Name.First ? data.Name.First : "")
  setLastName(data.Name.Last ? data.Name.Last : "")
  setZipCode(data.Adress.Private.ZipCode ? data.Adress.Private.ZipCode : "")
  setCity(data.Adress.Private.City ? data.Adress.Private.City : "")
  setStreet(data.Adress.Private.Street ? data.Adress.Private.Street : "")
  setTel(data.Tel.Private ? data.Tel.Private : "")
  setEmail(data.Email.Privates ? data.Email.Privates : "")
  splitDate(data.Date ? data.Date : "")
  setSociety(data.Society.Name ? data.Society.Name : "")
  setTva(data.Society.Tva ? data.Society.Tva : "")
  setCommentary(data.Commentary ? data.Commentary : "")
  setStatus(data.Society.Status ? data.Society.Status : "")
  setSector(data.Society.Sector ? data.Society.Sector : "")
  setStreetPro(data.Adress.Pro.Street ? data.Adress.Pro.Street : "")
  setZipCodePro(data.Adress.Pro.ZipCode ? data.Adress.Pro.ZipCode : "")
  setCityPro(data.Adress.Pro.City ? data.Adress.Pro.City : "")
  setTelPro(data.Tel.Pro ? data.Tel.Pro : "")
  setEmailPro(data.Email.Pro ? data.Email.Pro : "")
  splitDatePro(data.Society.Date ? data.Society.Date : "")
  setLocalisation(data.Society.Localisation ? data.Society.Localisation : "")
  setFacturationSociety(data.Facturation.Society ? data.Facturation.Society : "")
  setFacturationStreet(data.Facturation.Street ? data.Facturation.Street : "")
  setFacturationZipCode(data.Facturation.ZipCode ? data.Facturation.ZipCode : "")
  setFacturationCity(data.Facturation.City ? data.Facturation.City : "")
  setEmailFacturationTo(data.Facturation.Email ? data.Facturation.Email : "")
  setFacturationTva(data.Facturation.TVA ? data.Facturation.TVA : "")
  setSizeOfSociety(data.Society.SizeOfSociety ? data.Society.SizeOfSociety : "")
  setTvaCountryFacturation(data.Facturation.Country ? data.Facturation.Country : "")
  setTvaNumberFacturation(data.Facturation.TVA ? data.Facturation.TVA : "")
  setFacturationStreetNumber(data.Facturation.Number ? data.Facturation.Number : "")
  setProductOrderFacturation(data.Facturation.MoreInfoFacturation.Po ? data.Facturation.MoreInfoFacturation.Po : "")
  setTypeContactFacturation(data.Facturation.TypeContactFacturation ? data.Facturation.TypeContactFacturation : "")
}

function enleverLettres(str) {
  return str.replace(/[a-zA-Z]/g, '');
}

const setRegister = (data) => {
  setRegistrationType(data.RegistrationType)
}

const fetchData = () => {

  fetch(Constant.IP_BACKEND_DNS + '/getMemberInfo',{
    method:'POST',
    headers:{
    'Content-Type': 'application/json',
    'Accept': 'application/json'},
    body:JSON.stringify({accesToken: cookies.get('access'), sizeImage: 'small'})
  }).then(response=>response.json()).then(res=>{
   //console.log("RERREREER", res.data)
    if(res.error === false){
      setInfo(res.data.UserInformation)
      setRegister(res.data)
    
    } else {
      alert(intl.formatMessage({id: "ErrorHappened"}))
    }
  }).catch((e) => {
    setError({network: "Error network, please try again"})
  })
}


const { setLocale } = props;

useEffect(() => {
  fetchData()
  if (language === "fr") {
    setLocale(language);
  } else if (language === "nl") {
    setLocale(language);
  }
}, [language, setLocale]);


const _setFormatTvaFacturation = () => {
  try{
    var country = tvaCountryFacturation.toUpperCase()
    if(country === "BE"){
      var onlyNumber = tvaNumberFacturation.replace(/\D/g, "");
      if (onlyNumber.length != 10)
        return('ERREUR')
      var result = onlyNumber.substring(0,4) + "." + onlyNumber.substring(4,7) + "." + onlyNumber.substring(7);
      setVatNumberFacturation(result)
    } else {
      var onlyNumber = tvaNumberFacturation.replace(/\D/g, "");
      setVatNumberFacturation(onlyNumber)
    }
  }catch(e){
    //console.log("EREREUR", e)
    setError({network: "ErrorNetwork"})
  }
}

const tvaAPIFacturation = async () => {
  setError({tvaFacturation : ""})
  fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/GetVat' ,{
    method:'POST',
    headers:{
    'Content-Type': 'application/json',
    'Accept': 'application/json'},
    body:JSON.stringify({Vat: tvaNumberFacturation, VatCountry: tvaCountryFacturation})
  }).then(response=>response.json()).then(res=>{
    if(res.error === false){
      // mauvais num tva => CountryCode or Vat is invalid
      // api down => Service vat unavailable
      if (res.data.address && res.data.address.streetNumber !== undefined){
        setFacturationStreetNumber(res.data.address.streetNumber)
      }
      setResponseApiFacturation(true)
      setRegisterButton(true)
      setCountryFacturation(res.data.country_code)
      setTypeTvaFacturation(res.data.type)
      setFacturationCity(res.data.address.city)
      setFacturationStreet(res.data.address.street)
      setFacturationZipCode(res.data.address.postalCode)
      setFacturationSociety(res.data.name)
      setLoadingTvaFacturation(false)
      _setFormatTvaFacturation ()
    }else if (res.error === true && res.message === "One argument or more are not valid") {
      setError({tvaFacturation : "ErrorTva"})
      setLoadingTvaFacturation(false)
      setResponseApiFacturation(true)
      setRegisterButton(false)
      return ;
    }
    else if (res.error === true && res.message === "CountryCode or Vat is invalid"){
      setError({tvaFacturation : "ErrorTva"})
      setLoadingTvaFacturation(false)
      setResponseApiFacturation(true)
      setRegisterButton(false)
      return ;
    } else if (res.error === true && res.message === "Service vat unavailable") {
      setError({tvaFacturation : "ErrorTvaApi"})
      setLoadingTvaFacturation(false)
      setResponseApiFacturation(true)
      setRegisterButton(false)
      return ;
    }
    else {
      setError({tvaFacturation : "ErrorTvaApi"})
      setLoadingTvaFacturation(false)
      setResponseApiFacturation(true)
      setRegisterButton(false)
      return ;
    }
  }).catch((e) => {
    setLoadingTvaFacturation(false)
    setResponseApiFacturation(true)
    setRegisterButton(false)
    setError({tvaFacturation: "ErrorTvaApi"})
  })
}

const renderTypeSociety = () => {
  return (
    <Fade>
    <Row style={{marginTop: 20}}>
      <Col xl={3} lg={3} sm={12} md={12}>
        <div style={{display: 'flex', textAlign: 'left', alignItems: 'center'}}>
          <p style={{}}>{intl.formatMessage({id: 'HaveYouTvaModify'})}</p>
        </div>
        <Form>
          {['radio'].map((type) => (
            <div key={`inline-${type}`} style={{textAlign: 'left'}}>
              <Form.Check
              disabled={typeContactFacturation === "society"}
              onClick={() => setTypeContactFacturation('society')}
                inline
                label={intl.formatMessage({id: 'SociteyModify'})}
                name="group1"
                type={type}
                id={`inline-${type}-1`}
              />
              <Form.Check
              disabled={typeContactFacturation === "private"}
                inline
                label={intl.formatMessage({id: 'PrivateModify'})}
                name="group1"
                type={type}
                id={`inline-${type}-2`}
                onClick={() => setTypeContactFacturation('private')}
              />
            </div>
          ))}
        </Form>
      </Col>
    </Row>
  </Fade>
  )
} 

const handleSubmitTVAFacturation = async (event) => {
  setLoadingTvaFacturation(true)
  event.preventDefault();
  const form = event.currentTarget;
  tvaAPIFacturation()
  if (form.checkValidity() === false)
    event.stopPropagation();
  setValidatedTVAFacturation(true);
  };

  const _renderTvaApiFacturation = () => {
    return (
      <Form noValidate validated={validatedTVAFacturation} onSubmit={handleSubmitTVAFacturation} style={{marginTop: 25}}>
      <Row>
        <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "NumeroTva"})}<span style={{color: 'red'}}> *</span></p>
        <Col xl={1} lg={2} sm={2} md={2} style={{margin: 2}}>
          <Form.Control
          required
          value={tvaCountryFacturation}
          placeholder="BE"
          onChange={(event) => setTvaCountryFacturation(event.target.value)}
          />
        </Col>
        <Col xl={5} lg={5} sm={6} md={4} style={{margin: 2}}>
          <Form.Control
          required
          value={enleverLettres(tvaNumberFacturation)}
          placeholder="0123"
          onChange={(event) => {setResponseApiFacturation(false); setRegisterButton(false) ; setTvaNumberFacturation(event.target.value)}}
          />
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.tvaFacturation ? error.tvaFacturation : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.tvaFacturation ? error.tvaFacturation : "Space" })}
          </Form.Control.Feedback>
        </Col>

        {
          responseApiFacturation === false ?
          <Col xl={3} lg={3} sm={6} md={6} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 2}}>
        <Button
        type="submit"
        style={{backgroundColor: '#082331', fontSize: "0.90rem", color: "white", fontWeight: 'bold', padding: 8,  border: "none"}}
        >
        {
          loadingTvaFacturation === true ?
          <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
              <CircularProgress color="white" size={20}/>
            </div>
            <div>
              <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: "Verify"})}</p>
            </div>
          </div>
          :
          <p style={{margin: 0}}>{intl.formatMessage({id: "Verify"})}</p>
        }
        </Button>
        </Col>
          :
          <>
          </>
        }
        
      </Row>
    </Form>
    )
  }

  const _renderFacturationInfo = () => {
    return (
      <>
      {_renderTvaApiFacturation()}
   
        <>
        <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 25}}>
        <Row>
          <Form.Group as={Col} xl={8} lg={6} sm={12} md={12} controlId="formGridSociety" >
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Societe"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            placeholder={intl.formatMessage({id: "EntrezSociete"})}
            value={facturationSociety}
            onChange={(event) => setFacturationSociety(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.setFacturationSociety ? error.setFacturationSociety : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.setFacturationSociety ? error.setFacturationSociety : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col} xl={1} lg={6} sm={12} md={12} controlId="formGridCity">

          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "NumeroRue"})}<span style={{color: 'red'}}> *</span></p>
          <Form.Control
          required
          value={facturationStreetNumber}
          placeholder="00"
          onChange={(event) => setFacturationStreetNumber(event.target.value)}
          />
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.facturationStreetNumber ? error.facturationStreetNumber : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.facturationStreetNumber ? error.facturationStreetNumber : "Space" })}
          </Form.Control.Feedback>
          {/* a verifier demain*/}
          </Form.Group>

          <Form.Group as={Col} xl={7} lg={6} sm={12} md={12} controlId="formGridCity">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "AdressePostale"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            placeholder={intl.formatMessage({id: "EntrezAdressePostal"})}
            value={facturationStreet}
            onChange={(event) => setFacturationStreet(event.target.value)}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationStreet ? error.street : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationStreet ? error.street : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridZip">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "CodePostal"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="number"
            placeholder={intl.formatMessage({id: "EntrezCodePostal"})}
            value={facturationZipCode}
            onChange={(event) => setFacturationZipCode(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationZipCode ? error.zipCode : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationZipCode ? error.zipCode : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridCity">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Ville"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            placeholder={intl.formatMessage({id: "EntrezVille"})}
            value={facturationCity}
            onChange={(event) => setFacturationCity(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationCity ? error.city : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationCity ? error.city : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          </Row>

          {/*VERIFIER SI EMAIL VALIDEEEEEEEEEEEEE*/}
          
          <Row>
          <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridZip">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "EnterEmailFacturationTo"})}<span style={{color: 'red'}}></span></p>
              <Form.Control
              type="email"
              placeholder={intl.formatMessage({id: "EmailFacturationTo"})}
              value={emailFacturationTo}
              onChange={(event) => setEmailFacturationTo(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.emailFacturationTo ? error.emailFacturationTo : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.emailFacturationTo ? error.emailFacturationTo : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridCity">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "EnterPo"})}<span style={{color: 'red'}}> </span></p>
              <Form.Control
              type="text"
              placeholder={intl.formatMessage({id: "Po"})}
              value={productOrderFacturation}
              onChange={(event) => setProductOrderFacturation(event.target.value)}/>
            </Form.Group>
          </Row>
          </Form>
        </>
  
      </>
    )
  }

  const _renderFacturationInfoPrivate = () => {
    return ( 
        <>
         <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 25}}>
          <Row>
          <Form.Group as={Col} xl={1} lg={6} sm={12} md={12} controlId="formGridCity">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "NumeroRue"})}<span style={{color: 'red'}}> *</span></p>
          <Form.Control
          required
          value={facturationStreetNumber}
          placeholder="00"
          onChange={(event) => setFacturationStreetNumber(event.target.value)}
          />
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.facturationStreetNumber ? error.facturationStreetNumber : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.facturationStreetNumber ? error.facturationStreetNumber : "Space" })}
          </Form.Control.Feedback>
          {/* a verifier demain*/}
          </Form.Group>

          <Form.Group as={Col} xl={7} lg={6} sm={12} md={12} controlId="formGridCity">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "AdressePostale"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            placeholder={intl.formatMessage({id: "EntrezAdressePostal"})}
            value={facturationStreet}
            onChange={(event) => setFacturationStreet(event.target.value)}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationStreet ? error.street : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationStreet ? error.street : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridZip">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "CodePostal"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="number"
            placeholder={intl.formatMessage({id: "EntrezCodePostal"})}
            value={facturationZipCode}
            onChange={(event) => setFacturationZipCode(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationZipCode ? error.zipCode : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationZipCode ? error.zipCode : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridCity">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Ville"})}<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            placeholder={intl.formatMessage({id: "EntrezVille"})}
            value={facturationCity}
            onChange={(event) => setFacturationCity(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationCity ? error.city : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.facturationCity ? error.city : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          </Row>

          {/*VERIFIER SI EMAIL VALIDEEEEEEEEEEEEE*/}
          
          <Row>
          <Form.Group as={Col} xl={4} lg={6} sm={12} md={12} controlId="formGridZip">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "EnterEmailFacturationTo"})}<span style={{color: 'red'}}></span></p>
              <Form.Control
              
              type="email"
              placeholder={intl.formatMessage({id: "EmailFacturationTo"})}
              value={emailFacturationTo}
              onChange={(event) => setEmailFacturationTo(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.emailFacturationTo ? error.emailFacturationTo : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                {intl.formatMessage({id: error.emailFacturationTo ? error.emailFacturationTo : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          </Form>
        </>
  
     
    )
  }

const updateProfil = () => {
  setLoading(true)
  fetch(Constant.IP_BACKEND_DNS + '/updateMemberInfo',{
    method:'POST',
    headers:{
    'Content-Type': 'application/json',
    'Accept': 'application/json'},
    body:JSON.stringify({
      accesToken : cookies.get('access'),
      registrationType : registrationType,
      image: image,
      firstName : firstName, //obligatory
      lastName: lastName, //obligatory
      zipCode : zipCode, //obligatory
      city : city, //obligatory
      street : street, //obligatory
      tel : tel, //obligatory
      email: email, //obligatory
      day : day, //obligatory
      mounth: mounth, //obligatory
      year : year, //obligatory
      commentary: commentary,
      society: society, //obligatory
      tva : tva, //obligatory
      status: status,
      sector : sector,
      zipCodePro: zipCodePro,
      cityPro: cityPro,
      streetPro: streetPro,
      telPro: telPro,
      emailPro: emailPro,
      dayPro: dayPro,
      mounthPro: mounthPro,
      yearPro: yearPro,
      localisation: localisation,
      sizeOfSociety: sizeOfSociety,
      memberView: memberView,
      facturationSociety: facturationSociety,
      facturationStreet: facturationStreet,
      facturationZipCode: facturationZipCode,
      facturationCity: facturationCity,
      facturationTva: facturationTva,
      facturationStreetNumber: facturationStreetNumber, //**
      typeContactFacturation: typeContactFacturation, //**
      tvaCountryFacturation: tvaCountryFacturation,
      tvaNumberFacturation: tvaNumberFacturation,
      emailFacturationTo: emailFacturationTo,
      productOrderFacturation: productOrderFacturation
      })
  }).then(response=>response.json()).then(res=>{
    if(res.error === false){
      setLoading(false)
      setModalShow(true)
    }
    else if(res.error === true && res.messageObligatory){
      setLoading(false)
      if(res.messageObligatory){
        setError({
          registrationType: res.messageObligatory.registrationType,
          firstName: res.messageObligatory.firstName,
          lastName: res.messageObligatory.lastName,
          email: res.messageObligatory.email,
          date: res.messageObligatory.date,
          tel: res.messageObligatory.tel,
          society: res.messageObligatory.society,
          tva: res.messageObligatory.tva,
          zipCode: res.messageObligatory.zipCodePrivate,
          city: res.messageObligatory.cityPrivate,
          street: res.messageObligatory.streetPrivate,
          date: res.messageObligatory.date,
        })
        if(res.messageObligatory.checkPrivate === 1)
          executeScrollPrivate()
        else if(res.messageObligatory.checkPro === 1)
          executeScrollPro()
      }
    }else if(res.error === true && res.messageNonObligatory){
      setLoading(false)
      if(res.messageNonObligatory.checkProDate === 1)
          executeScrollProDate()
      setError({
        datePro: res.messageNonObligatory.datePro,
        image: res.messageNonObligatory.image,
        facturationStreet: res.messageNonObligatory.facturationStreet,
        facturationStreetNumber: res.messageNonObligatory.facturationStreetNumber,

      })
    }else if(res.error === true && res.message){
      setLoading(false)
      if(res.message.checkImage === 1)
        executeScrollImage()
      setError({
        image: res.message.image
      })
    }else{
      alert(intl.formatMessage({id: "ErrorHappened" }))
    }
      return ;
  }).catch((e) => {
    setLoading(false)
    alert(intl.formatMessage({id: "ErrorNetwork" }))
  })

}

const handleSubmit = (event) => {
  event.preventDefault();
  clear()
  updateProfil()
  const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.stopPropagation();
  }

  setValidated(true);
};

const convertbase64 = (file) => {
    return new Promise((resolve, reject)  => {
      const fileReader = new FileReader();
      try{
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
          resolve(fileReader.result)
        }
        fileReader.onerror = (error) => {
          reject(error)
        }
      }catch(e){
        resolve(null)
      }
    })
}

const updateUploadFiles = async (files) => {
  var file = files[0];
  var base64 = await convertbase64(file)
  var image = {Image: base64, Date: moment().format('YYYY/MM/DD HH:mm:ss')}
  setImage(image)
}

if(cookies.get('access') === undefined)
  return <Redirect push to={`/login/${language}`}/>

  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
    <Navigation/>
      <Fade>
      <div  style={{backgroundColor: "#F6F7FB", paddingTop: "100px"}}>
      <Container className="shadow" style={{backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
      <h2 style={{textAlign: 'left'}}>{intl.formatMessage({id: "ModifyProfilMin" })}</h2>
      <Form ref={refImage} >
        <UpdateImage
          updateFilesCb={updateUploadFiles}/>
        <p style={{textAlign: 'left', color:'red', fontWeight:'bold', marginTop: 25}}>{intl.formatMessage({id: error.image ? error.image : "Space" })}</p>
      </Form>

      <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 25}}>
        <Row ref={refPrivate}>
        <h6 style={{textAlign: 'left', color:'#FC8016'}}>{intl.formatMessage({id: 'PersonalInfo'})}</h6>
        <p style={{textAlign: 'right', fontSize: '0.8rem'}}><span style={{color: 'red'}}> *</span> {intl.formatMessage({id: "FieldObligatory"})}</p>
          <Form.Group as={Col}  xl={6} lg={6} sm={12} md={12} controlId="formGridlastName">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Nom"})} <span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            value={lastName}
            defaultValue={lastName}
            onChange={(event) => setLastName(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {intl.formatMessage({id: error.lastName ? error.lastName : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
              {intl.formatMessage({id: error.lastName ? error.lastName : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col}  xl={6} lg={6} sm={12} md={12} controlId="formGridfirstName">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Prénom"})} <span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            value={firstName}
            defaultValue={firstName}
            onChange={(event) => setFirstName(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {intl.formatMessage({id: error.firstName ? error.firstName : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
              {intl.formatMessage({id: error.firstName ? error.firstName : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
        <Form.Group as={Col} controlId="formGridTel">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Telephone"})} <span style={{color: 'red'}}> *</span></p>
          <Form.Control
          required
          type="text"
          value={tel}
          defaultValue={tel}
          onChange={(event) => setTel(event.target.value)}/>
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
            {intl.formatMessage({id: error.tel ? error.tel : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.tel ? error.tel : "Space" })}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridZip">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "CodePostal"})} <span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="number"
            value={zipCode}
            defaultValue={zipCode}
            onChange={(event) => setZipCode(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {intl.formatMessage({id: error.zipCode ? error.zipCode : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.zipCode ? error.zipCode : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12}  controlId="formGridCity">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Ville"})} <span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            value={city}
            defaultValue={city}
            onChange={(event) => setCity(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {intl.formatMessage({id: error.city ? error.city : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.city ? error.city : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Form.Group controlId="formGridAddress">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "AdressePostale"})} <span style={{color: 'red'}}> *</span></p>
          <Form.Control
          required
          type="text"
          value={street}
          defaultValue={street}
          onChange={(event) => setStreet(event.target.value)}/>
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
            {intl.formatMessage({id: error.street ? error.street : "Space" })}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {intl.formatMessage({id: error.street ? error.street : "Space" })}
          </Form.Control.Feedback>
        </Form.Group>

        <Row>
          <Form.Group as={Col} xl={4} lg={4} sm={12} md={12} controlId="formGridDay">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "DateNaissance"})} <span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            placeholder={intl.formatMessage({id: "Jour"})}
            value={day}
            defaultValue={day}
            onChange={(event) => setDay(event.target.value)}/>
           <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
             {intl.formatMessage({id: error.date ? error.date : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.date ? error.date : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xl={4} lg={4} sm={12} md={12}  controlId="formGridMounth">
            <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>{intl.formatMessage({id: "DateNaissance"})}</p>
            <Form.Control
            required
            type="text"
            placeholder={intl.formatMessage({id: "Mois"})}
            value={mounth}
            defaultValue={mounth}
            onChange={(event) => setMounth(event.target.value)}/>
          </Form.Group>
          <Form.Group as={Col} xl={4} lg={4} sm={12} md={12}  controlId="formGridYear">
            <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>{intl.formatMessage({id: "DateNaissance"})}</p>
            <Form.Control
            required
            type="text"
            placeholder={intl.formatMessage({id: "Annee"})}
            value={year}
            defaultValue={year}
            onChange={(event) => setYear(event.target.value)}/>
          </Form.Group>
        </Row>

        <Form.Group controlId="formGridSociety">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: 'Commentray'})}</p>
          <Form.Control
          type="text"
          value={commentary}
          defaultValue={setCommentary}
          onChange={(event) => setCommentary(event.target.value)}/>
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
            {intl.formatMessage({id: error.commentary ? error.commentary : "Space" })}
          </Form.Control.Feedback>
        </Form.Group>

        <Row ref={refPro}>
        <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>{intl.formatMessage({id: 'ProInfo'})}</h6>

    


          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridSociety">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: 'Societe'})} <span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            value={society}
            defaultValue={society}
            onChange={(event) => setSociety(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {intl.formatMessage({id: error.society ? error.society : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.society ? error.society : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridTva">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: 'NumeroTva'})} <span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            value={tva}
            defaultValue={tva}
            onChange={(event) => setTva(event.target.value)}/>
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {intl.formatMessage({id: error.tva ? error.tva : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.tva ? error.tva : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>

        </Row>

        <Row>
        <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridStatus">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: 'Poste'})}</p>
          <Form.Control
          type="text"
          value={status}
          defaultValue={status}
          onChange={(event) => setStatus(event.target.value)}/>
        </Form.Group>
        <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridSector">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: 'Sector'})}</p>
            <select value={sector} class="form-control" onChange={(event) => setSector(event.target.value)}>
            <option selected>{intl.formatMessage({id: 'SectorDescription'})}</option>
            <option value="1">
              {intl.formatMessage({id: 'Immobilier'})}
            </option>
            <option value="2">
              {intl.formatMessage({id: 'Banques'})}
            </option>
            <option value="3">
              {intl.formatMessage({id: 'Industriel'})}
            </option>
            <option value="4">
              {intl.formatMessage({id: 'It'})}
            </option>
            <option value="5">
              {intl.formatMessage({id: 'Biens'})}
            </option>
            <option value="6">
              {intl.formatMessage({id: 'Commerces'})}
            </option>
            <option value="7">
              {intl.formatMessage({id: 'Avocat'})}
            </option>
            <option value="8">
              {intl.formatMessage({id: 'Consultance'})}
            </option>
            <option value="9">
              {intl.formatMessage({id: 'Autres'})}
            </option>
          </select>
        </Form.Group>

        </Row>

        <Row>
          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridCityPro">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Ville"})}</p>
            <Form.Control
            type="text"
            value={cityPro}
            defaultValue={cityPro}
            onChange={(event) => setCityPro(event.target.value)}/>
          </Form.Group>

          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridZipCodePro">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "CodePostal"})}</p>
            <Form.Control
            type="text"
            value={zipCodePro}
            defaultValue={zipCodePro}
            onChange={(event) => setZipCodePro(event.target.value)}/>
          </Form.Group>
        </Row>

        <Form.Group controlId="formGridStreetPro">
          <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "AdressePostale"})}</p>
          <Form.Control
          type="text"
          value={streetPro}
          defaultValue={streetPro}
          onChange={(event) => setStreetPro(event.target.value)}/>
        </Form.Group>

          <Row>
            <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridTelPro">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Telephone"})}</p>
              <Form.Control
              type="text"
              value={telPro}
              defaultValue={telPro}
              onChange={(event) => setTelPro(event.target.value)}/>
            </Form.Group>

            <Form.Group ref={refProDate} xl={6} lg={6} sm={12} md={12} as={Col} controlId="formGridEmailPro">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Email"})}</p>
              <Form.Control
              type="text"
              value={emailPro}
              defaultValue={emailPro}
              onChange={(event) => setEmailPro(event.target.value)}/>
            </Form.Group>
          </Row>

        <Row>
        <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>{intl.formatMessage({id: "ComplInfo"})}</h6>
          <Form.Group as={Col} xl={4} lg={4} sm={12} md={12} controlId="formGridDayPro">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "DateSociety"})}</p>
            <Form.Control
            type="text"
            placeholder={intl.formatMessage({id: "Jour"})}
            value={dayPro}
            defaultValue={dayPro}
            onChange={(event) => setDayPro(event.target.value)}/>
           <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.datePro ? error.datePro : "Space" })}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {intl.formatMessage({id: error.datePro ? error.datePro : "Space" })}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xl={4} lg={4} sm={12} md={12} controlId="formGridMounthPro">
            <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>{intl.formatMessage({id: "DateSociety"})}</p>
            <Form.Control
            type="text"
            placeholder={intl.formatMessage({id: "Mois"})}
            value={mounthPro}
            defaultValue={mounthPro}
            onChange={(event) => setMounthPro(event.target.value)}/>
          </Form.Group>
          <Form.Group as={Col} xl={4} lg={4} sm={12} md={12} controlId="formGridYearPro">
            <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>{intl.formatMessage({id: "DateSociety"})}</p>
            <Form.Control
            type="text"
            placeholder={intl.formatMessage({id: "Annee"})}
            value={yearPro}
            defaultValue={yearPro}
            onChange={(event) => setYearPro(event.target.value)}/>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridLocalisation">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "LocalisationSociety"})}</p>
            <Form.Control
            type="text"
            placeholder={intl.formatMessage({id: "Belgique"})}
            value={localisation}
            defaultValue={localisation}
            onChange={(event) => setLocalisation(event.target.value)}/>
          </Form.Group>

          <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridSizeOfSociety">
            <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "SizeSociety"})}</p>
              <select value={sizeOfSociety} class="form-control" onChange={(event) => setSizeOfSociety(event.target.value)}>
              <option selected>{intl.formatMessage({id: "IndiquerSizeOfSociety"})}</option>
              <option value="-10">
              {intl.formatMessage({id: "less10"})}
              </option>
              <option value="10/50">
              {intl.formatMessage({id: "between10"})}
              </option>
              <option value="50/200">
              {intl.formatMessage({id: "to50"})}
              </option>
              <option value="200">
              {intl.formatMessage({id: "to200"})}
              </option>
            </select>
          </Form.Group>
        </Row>

        </Form>

        {
          registrationType === "Young" ?
          <>
          </>
          :
          <>
          <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 30}}>{intl.formatMessage({id: "CoordonneesFacturation"})}</h6>
          {renderTypeSociety()} 
          {
            typeContactFacturation === "society" ?
            <>
            {_renderFacturationInfo()}
            </>
            :
            <>
            {_renderFacturationInfoPrivate()}
            </>
          }
          </>
        }
  
        <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 25}}>
      {
        registerButton === true ?
        <div className="centerButton" style={{marginBottom: 15}}>
        <Button
        style={{marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}}
        type="submit">
        {
          loading === true ?
          <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
              <CircularProgress color="white" size={20}/>
            </div>
            <div>
              <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: "Enregistrer"})}</p>
            </div>
          </div>
          :
          <p style={{margin: 0}}>{intl.formatMessage({id: "Enregistrer"})}</p>
        }
        </Button>
      </div>
        :

        <>
        </>
      }
        <MyVerticallyCenteredModal
          show={modalShow}
          intl={intl}
          onHide={() => setModalShow(false)}
        />

      </Form>

    </Container>

    <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%', marginTop: 80}}/>
      </div>
      <div style={{backgroundColor: '#FFFFFF'}}>
      <Sponsor/>
      </div>
      <Footer/>
      </Fade>
    </div>
  );
}

export default Modify;

import './Footer.css';
import {Row, Col, Image} from 'react-bootstrap';
import FacebookH from '../../image/facebook-h.png'
import InstagramH from '../../image/instagram-h.png'
import LinkedinH from '../../image/linkedin-h.png'
import PlayStore from '../../image/playstore.png'
import AppStore from '../../image/appstore.png'
import LogoApp from '../../image/logoApp.png'
import Condition from '../../image/ConditionsGenerales_B19-merged.pdf'
import { useIntl } from 'react-intl';

import 'bootstrap/dist/css/bootstrap.min.css'

function Footer() {
  const intl = useIntl();

  return (
    <footer id="footer" className="dark">
        <div className="container-fluid text-center text-md-left">
            <div className="row">
                <div className="col-md-6 mt-md-0 mt-3">
                    <h5 className="text-uppercase" style={{color: 'black'}}>{intl.formatMessage({id: 'ProposDeNous'})}</h5>
                    <p style={{color: 'black', textAlign: 'center'}}>{intl.formatMessage({id: 'ProposDeNousDescription'})}</p>
                    <div style={{backgroundColor: 'white', height: 2, marginBottom: 10}}/>
                    <h5 className="text-uppercase" style={{color: 'black'}}>{intl.formatMessage({id: 'NotreApplication'})}</h5>
                    <Row>
                    <div>
                    <Image style={{borderRadius: 15}}  src={LogoApp} />
                    </div>
                    <Col>
                      <a href="https://apps.apple.com/app/b19/id1242357187" style={{color: 'black', textDecoration: 'none'}}><Image  src={AppStore} /></a>
                      <a href="https://play.google.com/store/apps/details?id=com.tapptic.b19countryclub&hl=fr&gl=US" style={{color: 'black', textDecoration: 'none'}}><Image  src={PlayStore} /></a>
                    </Col>
                    </Row>
                </div>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <div className="col-md-3 mb-md-0 mb-3">
                    <h5 className="text-uppercase" style={{color: 'black'}}>{intl.formatMessage({id: 'Social'})}</h5>
                    <Row>
                    <ul>
                    <Col>
                    <Image style={{width: 45, height: 45}} src={FacebookH}  />
                    <a href="https://www.facebook.com/B19BusinessClub" style={{color: 'black', textDecoration: 'none'}}>Facebook</a>
                    </Col>
                    <Col>
                    <Image style={{width: 45, height: 45}} src={InstagramH}  />
                    <a href="https://www.instagram.com/b19_business_club/" style={{color: 'black', textDecoration: 'none'}}>Instagram</a>
                    </Col>
                    <Col>
                    <Image style={{width: 40, height: 40}} src={LinkedinH}  />
                    <a href="https://www.linkedin.com/company/b19-country-club" style={{ color: 'black', textDecoration: 'none'}}>Linkedin</a>
                    </Col>
                    </ul>
                    </Row>
                </div>
                <div className="col-md-3 mb-md-0 mb-3">
                    <h5 className="text-uppercase" style={{color: 'black'}}>{intl.formatMessage({id: 'Support'})}</h5>
                    <ul className="list-unstyled">
                        <li>
                        <a href="https://b19.be/en/contact/" style={{color: 'black', textDecoration: 'none'}}>{intl.formatMessage({id: 'NousConacter'})}</a></li>
                    </ul>
                    <h5 className="text-uppercase" style={{color: 'black'}}>{intl.formatMessage({id: 'AutresSitesB19'})}</h5>
                    <ul className="list-unstyled">
                        <li>
                        <a href="https://www.newb19.be/location/" style={{color: 'black', textDecoration: 'none'}}>{intl.formatMessage({id: 'Locattion'})}</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="footer-copyright text-center py-3" style={{color: 'black'}}>{intl.formatMessage({id: 'ToutDroits'})}
            <a href={Condition} target="_blank" style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>{intl.formatMessage({id: 'ConditionsGenerales'})}</a>
            {intl.formatMessage({id: 'FaitPar'})}<a href="https://nyugo.be" target="_blank" style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>Nyugo</a>
        </div>
    </footer>
  );
}

export default Footer;

import React, { useState, useEffect } from "react";
import "./Nav.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "universal-cookie";
import { useIntl } from "react-intl";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AiOutlineUser } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import Boost from "../../image/ThumbLogo.png";
import { useParams } from "react-router-dom";

const cookies = new Cookies();

function Navigation() {
  const intl = useIntl();

  const { language } = useParams();

  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (cookies.get("access")) setIsConnected(true);
    else setIsConnected(false);
  }, []);

  const removeCookies = () => {
    cookies.remove("access", { path: "/" });
    //setIsConnected(false)
  };

  return (
    <div style={{ position: "relative" }}>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          direction="horizontal"
          fixed="top"
          collapseOnSelect
          expand={expand}
          style={{ paddingTop: "35px", background: "#F6F7FB" }}
        >
          <Container fluid>
            <Navbar.Toggle
              style={{ borderColor: "transparent", color: "transparent" }}
              aria-controls={`offcanvasNavbar-expand-${expand}`}
            />

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                ></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav
                  className="justify-content-center flex-grow-1 pe-3"
                  style={{
                    fontSize: "13px",
                    marginLeft: "25px",
                    fontFamily: "Typold Regular",
                  }}
                >
                  {/*  <Nav.Link style={{fontFamily: 'Typold Regular', fontWeight: 'bold', margin: 5}} href="/home" >{intl.formatMessage({id: 'Home'})}</Nav.Link> */}
                  {/* <Nav.Link style={{fontWeight: 'bold', margin: 5}} href="/agenda">{intl.formatMessage({id: 'Agenda'})}</Nav.Link> */}
                  {/*  <Nav.Link style={{fontWeight: 'bold', margin: 5}} href="/news">NEWS</Nav.Link>  */}
                  <Nav.Link
                    style={{ fontWeight: "bold", margin: 5 }}
                    href={`/member/${language ? language : 'fr'}`}
                  >
                    {intl.formatMessage({ id: "Member" })}
                  </Nav.Link>
                  <Nav.Link
                    style={{ fontWeight: "bold", margin: 5 }}
                    href={`/gallery/${language ? language : 'fr'}`}
                  >
                    {intl.formatMessage({ id: "Galerie" })}
                  </Nav.Link>
                  {/*<Nav.Link style={{fontWeight: 'bold', margin: 5}} href="/contact">{intl.formatMessage({id: 'ContactMaj'})}</Nav.Link>*/}
                  <Nav.Link
                    style={{ fontWeight: "bold", margin: 5 }}
                    href={`/faq/${language ? language : 'fr'}`}
                  >
                    {intl.formatMessage({ id: "Faq" })}
                  </Nav.Link>
                  <Nav.Link
                    style={{ fontWeight: "bold", margin: 5 }}
                    href="/language"
                  >
                    {intl.formatMessage({ id: "Langue" })}
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <div
              style={{
                position: "absolute",
                left: "25%",
                right: "25%",
                top: 28,
                zIndex: 1001,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a href="https://www.b19.be">
                  <LazyLoadImage
                    className="NavLogoB19"
                    alt="Boost_your_business"
                    style={{ objectFit: "contain", width: "50px" }}
                    src={Boost}
                  />
                </a>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isConnected === true ? (
                <>
                  <AiOutlineUser
                    className="NavLogoB19"
                    size={23}
                    style={{ marginBottom: "4px", marginRight: "8px" }}
                  />
                  <Nav.Link
                    className="marginRightNav"
                    style={{
                      fontFamily: "Typold Regular",
                      fontWeight: "bold",
                      zIndex: 1001,
                    }}
                    href={`/profil/${language ? language : 'fr'}`}
                  >
                    {intl.formatMessage({ id: "MyProfil" }).toUpperCase()}
                  </Nav.Link>
                  <FiLogOut
                    className="NavLogoB19"
                    size={23}
                    style={{ marginRight: "8px" }}
                  />
                  <Nav.Link
                    style={{
                      fontFamily: "Typold Regular",
                      fontWeight: "bold",
                      margin: 5,
                    }}
                    href={`/login/${language ? language : 'fr'}`}
                    onClick={() => removeCookies()}
                  >
                    {intl.formatMessage({ id: "Disconnect" })}
                  </Nav.Link>
                </>
              ) : (
                <>
                  <FiLogOut size={23} style={{ marginRight: "8px" }} />
                  <Nav.Link
                    style={{
                      fontFamily: "Typold Regular",
                      fontWeight: "bold",
                      margin: 5,
                    }}
                    href={`/login/${language ? language : 'fr'}`}
                
                  >
                    {intl.formatMessage({ id: "Connect" })}
                  </Nav.Link>
                </>
              )}
            </div>
          </Container>
        </Navbar>
      ))}
    </div>
  );
}

export default Navigation;

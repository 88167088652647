import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { Container } from 'react-bootstrap';
import Constant from '../../networking/Constant'
import Cookies from 'universal-cookie'

import Navigation from '../navBar/NavDark'
import Sponsor from '../sponsor/Sponsor'
import Footer from '../footer/Footer'
import { useIntl } from 'react-intl';

const cookies = new Cookies();


function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
            {props.intl.formatMessage({id: 'Erreur'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({id: 'ErrorTryLater'})}</h4>
        <p style={{marginTop: 25}}>
          {props.intl.formatMessage({id: 'ErrorContactStaff'})}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button href="/agenda" style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={props.onHide}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

function SuccesEvent(props) {
  const intl = useIntl();

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    fetch(Constant.IP_BACKEND_DNS + '/updateEventStatus',{
      method:'POST',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
      body:JSON.stringify({
        paymentToken: props.match.params.token,
        price: props.match.params.price,
        name: props.match.params.name,
        idEvent: props.match.params.idEvent,
        source: props.match.params.source,
        status: 'true',
        method: "Stripe"})
    }).then(response=>response.json()).then(res=>{
      if(res.error === false){
        fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/SubscribeEvents/',{
          method:'POST',
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json'},
          body:JSON.stringify({Jwt :props.match.params.accesToken, IdEvent: props.match.params.idEvent, Source: "Website"})
        }).then(response=>response.json()).then(res=>{
          if(res.error === true){
          setModalShow(true)
          }
          else if(res.error === false){
          console.log("G")
          }
        }).catch((e) => {
          setModalShow(true)
        })
      } else {
        console.log("F")
      }
    })

  }, [])


  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
    <Navigation/>
      <Fade>
      <div style={{backgroundColor: "#F6F7FB", paddingTop: 180, paddingBottom: 40}}>
        <Container className="shadow" style={{backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
          <h1>{intl.formatMessage({id: 'SuccesPayment'})}</h1>
        </Container>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        intl={intl}
        onHide={() => setModalShow(false)}
      />
        <div style={{backgroundColor: '#FFFFFF'}}>
        <Sponsor/>
        </div>
      <Footer/>
      </Fade>
    </div>
  );
}

export default SuccesEvent;

import React from 'react';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useState } from "react";
import store from './redux/store'
import { Provider } from 'react-redux'
import ScrollToTop from './ScrollToTop'
import messages from './message';
import Agenda from './components/agenda/Agenda'
import Consulting from './components/agenda/Consulting'
import ConsultingApp from './components/agenda/ConsultingApp'
import Subscription from './components/subscription/Subscription'
//import SubscriptionYoung from './components/subscription/SubscriptionYoung'
import Member from './components/member/Member'
import MemberProfil from './components/member/MemberProfil'
import Profil from './components/profil/Profil'
import Modify from './components/member/Modify'
import UpdatePassword from './components/profil/UpdatePassword'
import UpdateEmail from './components/profil/UpdateEmail'
import Map from './components/ambassador/Map'
import Login from './components/login/Login'
import LoginApp from './components/login/LoginApp'
import ForgetPassword from './components/forgetPassword/ForgetPassword'
import Gallery from './components/gallery/Gallery'
import ConsultingAlbum from './components/gallery/ConsultingAlbum'
//import Contact from './components/contact/Contact'
//import NotFound from './components/notFound/NotFound'
import reportWebVitals from './reportWebVitals';
import Succes from './components/statusPayment/Succes'
import SuccesEvent from './components/statusPayment/SuccesEvent'
import SuccesPublicEvent from './components/statusPayment/SuccesPublicEvent'
import SuccesSubscription from './components/statusPayment/SuccesSubscription'
import Failed from './components/statusPayment/Failed'
import FailedEvent from './components/statusPayment/FailedEvent'
import FailedPublicEvent from './components/statusPayment/FailedPublicEvent'
import FailedSubscription from './components/statusPayment/FailedSubscription'
import UrlConsulting from './components/agenda/UrlConsulting'
import ForgetPasswordToken from './components/forgetPassword/ForgetPasswordToken'
import SubscriptionStripe from './components/subscriptionStripe/subscriptionStripe'
//import News from './components/news/News'
import DeleteAccount from './components/deleteAccount/DeleteAccount'
import DeleteAccountEN from './components/deleteAccount/DeleteAccountEN'
import FormDeleteSub from './components/deleteAccount/FormDeleteSub'
import Language from './components/language/Language'
//import Home from './components/home/Home';
//import detectBrowserLanguage from 'detect-browser-language'
import { IntlProvider } from 'react-intl';
import Cookies from 'universal-cookie'
import HelmetMetaData from "./components/HelmetMetaData/HelmetMetaData";
import { hydrate, render } from "react-dom";
import Boost from './image/BOOST.png'
import RedirectComponent from './components/redirectComponent';
const cookies = new Cookies();

//import './i18n'

function Root() {
  const [locale, setLocale] = useState('fr');
/*
  const verifyLanguage = (language) => {
    var languageSupported = ["fr", 'nl']
    if (!language)
      return (0);
    if (languageSupported.findIndex(res => res === language) !== -1)
      return (1);
    return (0);
  }

  const SetLanguage = () => {
    try {
      var language = cookies.get('language')
      if (verifyLanguage(language))
      {
        setLocale(language)
        return ;
      }
      var language = detectBrowserLanguage();
      if (!language){
        setLocale('fr')
        return ;
      }
      var formatLanguage = language.split('-')[0]
      if (formatLanguage !== 'fr' && formatLanguage !== 'nl')
        setLocale('fr')
      else
        setLocale(formatLanguage)
    } catch (e) {
      setLocale('fr')
    }
  }

  useEffect(() => {
    var language = cookies.get('language')
    if (!verifyLanguage(language))
      SetLanguage();
    else
      setLocale(language)
  }, [])*/

  return (
    <BrowserRouter>
    <IntlProvider locale={locale} messages={messages[locale]}>

      <HelmetMetaData
        title={"B19"}
        description={"B19 | Boost your Business"}
        image={Boost}
        ></HelmetMetaData>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={(props) => <RedirectComponent setLocale={setLocale} {...props}/>} />
           <Route 
            exact 
            path='/agenda/language/:language?' 
            render={(props) => <Agenda setLocale={setLocale} {...props}/>}
            />
            <Route 
              exact 
              path='/agenda/:language?/:idEvent' 
              render={(props) => <Consulting setLocale={setLocale} {...props}/>}
            />
           {/* <Route exact path='/home' component={Home}/> */}
            <Route exact path='/agendaApp/:accesToken&:idEvent' component={ConsultingApp}/>
            <Route exact path='/invitation/:idEvent/:idMember?' component={(props) => <UrlConsulting setLocale={setLocale} {...props}/>}/>
            <Route exact path='/login/:language/:idEvent?' component={(props) => <Login setLocale={setLocale} {...props}/>}/>
            <Route exact path='/loginApp/:email&:accesToken&:registrationType&:region&:id&:accept' component={LoginApp}/>
            <Route exact path='/member/:language' component={(props) => <Member setLocale={setLocale} {...props}/>}/>
            <Route exact path='/memberProfil/:language' component={(props) => <MemberProfil setLocale={setLocale} {...props}/>}/>
            <Route exact path='/profil/:language' component={(props) => <Profil setLocale={setLocale} {...props}/>}/>
            <Route exact path='/ambassador/:region' component={Map}/>
            <Route exact path='/modify/:language?' component={(props) => <Modify setLocale={setLocale} {...props}/>}/>
            <Route exact path='/updatePassword/:language' component={(props) => <UpdatePassword setLocale={setLocale} {...props}/>}/>
            <Route exact path='/updateEmail/:language' component={(props) => <UpdateEmail setLocale={setLocale} {...props}/>}/>
            <Route exact path='/subscription/:language' component={(props) => <Subscription setLocale={setLocale} {...props}/>}/>
            {/*<Route exact path='/subscriptionYoung' component={SubscriptionYoung}/>*/}
            <Route exact path='/subscriptionStripe/:id' component={SubscriptionStripe}/>
            <Route exact path='/succes/:token/:registrationType' component={Succes}/>
            <Route exact path='/succesSubscription/:token/:registrationType' component={SuccesSubscription}/>
            <Route exact path='/succesEvent/:token&:price&:name&:idEvent&:source&:accesToken' component={SuccesEvent}/>
            <Route exact path='/succesPublicEvent/:token&:price&:name&:idEvent&:source' component={SuccesPublicEvent}/>
            <Route exact path='/failed/:token/:registrationType' component={Failed}/>
            <Route exact path='/failedSubscription/:token/:registrationType' component={FailedSubscription}/>
            <Route exact path='/failedEvent/:token&:price&:name&:idEvent&:source' component={FailedEvent}/>
            <Route exact path='/failedPublicEvent/:token&:price&:name&:idEvent&:source' component={FailedPublicEvent}/>
            <Route exact path='/forgetPassword/:language' component={(props) => <ForgetPassword setLocale={setLocale} {...props}/>}/>
            <Route exact path='/forgetPasswordToken/:language' component={(props) => <ForgetPasswordToken setLocale={setLocale} {...props}/>}/>
            <Route exact path='/gallery/:language?' component={(props) => <Gallery setLocale={setLocale} {...props}/>}/> 
            <Route exact path='/consultingAlbum/:language?/:id/:idPhoto?' component={(props) => <ConsultingAlbum setLocale={setLocale} {...props}/>}/>
            {/* <Route exact path='/contact' component={Contact}/> */}
            {/* <Route exact path='/news' component={News}/> */}
            <Route exact path='/faq/:language' component={(props) => <DeleteAccount setLocale={setLocale} {...props}/>}/>
            <Route exact path='/faq/en' component={DeleteAccountEN}/>
            <Route exact path='/deleteSub/:language' component={(props) => <FormDeleteSub setLocale={setLocale} {...props}/>}/>
            <Route exact path='/language' component={(props) => <Language setLocale={setLocale} {...props}/>}/>
            <Route component={(props) => <App setLocale={setLocale} {...props}/>} />
          </Switch>
        </ScrollToTop>
      </IntlProvider>
    </BrowserRouter>
  )

}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <Root  />
    </Provider>, rootElement);
} else {
  render(
    <Provider store={store}>
      <Root  />
    </Provider>, rootElement);
}

reportWebVitals();

/*
*/

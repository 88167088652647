import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
export default function HelmetMetaData(props) {

   let location = useLocation();
   let currentUrl = "https://newb19.be/" + location.pathname;
   let quote = props.quote !== undefined ? props.quote : "";
   let title = props.title !== undefined ? props.title : "B19";
   let image = props.image !== undefined ? props.image : "";
   let description = props.description !== undefined ? props.description  : "";
   let hashtag = props.hashtag !== undefined ? props.hashtag : "";
return (
 <Helmet>
     <title>{title}</title>
     <meta charset="utf-8" />
     <meta http-equiv="X-UA-Compatible" content="IE=9,chrome=1" />
     <meta http-equiv="Content-Language" content="fr" />
     <meta http-equiv="Expires" content="never" />
     <meta http-equiv="author" content="B19" />
     <meta http-equiv="Copyright" content="B19, 2023" />
     <meta property="url" content={currentUrl} />
     <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
     <meta name="robots" content="noindex, nofollow" />
     <meta property="title" content={title} />
     <meta property="quote" content={quote} />
     <meta name="description" content={description} />
     <meta property="image" content={image} />
     <meta property="og:locale" content="fr" />
     <meta property="og:type" content="article" />
     <meta property="og:title" content={title} />
     <meta property="og:quote" content={quote} />
     <meta property="og:image" content={image} />
     <meta property="og:url" content={currentUrl} />
     <meta property="og:site_name" content="B19" />
     <meta property="og:description" content={description} />    </Helmet>
);
}

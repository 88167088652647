import React, { useEffect, useState } from 'react';
import './ConsultingAlbum.css'
import { Fade } from "react-awesome-reveal";
import { Container } from 'react-bootstrap';
import Constant from '../../networking/Constant'
import { FiDownload } from "react-icons/fi";
import {
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import {
  FacebookIcon,
  LinkedinIcon,
} from "react-share";
import Navigation from '../navBar/NavDark'
import Sponsor from '../sponsor/Sponsor'
import Footer from '../footer/Footer'
import Cookies from 'universal-cookie'
import { useLoading, Rings } from '@agney/react-loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import HelmetMetaData from "../HelmetMetaData/HelmetMetaData";
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom'

const cookies = new Cookies();

function MyVerticallyCenteredModalBox(props) {

    const encodeUrl = (imageURL) => {
      var encodeUrlComponent = encodeURIComponent
      var UrlB19 = "https://newb19.be/consultingAlbum/" + encodeUrlComponent(props.IdGallery) + '/' + encodeUrlComponent(imageURL)
      return (UrlB19)
    }

    const NextImage = () => {
      if (props.ImageModal.length === 0)
        return ;
      if (props.i === props.ImageModal.length - 1)
      {
        props.setImagesFind(0)
        return ;
      }
      props.setImagesFind(props.i + 1)
    }

    const PreviousImage = () => {
      if (props.i === 0)
      {
        if (props.ImageModal.length === 0)
          return ;
        props.setImagesFind(props.ImageModal.length - 1)
        return ;
      }
      props.setImagesFind(props.i - 1)
    }

    //href={item.UrlDownload}

  return (
    <>
    <Lightbox
      toolbarButtons={[
        <div style={{}}>

        <FacebookShareButton url={encodeUrl(props.ImageModal[props.i].KeyId)} style={{marginRight: 15}}>
        <FacebookIcon size={32} round/>
        </FacebookShareButton>
        <LinkedinShareButton url={props.ImageModal[props.i].KeyId} style={{marginRight: 15}}>
        <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <a href={props.ImageModal[props.i].UrlDownload} download rel="noopener noreferrer" target="_blank" style={{marginRight: 10}}>
          <FiDownload size={25} color={"white"}/>
        </a>
      </div>
      ]}
      mainSrc={props.ImageModal[props.i].Image}
      nextSrc={props.ImageModal[(props.i + 1 % props.ImageModal.length) % props.ImageModal.length] ? props.ImageModal[(props.i + 1 % props.ImageModal.length) % props.ImageModal.length].Image : null}
      prevSrc={props.ImageModal[(props.i + props.ImageModal.length - 1) % props.ImageModal.length] ? props.ImageModal[(props.i + props.ImageModal.length - 1) % props.ImageModal.length].Image : null }
      onCloseRequest={() => props.setModalShowBox(false)}
      onMovePrevRequest={() =>  PreviousImage()}
      onMoveNextRequest={() => NextImage()}
      imagePadding={100}
      reactModalStyle={{objectFit: "contain"}}
    />
  </>
  );
}

function ConsultingAlbum(props) {

  const intl = useIntl();
  
  const { language } = useParams(); 

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Rings width="100" color="#FC8016" />,
  });

  const [loading, setLoading] = useState(true)

  const [modalShowBox, setModalShowBox] = useState(false);
  const [images, setImages] = useState(null);
  const [imagesFind, setImagesFind] = useState(0);
  const [data, setData] = useState(null);

const getSpecificAlbum = () => {
  setLoading(true)
  fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/GetSpecificGallery',{
    method:'POST',
    headers:{
    'Content-Type': 'application/json',
    'Accept': 'application/json'},
    body:JSON.stringify({Jwt: cookies.get('access'), IdGallery: props.match.params.id, Language: intl.locale})
  }).then(response=>response.json()).then(res=>{
    if(res.error === false){
      setImages(res.data.Image)
      setData(res.data)
      setLoading(false)
    } else {
      setLoading(false)
      alert(intl.formatMessage({id: "ErrorHappened"}))
    }
  }).catch((e) => {
    setLoading(false)
    alert(intl.formatMessage({id: "ErrorNetwork"}))
  })
}

const { setLocale } = props;

useEffect(() => {

if(images === null)
  getSpecificAlbum()
if (language === "fr") {
  setLocale(language);
} else if (language === "nl") {
  setLocale(language);
}
}, [images, language, setLocale])

const FindImageIndex = (Url) => {
  try {
    var index = data.ImageModal.findIndex(res => res.Image === Url)
    if (index != -1){
      setImagesFind(index)
      setModalShowBox(true)
    }
    return ;
  } catch (e) {
    return ;
  }
}

  const imageHelmet = () => {
    if(props.match.params.idPhoto){
      if(data){
        var index = data.ImageModal.findIndex(res => res.KeyId === props.match.params.idPhoto)
        if(index !== -1)
          return(data.ImageModal[index].Image)
      }
    }else if(data){
      return(data.ImageModal[0].Image)
    }
    return(null)

  }


if (!images) {
  return <div className="height"> <section {...containerProps}> {indicatorEl} </section></div>
}

  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
      {
        modalShowBox === true ?
        <>
        </>
      :
      <Navigation/>
    }
      <div style={{backgroundColor: "#F6F7FB", paddingTop: "100px"}}>
        {
          loading === true ?
          <div className="height"> <section {...containerProps}> {indicatorEl} </section></div>
          :
        <>
          <Container>
            <div class="photo-gallery">
            <div class="container">
                <div class="intro">
                    <h2 class="text-center" style={{paddingBottom: 20}}>{data ? data.Name : ""}</h2>
                </div>
            </div>
            </div>
            {
              data ?
              <div style={{textAlign: 'justify', marginTop: 10}} dangerouslySetInnerHTML={{__html: data.DescriptionHTML ? data.DescriptionHTML : " "}} />
              :
              <>
              </>
            }
            </Container>

            <HelmetMetaData
            title={data ? data.Name : ""}
            image={imageHelmet()}
            ></HelmetMetaData>

            <Container>
            <div class="row">
              <div class="col-lg-4 col-md-12 mb-4 mb-lg-0" id='0'>
                <Fade>
              {images && images['0'].map((item, index) => {
                return(
                  <div key={index}>
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => FindImageIndex(item.Url)}
                      src={item.Url}
                      class="w-100 shadow-1-strong rounded mb-4 img"
                      alt="">
                    </LazyLoadImage>
                </div>
                )
              })}
                  </Fade>
            </div>

            <div class="col-lg-4 mb-4 mb-lg-0" id='1'>
              <Fade>
                {images && images["1"].map((item, index) => {
                  return(
                    <div key={index}>
                      <LazyLoadImage
                        effect="blur"
                        onClick={() => FindImageIndex(item.Url)}
                        src={item.Url}
                        class="w-100 shadow-1-strong rounded mb-4 img"
                        alt=""
                      >
                    </LazyLoadImage>
                  </div>
                  )
                })}
              </Fade>
              </div>

              <div class="col-lg-4 mb-4 mb-lg-0" id='2'>
                <Fade>
                {images && images["2"].map((item, index) => {
                  return(
                    <div key={index}>
                      <LazyLoadImage
                        effect="blur"
                        onClick={() => FindImageIndex(item.Url)}
                        src={item.Url}
                        class="w-100 shadow-1-strong rounded mb-4 img"
                        alt=""
                      >
                    </LazyLoadImage>
                  </div>
                  )
                })}
                  </Fade>
              </div>
            </div>

            </Container>

          </>
        }
      </div>
      {
        modalShowBox === true ?

        <MyVerticallyCenteredModalBox
            show={modalShowBox}
            IdGallery={props.match.params.id}
            i={imagesFind}
            setImagesFind={setImagesFind}
            ImageModal={data ? data.ImageModal : []}
            setModalShowBox = {setModalShowBox}
            onHide={() => setModalShowBox(false)}
          />

        :
        <>
        </>
      }
      <div style={{backgroundColor: '#FFFFFF'}}>
        <Sponsor/>
      </div>
      <Footer/>
    </div>
  );
}

export default ConsultingAlbum;

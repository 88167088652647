import React, { useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { Container } from 'react-bootstrap';
import Constant from '../../networking/Constant'

import Navigation from '../navBar/NavDark'
import Sponsor from '../sponsor/Sponsor'
import Footer from '../footer/Footer'
import { useIntl } from 'react-intl';


function Failed(props) {
  const intl = useIntl();

  useEffect(() => {
    fetch(Constant.IP_BACKEND_DNS + '/updateRegisterStatus',{
      method:'POST',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
      body:JSON.stringify({paymentToken: props.match.params.token, registrationType: props.match.params.registrationType , status: 'false', method: "Stripe"})
    }).then(response=>response.json()).then(res=>{
     // console.log("res", res)
      if(res.error === false){
        return ;
      } else {
        alert(intl.formatMessage({id: "ErrorHappened"}))
      }
    }).catch((e) => {
      alert(intl.formatMessage({id: "ErrorNetwork"}))
    })
  })


  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
    <Navigation/>
      <Fade>
      <div style={{backgroundColor: "#F6F7FB", paddingTop: 180, paddingBottom: 180}}>
        <Container className="shadow" style={{backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
        <h1>{intl.formatMessage({id: 'CancelPayment'})}</h1>
        </Container>
      </div>
      <div style={{backgroundColor: '#FFFFFF'}}>
        <Sponsor/>
        </div>
      <Footer/>
      </Fade>
    </div>
  );
}

export default Failed;

import React, { Component } from 'react'
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";

import './Map.css'

import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux';

const geoMap = require('./belgiumMap.json');
const geoMapLux = require('./luxMap.json');



const markers = [
  {name: "GENT", latitude: 51.07886, longitude: 3.73050, region: "fl"},
  {name: "KNOCKE-HEIST", latitude: 51.346381962120056, longitude: 3.2858518380055393, region: "fl"},
  {name: "BRUGGE", latitude: 51.21019623222078, longitude: 3.2267755774964395, region: "fl"},
  {name: "KORTRIJK", latitude: 50.81950046149406, longitude: 3.258503373586166, region: "fl"},
  {name: "AALST", latitude: 51.00005, longitude: 4.02063, region: "fl"},
  {name: "WAREGEM", latitude: 50.99948613225443, longitude: 3.4149136531338495, region: "fl"},
  {name: "ANTWERPEN", latitude: 51.21904, longitude: 4.41419, region: "anv"},
  {name: "MECHELEN", latitude: 51.104186322540095, longitude: 4.450822096833728, region: "anv"},
  {name: "HASSELT", latitude: 51.0111139723613, longitude: 5.33547521575266, region: "anv"},
  {name: "BRUXELLES", latitude: 50.84690558511239, longitude: 4.353187521170914, region: "bxl"},
  {name: "NAMUR", latitude: 50.56684812910819, longitude: 4.5531602149630235, region: "bxl"},
  {name: "LIÈGE", latitude: 50.63603479087612, longitude: 5.589484966364612, region: "luik"},
  {name: "SPA", latitude: 50.55265393877854, longitude: 5.852557509081251, region: "luik"},
  {name: "WAVRE", latitude: 50.77852491954137, longitude: 4.60562854907476, region: "bxl"},
  {name: "OTTIGNIES-LLN", latitude: 50.687080579495365, longitude: 4.281895891789816, region: "bxl"},
  {name: "LUXEMBOURG", latitude: 49.787397616962544, longitude:5.920253464450386, region: "lux"},
  {name: "ROESELARE", latitude: 50.94520190777757, longitude: 2.9284407627116066, region: "fl"},
]


class MapChart extends Component {
  state = {
    select : false
  }


  test = (region) => {
    this.props.startFilterProps(region)
    this.props.checkRegion()
  }

  _setRegionRedux = (region) => {
    var scrollagenda = localStorage.getItem('scrollagenda')
    if (scrollagenda === undefined)
      scrollagenda = "false"
    else if (scrollagenda === "false")
      scrollagenda = 'true'
    else
      scrollagenda = "false"
    localStorage.setItem('scrollagenda', scrollagenda)
    localStorage.setItem('region', region)
    var action = {type : "SET_REGION", state: {region : region, scrollagenda : scrollagenda}}
    this.props.dispatch(action)
  }

  _redirection = () => {
    this.setState({select: true})

  }

  render() {
    if (this.state.select === true){
    return <Redirect push to={`/ambassador/${"liege"}`}/>
  }
    return (
        <ComposableMap
        height={600}

          projection="geoMercator"
          projectionConfig={{
            rotate: [-4.5, -50.5, 0],
            scale: 13000,
          }}
        >
        <Geographies geography={geoMap}>
        {({ geographies }) =>
        geographies
        .filter(d => d.properties.NAME_0 === 'Belgium')
        .map(geo => (
          <Geography
          onClick={() => {

                   }}
          key={geo.rsmKey}
          geography={geo}
          strokeWidth={0.9}
          style={{
            default: {
              fill: "#F6F7FB",
              stroke: "#D6D6DA",
              outline: "none"
            },
            hover:{
              fill: "#F6F7FB",
                stroke: "#D6D6DA",
              outline: "none"
            },
            pressed:{
              fill: "#F6F7FB",
                stroke: "#D6D6DA",
              outline: "none"
            }
          }}
          />
        ))
      }
      </Geographies>
      <Geographies geography={geoMapLux}>
      {({ geographies }) =>
      geographies
      .filter(d => d.properties.NAME_0 === 'Luxembourg')
      .map(geo => (
        <Geography
        component={Link}
        to={`/login`}
        onClick={() => {
                   this._redirection()
                 }}
        key={geo.rsmKey}
        geography={geo}
        strokeWidth={0.9}
        style={{
          default: {
            fill: "#F6F7FB",
            stroke: "#ED7136",
            strokeWidth: 4,
            outline: "none",
          },
          hover:{
            fill: "#F6F7FB",
            stroke: "#ED7136",
            strokeWidth: 4,
            outline: "none",
          },
          pressed:{
            fill: "#F6F7FB",
            stroke: "#ED7136",
            strokeWidth: 4,
            outline: "none"
          }
        }}
        />
      ))
    }
    </Geographies>
       {markers.map(({name, latitude, longitude, region}) => (

         <Marker
          class="circle"
         onClick={() => {
                    this._setRegionRedux(region)

                  }}
         key={name}
         coordinates={[longitude, latitude]}>
         <g
           fill="transparent"
           stroke="#61606d"
           strokeWidth="2"
           strokeLinecap="round"
           strokeLinejoin="round"
           transform="translate(-12, -24)">
             {
               name === "MONS" || name === "BRABANT WALLON"?
               <>
               </>
             :
             <>
             {
               name === "BRUXELLES" ?
               <a style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>

                <circle cx="40" cy="95" r="90">
                </circle>

              </a>
              :
              <>
              {
                name === "GENT" ?
              <a  style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>
                <ellipse className="test" cx="75" cy="10" rx="240" ry="95"/>
                </a>
              :
              <>
              {
                name === "ANTWERPEN" ?
                <a style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>
              {/*<circle className="test" cx="95" cy="30" r="110"/>*/}
                </a>
              :
              <>
              {
                name === "LIÈGE" ?
                <a style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>
                <circle className="test" cx="15" cy="60" r="107"/>
                </a>
              :
              <>
              {
                name === "LUXEMBOURG" ?
                <a  style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>
                <circle className="test" cx="35" cy="20" r="95"/>
                </a>
              :
              <>
              </>
              }
              </>
              }
              </>
              }
              </>
              }
              </>
             }
             </>
             }
             </g>

          <a className="border" style={{color: 'black', fontWeight: 'bold'}}>
            <text y={4} style={{ fontSize: 9, fontWeight: 'bold', letterSpacing: 1.5, }}>
              {name}
            </text>
        </a>

         </Marker>


       ))}
      </ComposableMap>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    region : state._state_reducer_home_page.region
  }
}

export default connect(mapStateToProps)(MapChart)

/*
<a href={`/ambassador/${name}`} style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>
*/

import React, { useState, useEffect, useRef } from 'react';
import './Member.css'
import { Fade } from "react-awesome-reveal";
import { Button, Row, Container, Col, Image, Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { Redirect, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Constant from '../../networking/Constant'
import Navigation from '../navBar/NavDark'
import Sponsor from '../sponsor/Sponsor'
import Footer from '../footer/Footer'
import EmptyProfil from '../../image/placeholder_man.png'
import { CgProfile } from "react-icons/cg";
import { FiMail } from "react-icons/fi";
import { useIntl } from 'react-intl';
import { useLoading, Rings } from '@agney/react-loading';
import { BiSearch } from "react-icons/bi";

const cookies = new Cookies();

function MyVerticallyCenteredModal(props) {

  const search = () => {
    props.setSearch(true)
    props.onHide()
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.intl.formatMessage({id: 'FiltreMin'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({id: 'SearchSector'})}</h4>
        <Form>
          <fieldset>
           <Form.Group as={Row} style={{marginTop: 25}}>
             <Col sm={10}>
               <Form.Check
                 value="1"
                 type="radio"
                 label={props.intl.formatMessage({id: 'Immobilier'})}
                 name="formHorizontalRadios"
                 onChange={(event) => props.setSector(event.target.value)}
               />
               <Form.Check
                 value="2"
                 type="radio"
                 label={props.intl.formatMessage({id: 'Banques'})}
                 name="formHorizontalRadios"
                 onChange={(event) => props.setSector(event.target.value)}
               />
               <Form.Check
                 value="3"
                 type="radio"
                 label={props.intl.formatMessage({id: 'Industriel'})}
                 name="formHorizontalRadios"
                 onChange={(event) => props.setSector(event.target.value)}
               />
               <Form.Check
                 value="4"
                 type="radio"
                 label={props.intl.formatMessage({id: 'It'})}
                 name="formHorizontalRadios"
                 onChange={(event) => props.setSector(event.target.value)}
               />
               <Form.Check
                 value="5"
                 type="radio"
                 label={props.intl.formatMessage({id: 'Biens'})}
                 name="formHorizontalRadios"
                 onChange={(event) => props.setSector(event.target.value)}
               />
               <Form.Check
                 value="6"
                 type="radio"
                 label={props.intl.formatMessage({id: 'Commerces'})}
                 name="formHorizontalRadios"
                 onChange={(event) => props.setSector(event.target.value)}
               />
               <Form.Check
                 value="7"
                 type="radio"
                 label={props.intl.formatMessage({id: 'Avocat'})}
                 name="formHorizontalRadios"
                 onChange={(event) => props.setSector(event.target.value)}
               />
               <Form.Check
                 value="8"
                 type="radio"
                 label={props.intl.formatMessage({id: 'Consultance'})}
                 name="formHorizontalRadios"
                 onChange={(event) => props.setSector(event.target.value)}
               />
               <Form.Check
                 value="9"
                 type="radio"
                 label={props.intl.formatMessage({id: 'Autres'})}
                 name="formHorizontalRadios"
                 onChange={(event) => props.setSector(event.target.value)}
               />
             </Col>
           </Form.Group>
         </fieldset>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={props.onHide}>{props.intl.formatMessage({id: 'Annuler'})}</Button>

        <Button style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={() => search()}>{props.intl.formatMessage({id: 'Rechercher'})}</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Member(props) {
  const intl = useIntl();

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Rings width="100" color="#FC8016" />,
  });

  const { language } = useParams(); 

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false)
  const [bgd, setBgd] = useState('36.5%')
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [data, setData] = useState([])
  const [search, setSearch] = useState(false)
  const reset = useRef(false)

  const createSector = (data) => {
    if(data === '1')
      return(intl.formatMessage({id: 'Immobilier'}))
    if(data === '2')
      return(intl.formatMessage({id: 'Banques'}))
    if(data === '3')
      return(intl.formatMessage({id: 'Industriel'}))
    if(data === '4')
      return(intl.formatMessage({id: 'It'}))
    if(data === '5')
      return(intl.formatMessage({id: 'Biens'}))
    if(data === '6')
      return(intl.formatMessage({id: 'Commerces'}))
    if(data === '7')
      return(intl.formatMessage({id: 'Avocat'}))
    if(data === '8')
      return(intl.formatMessage({id: 'Consultance'}))
    if(data === '9')
      return(intl.formatMessage({id: 'Autres'}))
  }

  const { setLocale } = props;


  useEffect(() => {
    if (data.length === 0 && name.length >= 1)
    {
      getMember()
    }
    if (search === true){
      setData([])
      setSearch(false)
    }
    if (window.screen.width >= 1250)
      setBgd('50%')
    else
      setBgd('100%')

    if (language === "fr") {
      setLocale(language);
    } else if (language === "nl") {
      setLocale(language);
    }

      // action on update of movies
  }, [search, language, setLocale]);


  const setListMember = (info) => {
    var copy = [...data]
    var test = copy.concat(info)
    setData(test)
  }

  const setMaj = (string) => {
    try {
      if (string !== null) {
        // Divise la chaîne par les tirets et les espaces
        const newString = string.split(/[-\s]/)
        for (var i = 0; i < newString.length; i++) {
          newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1).toLowerCase();
        }
        
        // Rejoignez les morceaux avec les tirets et les espaces appropriés
        const newStringJoin = newString.join(" ").replace(/ - /g, "-");
        return newStringJoin;
      }
    } catch (e) {
      return " ";
    }
  };

  const verifyName = (string) => {
  var string = string.toLowerCase()
  setName(string)
  }

  const getMember = () => {
    setLoading(true)
      fetch(Constant.IP_BACKEND_DNS + '/searchMember',{
        method:'POST',
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json'},
        body:JSON.stringify({accesToken: cookies.get('access'), name: name, sizeImage: 'large'})
      }).then(response=>response.json()).then(res=>{
        if(res.error === false){
          setListMember(res.data.result)
          setLoading(false)
        }
        if(res.error === true){
          setLoading(false)
          setError({network: "ErrorNetwork"})
        }
      }).catch((e) => {
        setLoading(false)
        setError({network: "ErrorNetwork"})
      })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

const pressEnter = (event) => {
  if(event.key === "Enter")
      setSearch(true)
}

const showMore = () => {
  getMember()
}

const sendMail = (email) => {
  if(email === null){
    alert(intl.formatMessage({id: 'EmailNotSave'}))
    return ;
  }
  var e = email
  window.location.href = "https://mail.google.com/mail/?view=cm&fs=1&to=" + e
}

const formatName = (lastName) => {
  if (typeof lastName !== 'string' || !lastName.trim()) return "";
  const parts = lastName.trim().split(" ");
  if (parts.length === 1) {
    return lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
  }
  return parts.map((part, index) => {
    if (index === 0 && part.toLowerCase() === "de") {
      return part.toLowerCase();
    } else {
      return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
    }
  }).join(" ");
};

const setMajCompanyName = (str) => {
  try{
    const wordsToCapitalize = ["srl", "sa", "sprl", "sc", "asbl"]; // Ajoutez les mots que vous souhaitez transformer en majuscules
    return str.split(' ').map(word => {
      // Met en majuscule la première lettre du mot
      let capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
      // Vérifie si le mot doit être entièrement en majuscules
      return wordsToCapitalize.includes(word.toLowerCase()) ? word.toUpperCase() : capitalizedWord;
    }).join(' ');
  }catch(e){
    return(" ")
  }
};


  if(cookies.get('access') === undefined)
    return <Redirect push to={`/login/${language}`}/>

  return (
    <div className="App" style={{backgroundColor: "#F6F7FB", fontFamily: 'Typold Regular'}}>
      <Navigation/>
      <Fade>
        <div >
        <Container  style={{justifyContent:'center', marginTop: 20, paddingTop: "100px", paddingBottom: '20px'}}>
        <Row>
          <Col>
            <h3 style={{textAlign: 'left'}}>{intl.formatMessage({id: 'SearchMember'})}</h3>
          </Col>
          <Col>
            <div style={{textAlign: 'right'}}>
              <Button onClick={() => setModalShow(true)} style={{fontWeight: 'bold', backgroundColor: '#FC8016', borderWidth: 0}}>
                {intl.formatMessage({id: 'Filtre'})}
              </Button>
            </div>
          </Col>
        </Row>

        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Col xl={10} lg={10} md={12} sm ={12} >
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Col} controlId="formGridName" style={{paddingTop: 18}}>
                <Form.Control
                type="text"
                placeholder={intl.formatMessage({id: 'PlaceHolderSearchMember'})}
                onKeyPress={(event) => pressEnter(event)}
                onChange={(event) => verifyName(event.target.value)}/>

              </Form.Group>
              <MyVerticallyCenteredModal
                show={modalShow}
                intl={intl}
                setSector={setName}
                getMember={getMember}
                setSearch={setSearch}
                reset={reset}
                onHide={() => setModalShow(false)}
              />
            </Form>
          </Col>
          <Col>
          <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'center'}}>
          <Button onClick={() => setSearch(true)} style={{display: 'flex', fontWeight: 'bold',  backgroundColor: '#082331', borderWidth: 0, marginLeft: 5}}>
                 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                   <BiSearch size={26} color={'white'}/>
                   <p style={{color: 'white', padding: 0, margin: 0, marginLeft: 5, fontSize: "0.90rem"}}>{intl.formatMessage({id: 'Rechercher'})}</p>
               </div>
             </Button>
          </div>
          </Col>
        </Row>
        </Container>


        {
          loading === true ?
          <div className="height"> <section {...containerProps}> {indicatorEl} </section></div>
          :
          <Container style={{width:bgd}}>
            {data && data.map((item, index) =>(
              <Row key={index} className="shadow" style={{backgroundColor: '#FFFFFF',  borderRadius: 15, marginBottom: 20}}>
                <Col sm={1} lg={3} xl={3} style={{textAlign: 'left', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: 0}}>
                    <a style={{color: 'black', textAlign: 'center', textDecoration: 'none', width: '100%'}}>
                        <Image style={{objectFit: item.image ? "cover" : "contain", borderTopLeftRadius: 15, borderBottomLeftRadius: 15, width: '100%', height: '144px'}} src={item.image ? item.image : EmptyProfil} />
                  </a>
                </Col>
                <Col style={{textAlign : 'left', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                  <p style={{fontWeight: 'bold'}}> {setMaj(item.UserInformation.Name.First)} {formatName(item.UserInformation.Name.Last)} </p>
                  <p style={{color:'#FC8016'}}>  {setMajCompanyName(item.UserInformation.Society.Name)} {item.UserInformation.Society.Status && item.UserInformation.Society.Status.trim() !== '' ? ` - ${setMaj(item.UserInformation.Society.Status)}` : ''} </p>
                  <p>{createSector(item.UserInformation.Society.Sector)}</p>
                </Col>
                <Col style={{justifyContent: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center',}}>
                  <Button onClick={() => props.history.push({pathname: `/memberProfil/${language}`, state: [{data: {item} }] }) } variant="outline-dark" style={{borderRadius: 10, backgroundColor: '#082331', color: "white", fontWeight: 'bold', marginRight: 10}}>
                    <CgProfile size={40}/>
                  </Button>

                  <Button onClick={() => sendMail(item.UserInformation.Email.Pro)} variant="outline-dark" style={{borderRadius: 10, backgroundColor: '#082331', color: "white", fontWeight: 'bold', marginLeft: 10 }}>
                    <FiMail size={40}/>
                  </Button>
                </Col>
              </Row>
            ))}
          </Container>
        }

      </div>
        <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%'}}/>
        <div style={{backgroundColor: '#FFFFFF'}}>
        <Sponsor/>
        </div>
      <Footer/>
      </Fade>
    </div>
  );
}

export default Member;

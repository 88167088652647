import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import Constant from '../../networking/Constant'
import Navigation from '../navBar/NavDark'
import Footer from '../footer/Footer'
import { CircularProgress } from '@material-ui/core';
import { useIntl } from 'react-intl';
import './DeleteAccount.css'
import { useParams } from 'react-router-dom'

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.intl.formatMessage({id: 'ModalSuccesDeleteSubTitle'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({id: 'Succes'})}</h4>
        <p style={{marginTop: 25}}>
          {props.intl.formatMessage({id: 'ModalSuccesDeleteSubBody'})}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button href="/" style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={props.onHide}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

function FormDeleteSub(props) {
  const intl = useIntl();

  const { language } = useParams();

  const [bgd, setBgd] = useState('35%')
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState(null)

  const { setLocale } = props;

  useEffect(() => {
    if (window.screen.width >= 1250){
      setBgd('35%')
    } else {
      setBgd('100%')
    }
    if (language === "fr") {
      setLocale(language);
    } else if (language === "nl") {
      setLocale(language);
    }
}, [[language, setLocale]]);

const deleteSub = () => {
    setLoading(true)
    fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/DeleteAccount',{
      method:'POST',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
      body:JSON.stringify({
        Email: email,
        Password : password,
        })
    }).then(response=>response.json()).then(res=>{
      if(res.error === false){
        setLoading(false)
        setModalShow(true)
      }
      else if(res.error === true && res.message === "Argument required"){
        setLoading(false)
        setError("MissignArgDeleteSub")
      }
      else if(res.error === true && res.message === "Email/Password arguments trouble"){
        setLoading(false)
        setError("MissignArgDeleteSub1")
      }
      else if(res.error === true && res.message === "Account is validate, please login"){
        setLoading(false)
        setError("MissignArgDeleteSub2")
      }
      else if(res.error === true && res.message === "No Id found into data base"){
        setLoading(false)
        setError("EmailNotExist")
      }
      else if(res.error === true && res.message === "Password not match"){
        setLoading(false)
        setError("MissignArgDeleteSub1")
      }
      else {
        setLoading(false)
        setError("ErrorTryLater")
      }
    }).catch((e) => {
      setLoading(false)
      setError({network: "ErrorNetwork"})
    })
}

  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
      <Navigation/>
      <Fade>
      <div style={{backgroundColor: "#F6F7FB", paddingTop: "100px"}}>
        <Container className="shadow" style={{width: bgd, backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
        <h2 style={{textAlign: 'left', marginLeft: 15}}>{intl.formatMessage({id: 'DeleteSubTitle'})}</h2>
        <p style={{color:'red', fontWeight:'bold', marginTop: 25}}>{intl.formatMessage({id: error ? error : "Space" })}</p>
        <Form>
          <Row>
            <Form.Group as={Col} controlId="formGridEmail" style={{padding: 25}}>
              <Form.Control
              type="email"
              placeholder={intl.formatMessage({id: 'PlaceHolderLogin'})}
              value={email}
              onChange={(event) => setEmail(event.target.value)}/>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formGridPassword" style={{padding: 25}}>
              <Form.Control
              type="password"
              placeholder={intl.formatMessage({id: 'PlaceHolderPassword'})}
              value={password}
              onChange={(event) => setPassword(event.target.value)}/>
            </Form.Group>
          </Row>

          <Button
          style={{margin: 20, marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}}
          onClick={() => deleteSub()}>
          {
            loading === true ?
            <div style={{position: 'relative'}}>
              <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                <CircularProgress color="white" size={20}/>
              </div>
              <div>
                <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: 'DeleteSubButton'})}</p>
              </div>
            </div>
            :
            <p style={{margin: 0}}>{intl.formatMessage({id: 'DeleteSubButton'})}</p>
          }
          </Button>
        </Form>

        <Col style={{marginTop: 25, padding: 5}}>
        <Button href="/forgetPassword" variant="link" style={{color:'black'}}>{intl.formatMessage({id: 'ForgetPassword'})}</Button>
        </Col>

        <MyVerticallyCenteredModal
          show={modalShow}
          intl={intl}
          onHide={() => setModalShow(false)}
        />

        </Container>
        <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%', marginTop: 80}}/>
      </div>

        <div style={{backgroundColor: '#FFFFF'}}>

        </div>

      <Footer/>

      </Fade>
    </div>
  );
}

export default FormDeleteSub;

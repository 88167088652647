import React, { useState, useEffect } from 'react';
import './MemberProfil.css'
import { Fade } from "react-awesome-reveal";
import { Row, Container, Col, Image, } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Navigation from '../navBar/NavDark'
import Sponsor from '../sponsor/Sponsor'
import Footer from '../footer/Footer'
import EmptyProfil from '../../image/placeholder_man.png'
import { RiMapPin2Fill } from "react-icons/ri";
import { BsFillBriefcaseFill } from "react-icons/bs";
import { ImPhone } from "react-icons/im";
import { IoMail, IoCalendar } from "react-icons/io5";
import { BiCommentDetail } from "react-icons/bi";
import { useIntl } from 'react-intl';
import { CgAttachment } from 'react-icons/cg';

const cookies = new Cookies();

function MemberProfil(props) {
  const intl = useIntl();

  const { language } = useParams(); 

  const [bgd, setBgd] = useState('36.5%')

  const createSector = (data) => {
    if(data === '1')
      return(intl.formatMessage({id: 'Immobilier'}))
    if(data === '2')
      return(intl.formatMessage({id: 'Banques'}))
    if(data === '3')
      return(intl.formatMessage({id: 'Industriel'}))
    if(data === '4')
      return(intl.formatMessage({id: 'It'}))
    if(data === '5')
      return(intl.formatMessage({id: 'Biens'}))
    if(data === '6')
      return(intl.formatMessage({id: 'Commerces'}))
    if(data === '7')
      return(intl.formatMessage({id: 'Avocat'}))
    if(data === '8')
      return(intl.formatMessage({id: 'Consultance'}))
    if(data === '9')
      return(intl.formatMessage({id: 'Autres'}))
  }


  const setMaj = (string) => {
    try {
      if (string !== null) {
        // Divise la chaîne par les tirets et les espaces
        const newString = string.split(/[-\s]/)
        for (var i = 0; i < newString.length; i++) {
          newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1).toLowerCase();
        }
        
        // Rejoignez les morceaux avec les tirets et les espaces appropriés
        const newStringJoin = newString.join(" ").replace(/ - /g, "-");
        return newStringJoin;
      }
    } catch (e) {
      return " ";
    }
  };

  const setMajCompanyName = (str) => {
    try{
      const wordsToCapitalize = ["srl", "sa", "sprl", "sc", "asbl"]; // Ajoutez les mots que vous souhaitez transformer en majuscules
      return str.split(' ').map(word => {
        // Met en majuscule la première lettre du mot
        let capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        // Vérifie si le mot doit être entièrement en majuscules
        return wordsToCapitalize.includes(word.toLowerCase()) ? word.toUpperCase() : capitalizedWord;
      }).join(' ');
    }catch(e){
      return(" ")
    }
  };

  const { setLocale } = props;

  useEffect(() => {
    if (window.screen.width >= 1250)
      setBgd('36.5%')
    else
      setBgd('100%')
    if (language === "fr") {
      setLocale(language);
    } else if (language === "nl") {
      setLocale(language);
    }
},[language, setLocale]);

const formatName = (lastName) => {
  if (typeof lastName !== 'string' || !lastName.trim()) return "";
  const parts = lastName.trim().split(" ");
  if (parts.length === 1) {
    return lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
  }
  return parts.map((part, index) => {
    if (index === 0 && part.toLowerCase() === "de") {
      return part.toLowerCase();
    } else {
      return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
    }
  }).join(" ");
};

  const joinWithComma = (...args) => {
    return args.filter(arg => arg && arg.trim() !== '').join(', ');
  }

  const { Street, ZipCode, City } = props.location.state[0].data.item.UserInformation.Adress.Pro;


  if(cookies.get('access') === undefined)
    return <Redirect push to={`/login/${language}`}/>

  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
      <Navigation/>
      <Fade>
        <div style={{backgroundColor: "#F6F7FB"}}>
        <Container  style={{justifyContent:'center', width:bgd, paddingTop: "100px", paddingBottom: 80}}>
          <Row>

          <div style={{}}>
            <h3 style={{textAlign: 'left'}}>{intl.formatMessage({id: 'ProfilMin'})}</h3>
          </div>

          <div className="shadow" style={{backgroundColor: '#FFFFFF', padding: 15, borderRadius: 5, marginTop: 30}}>

          <Row >

          {/*Personnal information Section*/}

          <Col style={{textAlign:'left', marginTop: 20}}>

          <div style={{textAlign: 'center'}}>
          <a href={props.location.state[0].data.item.image ? props.location.state[0].data.item.image : EmptyProfil} data-lightbox="photos">
          <Image style={{objectFit: "contain", borderRadius: 15, marginTop: 10, height: '200px'}} fluid src={props.location.state[0].data.item.image ? props.location.state[0].data.item.image : EmptyProfil} />
          </a>
        </div>

          <div style={{textAlign: 'left', marginTop: 25}}>

          <h5>{setMaj(props.location.state[0].data.item.UserInformation.Name.First)} {formatName(props.location.state[0].data.item.UserInformation.Name.Last)}</h5>

          </div>


          <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'flex-start', marginTop: 20}}>
          <div style={{backgroundColor: '#ff5f00', height: 2, width: '20%'}}/>
          </div>

          <p style={{fontWeight: 'bold', color:'#FC8016', marginTop: 20}}>{intl.formatMessage({id: 'PersonalInfo'})}</p>

          <div style={{marginTop: 25}}>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <RiMapPin2Fill size={25}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{}}>{props.location.state[0].data.item.UserInformation.Adress.Private.Street}, {props.location.state[0].data.item.UserInformation.Adress.Private.ZipCode}, {props.location.state[0].data.item.UserInformation.Adress.Private.City}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <ImPhone size={25}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{}}>{props.location.state[0].data.item.UserInformation.Tel.Private ? props.location.state[0].data.item.UserInformation.Tel.Private : " "}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <IoMail size={25}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{}}>{props.location.state[0].data.item.UserInformation.Email.Privates}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <BiCommentDetail size={25}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{}}>{props.location.state[0].data.item.UserInformation.Commentary}</p>
            </div>
          </div>

          </div>
          </Col>

          <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'flex-start', marginTop: 20}}>
          <div style={{backgroundColor: '#ff5f00', height: 2, width: '20%'}}/>
          </div>

          {/*Profesionnal information Section*/}

          <Col style={{textAlign:'left', marginTop: 20}}>
          <p style={{fontWeight: 'bold', color:'#FC8016'}}>{intl.formatMessage({id: 'ProInfo'})}</p>

          <div style={{marginTop: 25}}>
          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <BsFillBriefcaseFill size={25}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{}}>
              {setMajCompanyName(props.location.state[0].data.item.UserInformation.Society.Name)} 
              {props.location.state[0].data.item.UserInformation.Society.Status && props.location.state[0].data.item.UserInformation.Society.Status.trim() !== '' 
                ? ` - ${setMaj(props.location.state[0].data.item.UserInformation.Society.Status)}` 
                : ''}
            </p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <BsFillBriefcaseFill size={25}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{}}>{createSector(props.location.state[0].data.item.UserInformation.Society.Sector)}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <RiMapPin2Fill size={25}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{}}>{joinWithComma(Street, ZipCode, City)}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <ImPhone size={25}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{}}>{props.location.state[0].data.item.UserInformation.Tel.Pro}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <IoMail size={25}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{}}>{props.location.state[0].data.item.UserInformation.Email.Pro}</p>
            </div>
          </div>

          </div>

          <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'flex-start', marginTop: 20}}>
          <div style={{backgroundColor: '#ff5f00', height: 2, width: '20%'}}/>
          </div>

          <p style={{fontWeight: 'bold', color:'#FC8016', marginTop: 20}}>{intl.formatMessage({id: 'ComplInfo'})}</p>

          <div style={{marginTop: 25}}>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
            <p style={{fontWeight: 'bold'}}>{intl.formatMessage({id: 'DateSociety'})}</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{}}>{props.location.state[0].data.item.UserInformation.Society.Date === 'null/null/null' ? "" : props.location.state[0].data.item.UserInformation.Society.Date}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
            <p style={{fontWeight: 'bold'}}>{intl.formatMessage({id: 'Localisation'})}</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{}}>{props.location.state[0].data.item.UserInformation.Society.Localisation}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
            <p style={{fontWeight: 'bold'}}>{intl.formatMessage({id: 'SizeSociety'})}</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{}}>{props.location.state[0].data.item.UserInformation.Society.SizeOfSociety}</p>
            </div>
          </div>

          </div>
          </Col>

          </Row>

          </div>
          </Row>
        </Container>
        </div>
        <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%'}}/>
        <div style={{backgroundColor: '#FFFFFF'}}>
        <Sponsor/>
        </div>
      <Footer/>
      </Fade>
    </div>
  );
}

export default MemberProfil;

import Cookies from 'universal-cookie'
import Request from './Config'

const cookies = new Cookies();

const createCookie = (name, value) => {
  cookies.set(name, value, {
    sameSite: 'strict',
    path: '/',
    //expires: new Date(new Date().getTime() + 60*60*24*7*1000)
  })
}

export const SetToken = (token) => {
  createCookie("RefreshToken", token.RefreshToken)
  createCookie("AccessToken", token.AccesToken)
  Request.defaults.headers['Authorization'] = 'Bearer ' + token.AccesToken
}

export const ClearToken = () => {
  cookies.remove("RefreshToken")
  cookies.remove("AccessToken")
  Request.defaults.headers['Authorization'] = null
}

export const GetToken = () => {
  var refresh_token = cookies.get("RefreshToken")
  var access_token = cookies.get("AccessToken")
  return ({refresh_token: refresh_token, access_token: access_token})
}


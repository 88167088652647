import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import Constant from '../../networking/Constant'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import Cookies from 'universal-cookie'
import { useLoading, Rings } from '@agney/react-loading';
import { loadStripe } from '@stripe/stripe-js';
import { useIntl } from 'react-intl';

const cookies = new Cookies();
const stripePromiseBxl = loadStripe('pk_live_51J4hxHL0SWLMklZ3E53r2s856WjJNqxvY0ElyxyQQm8c62OAXkBDqKc1AmuN9auWfldnDdV0Oe0uCjYqR03Ffo2100uIY1TDh3');
//const stripePromise = loadStripe('pk_test_51J4hxHL0SWLMklZ3MgLaZGEqlDloUu4AMByjzBiI9d4PtY8idpytAHddFYCbs40FmSyx99pMmDHT9c6xcSrv4Xic00kTljgnSm');
const stripePromiseLuik = loadStripe('pk_live_51JCOAJEbzcAf2J36UHWIEnEMW0euy8JWvuhjLUtvAfq3iP5K2UqsKuhS7FECNvCfEwS8IZhW8vCbPyCxkq0mWnUt00BjxzCUih');
const stripePromiseLux = loadStripe('pk_live_51JMVepEXYjxp2I0yYzxmdPpezg4Y7P1dpcOID8tNswUwzTvLNwpE9aTVJXtGiGLB1gw0PuIXLUifI7milrZ7nemh00qcKghPGM');

function MyVerticallyCenteredModal(props) {

  const disconnect = () => {
    cookies.remove('access', {path: '/'})
    props.onHide()
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.intl.formatMessage({id: 'Cotisation'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({id: 'CotisationMessage'})}</h4>
        <p style={{marginTop: 25}}>
          {props.intl.formatMessage({id: 'CotisationIndication'})}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button href="/login" style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={() => disconnect()}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

function ConsultingApp(props) {

  const intl = useIntl();

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Rings width="100" color="#FC8016" />,
  });

  const [loading, setLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false);


  const payment = async () => {
    let stripe = null
    const response = await fetch(Constant.IP_BACKEND_DNS + '/paymentEvent', { method: 'POST',
    headers:{
    'Content-Type': 'application/json',
    'Accept': 'application/json'},
    body:JSON.stringify({accesToken: props.match.params.accesToken, idEvent: props.match.params.idEvent, source: "App"})
   });
    const session = await response.json();
    if (session.error === true) {
      if(session.message === "User is blocked"){
        setModalShow(true)
        setLoading(false)
        return;}
      setLoading(false)
      alert(intl.formatMessage({id: 'ErrorHappened'}))
    }
    if(session.region === "bxl" || session.region === "anv" || session.region === "fl"){
      stripe = await stripePromiseBxl;}
    if (session.region === "luik"){
      stripe = await stripePromiseLuik;}
    if (session.region === "lux"){
      stripe = await stripePromiseLux;}
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      setLoading(false)
      alert(intl.formatMessage({id: 'ErrorHappened'}))
    }
  setLoading(false)
  return ;
  }



useEffect(() => {
    payment()
}, [])


  return (
    <div>
      <Fade>
      <div className="height"> <section {...containerProps}> {indicatorEl} </section></div>
      <MyVerticallyCenteredModal
        show={modalShow}
        intl={intl}
        onHide={() => setModalShow(false)}
      />
      </Fade>
  </div>
  );
}

export default ConsultingApp;

import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { Row, Container} from 'react-bootstrap';
import Navigation from '../navBar/NavDark'
import Footer from '../footer/Footer'
import arrow from '../../image/ic_arrow_down_white.png'
import AnimateHeight from 'react-animate-height';
import { useIntl } from 'react-intl';
import './DeleteAccount.css'
import { useParams } from 'react-router-dom'


function Faq(props) {
  const intl = useIntl();

  const { language } = useParams();

  const [height, setHeight] = useState(0)
  const [height1, setHeight1] = useState(0)
  const [height2, setHeight2] = useState(0)
  const [height3, setHeight3] = useState(0)
  const [height4, setHeight4] = useState(0)
  const [height5, setHeight5] = useState(0)

  const telNumber = {
    bxl: "+32 2 375 00 09",
    luik: "+32 497 246 913",
    lux: "+352 621 356 450",
    fl: "+32 2 375 00 09",
  }

  const open = () => {
    setHeight(height === 0 ? 'auto' : 0)
  }

  const open1 = () => {
    setHeight1(height1 === 0 ? 'auto' : 0)
  }

  const open2 = () => {
    setHeight2(height2 === 0 ? 'auto' : 0)
  }

  const open3 = () => {
    setHeight3(height3 === 0 ? 'auto' : 0)
  }

  const open4 = () => {
    setHeight4(height4 === 0 ? 'auto' : 0)
  }

  const open5 = () => {
    setHeight5(height5 === 0 ? 'auto' : 0)
  }

  const { setLocale } = props;

  useEffect(() => {
    if (language === "fr") {
      setLocale(language);
    } else if (language === "nl") {
      setLocale(language);
    }
}, [language, setLocale]);


  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
    <Navigation/>
      <Fade>
      <div style={{backgroundColor: "#F6F7FB", paddingTop: "100px"}}>
        <Container style={{backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
        <corps>
        <h2 id="ValidationWaitingSection">{intl.formatMessage({id: "ValidationWaitting"})}</h2>
        <p>{intl.formatMessage({id: "ValidationWaittingExpl"})}</p>
        <Row>
        <div className="shadow filtre" style={{width: "100%",  border: 0, marginTop: 15, borderRadius: 5, margin: '2px'}}>
          <button className="filtre" onClick={() => open3()} style={{width: "100%",  border: 0,  borderRadius: 5}}>
            <div  style={{display: "flex", flexDirection: 'row', alignItems: 'center', marginLeft: 5}}>
            <p  style={{ textAlign: 'left', padding: 10, margin: 0, color: "white", fontWeight: "bold"}}>{intl.formatMessage({id: "WhyAccountWaitTitle"})}</p>
            <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 5}}>
              {
                height3 === 0 ?
                <img  src={arrow} style={{width:"20px"}}/>
                :
                <img class="rotate90" src={arrow} style={{width:"20px"}}/>
              }
            </div>
            </div>
          </button>
          <AnimateHeight
            id="example3"
            duration={500}
            height = {height3}>
            <p style={{color: "white"}}>
            {intl.formatMessage({id: "WhyAccountWaitExpl"})}<br/><br/>
            {intl.formatMessage({id: "WhyAccountWaitExpl1"})}<br/>
            {intl.formatMessage({id: "WhyAccountWaitExpl2"})}<br/>
            {intl.formatMessage({id: "WhyAccountWaitExpl3"})}<br/>
            </p>
          </AnimateHeight>
        </div>
        </Row>

    <h2 style={{marginTop: "4rem"}}>{intl.formatMessage({id: "CancelSubTitle"})}</h2>
    <p>{intl.formatMessage({id: "CancelSubExpl"})}</p>

    <Row>
    <div   className="shadow filtre" style={{width: "100%",  border: 0, marginTop: 15, borderRadius: 5, margin: '2px'}}>
      <button className="filtre" onClick={() => open4()} style={{width: "100%",  border: 0,  borderRadius: 5}}>
        <div  style={{display: "flex", flexDirection: 'row', alignItems: 'center', marginLeft: 5}}>
        <p  style={{ textAlign: 'left', padding: 10, margin: 0, color: "white", fontWeight: 'bold'}}>{intl.formatMessage({id: "TimeToDeleteSub"})}</p>
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 5}}>
          {
            height4 === 0 ?
            <img  src={arrow} style={{width:"20px"}}/>
            :
            <img class="rotate90" src={arrow} style={{width:"20px"}}/>
          }
        </div>
        </div>
      </button>
      <AnimateHeight
        id="example4"
        duration={500}
        height = {height4}>
        <p style={{color: "white"}}>{intl.formatMessage({id: "DeleteDirectly"})}</p>
      </AnimateHeight>
    </div>
    </Row>
    <Row>
    <div className="shadow filtre" style={{width: "100%",  border: 0, marginTop: 15, borderRadius: 5, margin: '2px'}}>
      <button className="filtre" onClick={() => open5()} style={{width: "100%",  border: 0,  borderRadius: 5}}>
        <div  style={{display: "flex", flexDirection: 'row', alignItems: 'center', marginLeft: 5}}>
        <p  style={{ textAlign: 'left', padding: 10, margin: 0, color: "white", fontWeight: 'bold'}}>{intl.formatMessage({id: "HowAskDeleteSub"})}</p>
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 5}}>
          {
            height5 === 0 ?
            <img  src={arrow} style={{width:"20px"}}/>
            :
            <img class="rotate90" src={arrow} style={{width:"20px"}}/>
          }
        </div>
        </div>
      </button>
      <AnimateHeight
        id="example3"
        duration={500}
        height = {height5}>
        <p style={{color: "white"}}>
        {intl.formatMessage({id: "HowAskDeleteSubExpl"})}
        <a href="/deleteSub" style={{color:"black", fontWeight:"bold"}}>
        {intl.formatMessage({id: "HowAskDeleteSubExpl1"})}
        </a>
        {intl.formatMessage({id: "HowAskDeleteSubExpl2"})}
        </p>
      </AnimateHeight>
    </div>
    </Row>

    <h2 id="DeleteAccountSection" style={{marginTop: "4rem"}}>{intl.formatMessage({id: "DeleteAccountTitle"})}</h2>
    <p>{intl.formatMessage({id: "DeleteAccountExpl"})}</p>
    <Row>
    <div className="shadow filtre" style={{width: "100%",  border: 0, marginTop: 15, borderRadius: 5, margin: '2px'}}>
      <button className="filtre" onClick={() => open()} style={{width: "100%",  border: 0,  borderRadius: 5}}>
        <div  style={{display: "flex", flexDirection: 'row', alignItems: 'center', marginLeft: 5}}>
        <p  style={{ textAlign: 'left', padding: 10, margin: 0, color: "white", fontWeight: 'bold'}}>{intl.formatMessage({id: "TimeToDeleteAccount"})}</p>
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 5}}>
          {
            height === 0 ?
            <img  src={arrow} style={{width:"20px"}}/>
            :
            <img class="rotate90" src={arrow} style={{width:"20px"}}/>
          }
        </div>
        </div>
      </button>
      <AnimateHeight
        id="example"
        duration={500}
        height = {height}>
        <p style={{color: "white"}}>{intl.formatMessage({id: "TimeToDeleteAccountExpl"})}</p>
      </AnimateHeight>
    </div>

    </Row>
    <Row>
    <div className="shadow filtre" style={{width: "100%",  border: 0, marginTop: 15, borderRadius: 5, margin: '2px'}}>
      <button className="filtre" onClick={() => open1()} style={{width: "100%",  border: 0,  borderRadius: 5}}>
        <div  style={{display: "flex", flexDirection: 'row', alignItems: 'center', marginLeft: 5}}>
        <p  style={{ textAlign: 'left', padding: 10, margin: 0, color: "white", fontWeight: 'bold'}}>{intl.formatMessage({id: "HowAskDeleteAccount"})}</p>
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 5}}>
          {
            height1 === 0 ?
            <img  src={arrow} style={{width:"20px"}}/>
            :
            <img class="rotate90" src={arrow} style={{width:"20px"}}/>
          }
        </div>
        </div>
      </button>
      <AnimateHeight
        id="example1"
        duration={500}
        height = {height1}>
        <p style={{color: "white"}}>
        {intl.formatMessage({id: "HowAskDeleteAccountExpl"})}<br/><br/>
        {intl.formatMessage({id: "HowAskDeleteAccountExpl1"})}<br/><br/>
        {intl.formatMessage({id: "HowAskDeleteAccountExpl2"})}<br/><br/>
        {intl.formatMessage({id: "HowAskDeleteAccountExpl3"})}
        </p>
      </AnimateHeight>
    </div>
    </Row>

    </corps>

    </Container>
    <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%', marginTop: 80}}/>
    </div>

    <Footer/>
    </Fade>
    </div>
  );
}

export default Faq;

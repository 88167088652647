import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Modal from 'react-bootstrap/Modal'
import Constant from '../../networking/Constant'
import { CircularProgress } from '@material-ui/core';
import Navigation from '../navBar/NavDark'
import Footer from '../footer/Footer'
import Sponsor from '../sponsor/Sponsor'
import { useIntl } from 'react-intl';

const cookies = new Cookies();

function MyVerticallyCenteredModal(props) {
  const { language } = useParams();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.intl.formatMessage({id: 'MofifierMotDePasse'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({id: 'Approuve'})}</h4>
        <p style={{marginTop: 25}}>
          {props.intl.formatMessage({id: 'MotDePasseBienModifie'})}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button href={`/login/${language}`} style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={props.onHide}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModalError(props) {
  const { language } = useParams();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.intl.formatMessage({id: 'MofifierMotDePasse'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({id: 'TokenExpire'})}</h4>
        <p style={{marginTop: 25}}>
          {props.intl.formatMessage({id: 'VeuillezRecommencer'})}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button href={`/forgetPassword/${language}`} style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={props.onHide}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

function ForgetPasswordToken(props) {
  const intl = useIntl();

  const { language } = useParams(); 

  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [bgd, setBgd] = useState('35%')
  const [modalShow, setModalShow] = useState(false)
  const [modalShowError, setModalShowError] = useState(false)


  const [token, setToken] = useState(null)
  const [newPassword, setNewPassword] = useState(null)
  const [newPasswordCompare, setNewPasswordCompare] = useState(null)

  const[error, setError] = useState({
    newPassword : "",
    newPasswordCompare : "",
    info: "",
  })

  const pressEnter = (event) => {
    if(event.key === "Enter")
        updatePassword()
    }

  const clear = () => {
    setError({
      token: "",
      newPassword : "",
      newPasswordCompare : "",
      info: "",
    })
  }

  const updatePassword = () => {
    setLoading(true)
    if(newPassword === newPasswordCompare){
      fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/ForgetPassword/Second/Step',{
        method:'POST',
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json'},
        body:JSON.stringify({Token: token, Email: props.location.state.email, Password: newPassword, ConfirmationPassword: newPasswordCompare})
      }).then(response=>response.json()).then(res=>{
        if(res.error === true){
          setLoading(false)
        if(res.message === "Token must have 6")
          setError({token : "TokenInvalide"})
        if(res.message === "Password must have 5")
          setError({newPassword : "PasswordLength"})
        if(res.message === "Expiration Time is finish")
          setModalShowError(true)
        }
        else if(res.error === false){
          setLoading(false)
          setModalShow(true)
        }
      }).catch((e) => {
        setLoading(false)
        setError("ErrorNetwork")
      })
    }
    else{
      setLoading(false)
      setError({newPassword: "PasswordPasIdentique"})
    }
  }

  const { setLocale } = props;

  useEffect(() => {
    if (window.screen.width >= 1250){
      setBgd('35%')
    } else {
      setBgd('100%')
    }
    if (language === "fr") {
      setLocale(language);
    } else if (language === "nl") {
      setLocale(language);
    }
}, [language, setLocale]);


const handleSubmit = (event) => {
  event.preventDefault();
  clear()
  updatePassword()
  const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.stopPropagation();
  }
  setValidated(true);
};

  if(!props.location.state || !props.location.state.email){
    return <Redirect push to={`/login/${language}`}/>
  }

  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
      <Navigation/>
      <Fade>

      <div style={{backgroundColor: "#F6F7FB", paddingTop: "100px"}}>
        <Container className="shadow" style={{width:bgd, backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
        <h2 style={{textAlign: 'left'}}>{intl.formatMessage({id: 'MotDePasseOublieTitre'})}</h2>
        <p style={{textAlign: 'left'}}>{intl.formatMessage({id: 'CompleteForgetPassword'})}</p>
        <p style={{color:'red', fontWeight:'bold', marginTop: 20}}></p>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="formGridToken" style={{marginTop: 20, padding: 25}}>
              <Form.Control
              required
              placeholder={intl.formatMessage({id: "EnterToken"})}
              value={token}
              onChange={(event) => setToken(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
                {intl.formatMessage({id: error.token ? error.token : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
                {intl.formatMessage({id: error.token ? error.token : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formGridEmail" style={{padding: 25}}>
              <Form.Control
              required
              type="password"
              placeholder={intl.formatMessage({id: "EnterNewPassword"})}
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
                {intl.formatMessage({id: error.newPassword ? error.newPassword : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
                {intl.formatMessage({id: error.newPassword ? error.newPassword : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formGridPassword" style={{padding: 25}}>
              <Form.Control
              required
              onKeyPress={(event) => pressEnter(event)}
              type="password"
              placeholder={intl.formatMessage({id: "ConfirmNewPassword"})}
              value={newPasswordCompare}
              onChange={(event) => setNewPasswordCompare(event.target.value)}/>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
                {intl.formatMessage({id: error.newPasswordCompare ? error.newPasswordCompare : "Space" })}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
                {intl.formatMessage({id: error.newPasswordCompare ? error.newPasswordCompare : "Space" })}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Button
          style={{margin: 20, marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} type="submit">
          {
            loading === true ?
            <div style={{position: 'relative'}}>
              <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                <CircularProgress color="white" size={20}/>
              </div>
              <div>
                <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: "Confirmer"})}</p>
              </div>
            </div>
            :
            <p style={{margin: 0}}>{intl.formatMessage({id: "Confirmer"})}</p>
          }
          </Button>
        </Form>

        <MyVerticallyCenteredModal
          show={modalShow}
          intl={intl}
          onHide={() => setModalShow(false)}
        />
        <MyVerticallyCenteredModalError
          show={modalShowError}
          intl={intl}
          onHide={() => setModalShow(false)}
        />
        </Container>
          <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%', marginTop: 80}}/>
        </div>

        <div style={{backgroundColor: '#FFFFFF'}}>
        <Sponsor/>
        </div>

      <Footer/>

      </Fade>
    </div>
  );
}

export default ForgetPasswordToken;

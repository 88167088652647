import "./App.css";
import React, { Suspense, lazy, useState, useEffect } from "react";
import "./fonts/The Northern Block Ltd - Typold Regular.woff";
import "./fonts/The Northern Block Ltd - Typold Bold.woff";

import { Fade } from "react-awesome-reveal";
//import { Redirect } from 'react-router-dom'
//import { fadeIn } from 'react-animations'
import { useLoading, Rings } from "@agney/react-loading";
import "bootstrap/dist/css/bootstrap.min.css";

import Navigation from "./components/navBar/Nav";

import Language from "./components/language/Language";
const Header = lazy(() => import("./components/home/Header"));
const EventSection = lazy(() => import("./components/home/EventSection"));
const Video = lazy(() => import("./components/home/Video"));
const MobileApp = lazy(() => import("./components/home/MobileApp"));
const PriceMember = lazy(() => import("./components/home/PriceMember"));
const Sponsor = lazy(() => import("./components/sponsor/Sponsor"));
const Footer = lazy(() => import("./components/footer/Footer"));


function App(props) {
  const [tip, setTip] = useState(true);
  const [bgd, setBgd] = useState(true);
  const [lgHome, setLgHome] = useState(true);

  useEffect(() => {
    if (window.screen.width >= 850) {
      setBgd(true);
    } else {
      setBgd(false);
    }
  }, []);

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Rings width="100" color="#FC8016" />,
  });

  const changePage = () => {
    setLgHome(false);
  };

  return (
    <Suspense fallback={<div className="height"> </div>}>
      {lgHome === true ? (
        <Language
          changePage={() => changePage()}
          setLocale={props.setLocale}
          info={true}
        />
      ) : (
        <>
          <div className="App">
            <div
              style={{ backgroundColor: "white", fontFamily: "Typold Regular" }}
            >
              <Navigation />
              <Header tip={tip} setTip={setTip} setLocale={props.setLocale} />

              <div>
                <EventSection props={props} />
                <Fade>
                  <MobileApp />
                  <Video />
                  <PriceMember />
                  <Sponsor props={props} />
                  <Footer />
                </Fade>
              </div>
            </div>
          </div>
        </>
      )}
    </Suspense>
  );
}

export default App;

/*
<ul>
 <FlatList
   list={people}
   renderItem={renderPerson}
   renderWhenEmpty={() => <div>List is empty!</div>}
   sortBy={["firstName", {key: "lastName", descending: true}]}
   groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
 />
</ul>

<Col style={{marginTop: 50}}>
<ComposableMap>
 <Geographies geography={geoUrl}>
   {({ geographies }) =>
   geographies.map((geography, i) =>
    <Geography
      key={i}
      geography={geography}
      style={{
        default: {
           fill: "#ECEFF1",
           stroke: "#607D8B",
           strokeWidth: 0.75,
           outline: "none",
        },
        hover: {
           fill: "#CFD8DC",
           stroke: "#607D8B",
           strokeWidth: 1,
           outline: "none",
        },
        pressed: {
           fill: "#FF5722",
           stroke: "#607D8B",
           strokeWidth: 1,
           outline: "none",
        }
     }}
   />
  )
   }
 </Geographies>
 </ComposableMap>
</Col>

*/

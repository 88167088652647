import React, { useEffect, useState } from 'react';
import "./Consulting.css"
import { Fade } from "react-awesome-reveal";
import { Button, Row, Container, Col, Image, Form} from 'react-bootstrap';
import Constant from '../../networking/Constant'
import { CircularProgress } from '@material-ui/core';
import Cookies from 'universal-cookie'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
} from "react-share";
import { useLoading, Rings } from '@agney/react-loading';
import Modal from 'react-bootstrap/Modal'
import HelmetMetaData from "../HelmetMetaData/HelmetMetaData";
import LogoB19 from '../../image/ThumbLogo.png'
import { loadStripe } from '@stripe/stripe-js';
import { useIntl } from 'react-intl';
import detectBrowserLanguage from 'detect-browser-language'


const cookies = new Cookies();
const stripePromiseBxl = loadStripe('pk_live_51J4hxHL0SWLMklZ3E53r2s856WjJNqxvY0ElyxyQQm8c62OAXkBDqKc1AmuN9auWfldnDdV0Oe0uCjYqR03Ffo2100uIY1TDh3');
const stripePromiseLuik = loadStripe('pk_live_51JCOAJEbzcAf2J36UHWIEnEMW0euy8JWvuhjLUtvAfq3iP5K2UqsKuhS7FECNvCfEwS8IZhW8vCbPyCxkq0mWnUt00BjxzCUih');
const stripePromiseLux = loadStripe('pk_live_51JMVepEXYjxp2I0yYzxmdPpezg4Y7P1dpcOID8tNswUwzTvLNwpE9aTVJXtGiGLB1gw0PuIXLUifI7milrZ7nemh00qcKghPGM');

//SubscribeEvents, params: Jwt, IdEvent, Source: "Website"
//UnSbscribeEvents, params: Jwt, IdEvent, Source: "Website"


function MyVerticallyCenteredModal(props) {
  const intl = useIntl();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {intl.formatMessage({id: 'Inscription'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{intl.formatMessage({id: 'Succes'})}</h4>
        <p style={{marginTop: 25}}>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button href="/" style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={props.onHide}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

function UrlConsulting(props) {
  const intl = useIntl();

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Rings width="100" color="#FC8016" />,
  });

  const [modalShow, setModalShow] = useState(false);
  const [validated, setValidated] = useState(false);


  const [data, setData] = useState(null)
  const [subscribe, setSubscripe] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(true)
  const [block, setBlock] = useState(false)
  const [payment, setPayment] = useState(false)
  const [source, setSource] = useState("")
  const [sharing, setSharing] = useState(true)


  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [tel, setTel] = useState("")
  const [society, setSociety] = useState("")
  const [billing, setBilling] = useState(false)




  const [error, setError] = useState({
    network: "",
    firstName: "",
    lastName: "",
    email: "",
    tel: "",
    message: "",
  })

  const clear = () => {
    setError({
      network: "",
      firstName: "",
      lastName: "",
      email: "",
      tel: "",
      message: "",
    })
  }

  const checkRequest = async () => {
    setLoading(true)
    clear()
    if (source === null){
      alert(intl.formatMessage({id: 'InvitationRequired'}))
      setLoading(false)
      return ;
    }
    fetch(Constant.IP_BACKEND_DNS + '/publicEvent',{
      method:'POST',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
      body:JSON.stringify({
        idEvent: data.Id,
        firstName : firstName,
        lastName: lastName,
        email : email,
        tel: tel,
        society: society,
        getBilling: billing,
        region : data.Compta,
        source: source,
        sharing: sharing
        })
    }).then(response=>response.json()).then(res=>{
      if(res.error === false){
        if(data.isFree === false){
          paymentPublic(res.data)
          setLoading(false)
          return ;
        }
      setLoading(false)
      setModalShow(true)
      return ;
      }
      if(res.error === true){
        setLoading(false)
      if(res.message === "subscribe"){
        setLoading(false)
        setError({message: "EmailAlreadySubscribe"})
        return ;
      }
      if(res.message === "Members are not authorize on this gateaway"){
        setLoading(false)
        setError({message: "EmailMember"})
        return ;
      }
      setError({
        firstName: res.message.firstName,
        lastName: res.message.lastName,
        email: res.message.email,
        tel: res.message.tel,
      })
      }
    }).catch((e) => {
      setLoading(false)
      setError({network: "ErrorNetwork"})
    })
}


const paymentPublic = async (id) => {
  let stripe = null
  const response = await fetch(Constant.IP_BACKEND_DNS + '/paymentPublicEvent', { method: 'POST',
  headers:{
  'Content-Type': 'application/json',
  'Accept': 'application/json'},
  body:JSON.stringify({id: id, idEvent: data.Id, source: source}) //j'été là jdfbjg
 });
  const session = await response.json();
  if (session.error === true) {
      setLoading(false)
      alert(intl.formatMessage({id: 'ErrorHappened'}))
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
  }
  if(session.region === "bxl" || session.region === "anv" || session.region === "fl"){
    stripe = await stripePromiseBxl;
  }
  if (session.region === "luik"){
    stripe = await stripePromiseLuik;
  }
  if (session.region === "lux"){
    stripe = await stripePromiseLux;
  }
  const result = await stripe.redirectToCheckout({
    sessionId: session.id,
  });
  if (result.error) {
    setLoading(false)
    alert(intl.formatMessage({id: 'ErrorHappened'}))
  }
return ;
}


  const getIdMember = () => {
    if (props.match.params.idMember)
      return (props.match.params.idMember)
    return null
  }

  const verifyLanguage = (language) => {
    var languageSupported = ["fr", 'nl']

    if (!language)
      return (0);
    if (languageSupported.findIndex(res => res === language) !== -1)
      return (1);
    return (0);
  }

  const GetLanguage =  () => {
    try {
      var language = cookies.get('language')
      if (verifyLanguage(language))
        return (language);
      var language = detectBrowserLanguage();
      if (!language)
        return ('fr')
      var formatLanguage = language.split('-')[0]
      if (formatLanguage !== 'fr' && formatLanguage !== 'nl')
        return ('fr')
      else
        return (formatLanguage)
    } catch (e) {
      return ('fr')
    }
  }

  useEffect(() => {
    if(data === null)
      fetchData()
    else{
      if(data.IsSubscribe === "pending"){
        setSubscripe('pending')
        setPayment(true)
        setBlock(false)
        return ;
      }
      if(data.IsSubscribe === "unsubscribe" && data.isFree === false){
        setSubscripe('false')
        setPayment(true)
        setBlock(false)
        return ;
      }
      if(data.IsSubscribe === "unsubscribe"){
        setSubscripe('false')
        setBlock(false)
        return ;
      }
      if(data.IsSubscribe === "subscribe"  && data.isFree === false){
        setSubscripe('true')
        setBlock(true)
        return ;
      }
      if(data.IsSubscribe === "subscribe" && data.isFree === true){
        setSubscripe('true')
        setBlock(false)}
      else
        setSubscripe('false')
    }
  }, [data]);

  const ChangeLanguage = (lg) => {
    localStorage.setItem("language", lg);
    if (lg === "fl" || lg === "anv")
      props.setLocale("nl");
    else
      props.setLocale("fr");    
  };

  const fetchData = () => {
    var language = GetLanguage()
    fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/GetSpecificInvitationEvent/',{
      method:'POST',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
      body:JSON.stringify({IdMember:  getIdMember(), IdEvent: props.match.params.idEvent, Language: language, SizeImage: 'medium'})
    }).then(response=>response.json()).then(res=>{
      //console.log("res", res.data.Event.Location.RegionB19)
      ChangeLanguage(res.data.Event.Location.RegionB19)
      if(res.error === true){
        alert(intl.formatMessage({id: 'ErrorHappened'}))
      }
      else if(res.error === false){
        setData(res.data.Event)
        if (props.match.params.idMember)
        {
          setFirstName(res.data.Member.FirstName)
          setLastName(res.data.Member.LastName)
          setEmail(res.data.Member.Email)
          setTel(res.data.Member.Tel)
        }
        setLoadingPage(false)
      }
    }).catch((e) => {
      alert(intl.formatMessage({id: 'ErrorHappened'}))
    })
  }


  const checkButtonSubscribe = () => {
    if(subscribe === 'pending'){
      return(intl.formatMessage({id: 'PaymentRequestMAJ'}))
    }
    if(subscribe === 'true'){
      return(intl.formatMessage({id: 'UnSubscribeMAJ'}))
    }
    if(subscribe === 'false' && payment === true){
      var SubscribeTitle = intl.formatMessage({id: 'SubscribeMaj'});
      var message = SubscribeTitle + '(' + data.Price + "€)"
      return(message)
    }
    if(subscribe === 'false' && payment === false){
      return(intl.formatMessage({id: 'SubscribeMaj'}))
    }
    return(intl.formatMessage({id: 'SubscribeMaj'}))
  }




  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    checkRequest()
    if (form.checkValidity() === false)
      event.stopPropagation();
    setValidated(true);
  };

  var encodeUrl = encodeURIComponent


  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>

      <Fade>
      <div style={{paddingTop: 50, backgroundColor: "#F6F7FB", paddingBottom: 100}}>
      <Container>
      <div style={{textAlign: 'left'}}>

      <MyVerticallyCenteredModal
        show={modalShow}
        intl={intl}
        onHide={() => setModalShow(false)}
      />


      </div>
      {
        loadingPage === true ?
        <div className="height"> <section {...containerProps}> {indicatorEl} </section></div>
        :
        <Row>
        <HelmetMetaData
        title={data.Location.Name}
        description={data.Name}
        image={data.Image}
        ></HelmetMetaData>
          <Col className="shadow" style={{backgroundColor: '#FFFFFF', padding: 15, borderRadius: 5, margin: 10, marginTop: 30}}>

          <div style={{textAlign: 'center', paddingBottom: 10}}>
          </div>
          <div style={{display:'flex', flexDirection: 'row', marginTop: 40,  justifyContent: 'center' }}>
            <Image className="widthLogoB19" src={LogoB19}  />
            <div style={{display:'flex', alignItems: 'flex-end', marginLeft: 10}}>
            </div>
          </div>

          <div style={{marginTop: 20}}>
            <h3>{data.Location.Name}</h3>
          </div>
          <div style={{display:'flex', flexDirection: 'row', justifyContent: 'center'}}>

            <div style={{display:'flex', alignItems: 'center', marginLeft: 10, marginTop: 12}}>
            <p className="fontSizeLocation">{data.DateDisplayEvent}</p>
          </div>
        </div>

          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 20}} >
            <div style={{ width: '60%'}}>
            <Image thumbnail style={{padding: 5, borderRadius: 10}} src={data.Image}  />

            <div style={{marginTop: 30}}>
            <h5>{data.Name}</h5>
            </div>

            <div style={{textAlign: 'center', marginBottom: 30, marginTop: 30}}>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridlastName">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: 'Nom'})}</p>
                  <Form.Control
                  required
                  type="text"
                  disabled={props.match.params.idMember ? true : false}
                  placeholder={intl.formatMessage({id: 'EntrezNom'})}
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}/>
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
                    {intl.formatMessage({id: error.lastName ? error.lastName : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
                    {intl.formatMessage({id: error.lastName ? error.lastName : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridfirstName">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: 'Prénom'})}</p>
                  <Form.Control
                  required
                  type="text"
                  disabled={props.match.params.idMember ? true : false}
                  placeholder={intl.formatMessage({id: 'EntrezPrenom'})}
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}/>
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
                    {intl.formatMessage({id: error.firstName ? error.firstName : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
                    {intl.formatMessage({id: error.firstName ? error.firstName : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridfirstMail">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: 'Email'})}</p>
                  <Form.Control
                  required
                  disabled={props.match.params.idMember ? true : false}
                  type="email"
                  placeholder={intl.formatMessage({id: 'EntrezEmail'})}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}/>
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
                    {intl.formatMessage({id: error.email ? error.email : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
                    {intl.formatMessage({id: error.email ? error.email : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridTel">
                  <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: 'Telephone'})}</p>
                  <Form.Control
                  required
                  type="text"
                  disabled={props.match.params.idMember ? true : false}
                  placeholder={intl.formatMessage({id: 'EnterTelephone'})}
                  value={tel}
                  onChange={(event) => setTel(event.target.value)}/>
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color:'red'}}>
                    {intl.formatMessage({id: error.tel ? error.tel : "Space" })}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
                    {intl.formatMessage({id: error.tel ? error.tel : "Space" })}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row style={{justifyContent: 'center', alignItems: 'center'}}>
              <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridSociety" >
                <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "Societe"})}<span style={{color: 'red'}}> </span></p>
                <Form.Control
                required
                type="text"
                placeholder={intl.formatMessage({id: "EntrezSociete"})}
                value={society}
                onChange={(event) => setSociety(event.target.value)}/>
                <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                  {intl.formatMessage({id: error.society ? error.society : "Space" })}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                  {intl.formatMessage({id: error.society ? error.society : "Space" })}
                </Form.Control.Feedback>
              </Form.Group>


              {
                data.isFree === false && data.Compta !== "luik" && data.Compta !== "lux" ?
                <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridBilling" >
                <div style={{textAlign: 'left', marginTop: '45px', display: 'flex'}}>
                  <Form.Check
                    required

                    label={intl.formatMessage({id: "GetBilling"})}
                    name="formHorizontalRadiosGender"
                    id="formHorizontalRadios2Gender"
                    value={billing}
                    onChange={()=> setBilling(!billing)}
                  />
                  <span style={{color: 'red', marginLeft: 4}}> </span>
                </div>
                </Form.Group>
                :
                <Form.Group as={Col} xl={6} lg={6} sm={12} md={12} controlId="formGridBilling" >
                <div style={{textAlign: 'left', marginTop: '45px', display: 'flex'}}>

                </div>
                </Form.Group>

              }
              </Row>
              <Row>
              <Form.Group as={Col}  xl={6} lg={6} sm={12} md={12} controlId="exampleForm.ControlSelect9">
              <p style={{textAlign: 'left', marginTop: 10}}>{intl.formatMessage({id: "MaSource"})}</p>
              <Form.Control  required as="select" onChange={(event) => setSource(event.target.value)}>
               

          

                <option hidden value="">
                  {intl.formatMessage({id: "MaSourceLabel"})}
                </option>
                <option value="Philippe Vanderbecken">
                Philippe Vanderbecken
                </option>
                <option value="Florian Moulhade">
                Florian Moulhade
                </option>
                <option value="Guillaume Collard">
                Guillaume Collard
                </option>
                <option value="CBRE">
                CBRE
                </option>
                <option value="ABN AMRO">
                ABN AMRO
                </option>                
                <option value="GBL">
                GBL
                </option>
               
                <option value="RAFC">
                RAFC
                </option>
                <option value="MeetDistrict">
                MeetDistrict
                </option>
                <option value="Top Secret Communication">
                Top Secret Communication
                </option>
                <option value="BELFIUS">
                BELFIUS
                </option>
                <option value="MeetDistrict">
                MeetDistrict
                </option>
                <option value="AXA">
                  AXA
                </option>
            
                <option value="Young Talent Award">
                Young Talent Award
                </option>
                <option value="DavidLloyd">
                  {intl.formatMessage({id: "DavidLloyd"})}
                </option>
                <option value="Silversquare">
                  {intl.formatMessage({id: "Silversquare"})}
                </option>
                <option value="Nathalie Ducobu">
                  {intl.formatMessage({id: "NathalieDucobu"})}
                </option>
                <option value="Sofie Borghgraef">
                  {intl.formatMessage({id: "SofieBorghgraef"})}
                </option>
                <option value="SD Worx">
                  {intl.formatMessage({id: "SDWorx"})}
                </option>
                
                <option value="ICHEC Alumni">
                  {intl.formatMessage({id: "ICHECAlumni"})}
                </option>
             
                <option value="Véronique Orens">
                  {intl.formatMessage({id: "VéroniqueOrens"})}
                </option>
                <option value="Stephanie Van den Eynde">
                  {intl.formatMessage({id: "StephanieVandenEynde"})}
                </option>
                <option value="Thibaut Steyaert">
                  {intl.formatMessage({id: "ThibautSteyaert"})}
                </option>
                <option value="Steven Tritsmans">
                  {intl.formatMessage({id: "StevenTritsmans"})}
                </option>
                <option value="Filip Goorden">
                  {intl.formatMessage({id: "FilipGoorden"})}
                </option>
                <option value="Julien Depouhon">
                  Julien Depouhon
                </option>
               
                <option value="Liberius">
                  Liberius
                </option>
                <option value="Porsche">
                 Porsche
                </option>
                <option value="Cabale Exquise">
                Cabale Exquise
                </option>
                <option value="SWINZ">
                SWINZ
                </option>
                <option value="Ionnyk">
                Ionnyk
                </option>
                <option value="Voucher of Member">
                Voucher of Member
                </option>
             
             

                <option value="Autres">
                  {intl.formatMessage({id: "Autres"})}
                </option>
          </Form.Control>
        </Form.Group>
       
        <Row>
        <div style={{textAlign: 'left', marginTop: 40}}>
            <input
              type="checkbox"
              id="exampleCheck1"
              checked={sharing}
              onChange={() => setSharing(!sharing)}/>
              <label style={{color: 'black', marginLeft: 10, fontWeight: 'bold'}}>{intl.formatMessage({id: "Sharing"})}</label>
        </div>
        </Row>

        </Row>

              <p style={{textAlign: 'center', color:'red', fontWeight: 'bold'}}>  {intl.formatMessage({id: error.message ? error.message : "Space" })}</p>
              <Button
              style={{marginTop: 20, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} type="submit">
              {
                loading === true ?
                <div style={{position: 'relative'}}>
                  <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                    <CircularProgress color="white" size={20}/>
                  </div>
                  <div>
                    <p style={{margin: 0, color: 'transparent'}}>{checkButtonSubscribe()}</p>
                  </div>
                </div>
                :
                <p style={{margin: 0}}>{checkButtonSubscribe()}</p>
              }
              </Button>
            </Form>
            </div>

            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 40}}>
            <div style={{backgroundColor: '#ff5f00', height: 2, width: '90%'}}/>
            </div>

            <div style={{textAlign: 'left', marginTop: 50}}>

            </div>

            <div style={{textAlign: 'justify', marginTop: 20}} dangerouslySetInnerHTML={{__html: data.DescriptionHTML}} />

            <p style={{textAlign: 'left', fontWeight: 'bold'}}>{intl.formatMessage({id: "AddressDoublePoint" })}</p>
            <p style={{textAlign: 'left'}}>{data.Location.Street}, {data.Location.Number} - {data.Location.PostalCode} {data.Location.City}</p>
            <div style={{marginTop: 50}}>
            <FacebookShareButton
                url={`https://newb19.be/agenda/${props.match.params.idEvent}`}
                style={{margin: 5}}>
            <FacebookIcon size={32} round/>
            </FacebookShareButton>
            <LinkedinShareButton url={`https://newb19.be/agenda/${props.match.params.idEvent}`} style={{margin: 5}}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <EmailShareButton url={`https://newb19.be/agenda/${props.match.params.idEvent}`} style={{margin: 5}}>
            <EmailIcon size={32} round />
            </EmailShareButton>
            </div>
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 50}}>
            <div style={{backgroundColor: '#ff5f00', height: 2, width: '90%'}}/>
            </div>
            <Row style={{display: 'flex', justifyContent:'center', marginBottom: 15}}>
              {
                data.Sponsor.length !== 0 ?
                <h3 style={{marginTop: 25, marginBottom: 50}}>{intl.formatMessage({id: 'SponsorBy'})}</h3>
                :
                <>
                </>
              }
              {data.Sponsor && data.Sponsor.map((item) => {
                return(
                  <Col xl={2} lg={2} md={2} sm={3}>
                    <a href={item.Link}>
                      <Image style={{margin: 10, objectFit: 'contain', width: "100%"}} src={item.LogoLarge}  />
                    </a>
                </Col>
                )
              })}

            </Row>
            </div>
          </div>

          </Col>
        </Row>
      }

      </Container>
      </div>

      </Fade>
    </div>
  );
}

export default UrlConsulting;

const initialState = {
 region: localStorage.getItem('region') ? localStorage.getItem('region') : 'bxl',
 firstTime: false,
 scrollagenda: 'false'
}

function _state_reducer_home_page(state = initialState, action){
  let nextState
  switch(action.type){
    case'SET_REGION':
    nextState = {
      ...state,
      region: action.state.region,
      firstTime: true,
      scrollagenda: action.state.scrollagenda
    }
    return nextState || state
    case'SET_REGION_LG':
    nextState = {
      ...state,
      region: action.state.region,
    }
    return nextState || state
    default:
      return state
}
}

export default _state_reducer_home_page

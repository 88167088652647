import React, { useRef, useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { Button, Row, Container, Col, Image, Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import Constant from '../../networking/Constant'
import { CircularProgress } from '@material-ui/core';
import { Redirect, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import fetch from 'node-fetch'
import Navigation from '../navBar/NavDark'
import Sponsor from '../sponsor/Sponsor'
import Footer from '../footer/Footer'
import './UpdatePassword.css'
import { useIntl } from 'react-intl';

const cookies = new Cookies();

function MyVerticallyCenteredModal(props) {
  const { language } = useParams();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.intl.formatMessage({id: 'MofifierMotDePasse'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({id: 'Approuve'})}</h4>
        <p style={{marginTop: 25}}>
          {props.intl.formatMessage({id: 'MotDePasseBienModifie'})}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button href={`/profil/${language ? language : "fr"}`} style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={props.onHide}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

function UpdatePassword(props) {
  const intl = useIntl();

  const { language } = useParams();

  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false);
  const [bgd, setBgd] = useState('35%')
  const [modalShow, setModalShow] = useState(false);

  const [newPassword, setNewPassword] = useState(null)
  const [newPasswordCompare, setNewPasswordCompare] = useState(null)

  const[error, setError] = useState({
    newPassword : "",
    newPasswordCompare : "",
    info: "",
  })

  const { setLocale } = props;

  useEffect(() => {
    if (window.screen.width >= 1250){
      setBgd('35%')
    } else {
      setBgd('100%')
    }
    if (language === "fr") {
      setLocale(language);
    } else if (language === "nl") {
      setLocale(language);
    }
},[language, setLocale]);

  const pressEnter = (event) => {
    if(event.key === "Enter")
        updatePassword()
    }

  const clear = () => {
    setError({
      newPassword : "",
      newPasswordCompare : "",
    })
  }

  const updatePassword = () => {
    setLoading(true)
    if(newPassword === newPasswordCompare){
      fetch(Constant.IP_BACKEND_DNS + '/updatePassword',{
        method:'POST',
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json'},
        body:JSON.stringify({accesToken : cookies.get('access'), password : newPassword})
      }).then(response=>response.json()).then(res=>{
        if(res.error === true){
          setLoading(false)
          setError({info : res.message})
        }
        else if(res.error === false){
          setLoading(false)
          setModalShow(true)
        }
      }).catch((e) => {
        setLoading(false)
        alert(intl.formatMessage({id: "ErrorNetwork" }))
      })
    }
    else{
      setLoading(false)
      setError({info: "PasswordPasIdentique"})
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    clear()
    updatePassword()
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
  };

  if(cookies.get('access') === undefined)
    return <Redirect push to={`/login/${language}`}/>

  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
    <Navigation/>
      <Fade>

      <div style={{backgroundColor: "#F6F7FB", paddingTop: 180}}>
        <Container className="shadow" style={{width: bgd, backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
        <h2 style={{textAlign: 'left', marginLeft: 15}}>{intl.formatMessage({id: 'MofifierMotDePasse'})}</h2>
        <p style={{color:'red', fontWeight:'bold', marginTop: 25}}>{intl.formatMessage({id: error.info ? error.info : "Space" })}</p>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="formGridEmail" style={{padding: 25}}>
              <Form.Control
              required
              type="password"
              placeholder={intl.formatMessage({id: 'EnterNewPassword'})}
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}/>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formGridPassword" style={{padding: 25}}>
              <Form.Control
              required
              onKeyPress={(event) => pressEnter(event)}
              type="password"
              placeholder={intl.formatMessage({id: 'ConfirmNewPassword'})}
              value={newPasswordCompare}
              onChange={(event) => setNewPasswordCompare(event.target.value)}/>
            </Form.Group>
          </Row>
          <Button
          href={`/profil/${language}`}
          style={{margin: 20, marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}}>
          {intl.formatMessage({id: 'Annuler'})}
          </Button>
          <Button
          style={{margin: 20, marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}}
          type="submit">
          {
            loading === true ?
            <div style={{position: 'relative'}}>
              <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                <CircularProgress color="white" size={20}/>
              </div>
              <div>
                <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: 'ModifyButton'})}</p>
              </div>
            </div>
            :
            <p style={{margin: 0}}>{intl.formatMessage({id: 'ModifyButton'})}</p>
          }
          </Button>


      <MyVerticallyCenteredModal
        show={modalShow}
        intl={intl}
        onHide={() => setModalShow(false)}
      />

        </Form>

        </Container>
          <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%', marginTop: 80}}/>
        </div>

        <div style={{backgroundColor: '#FFFFFF'}}>
        <Sponsor/>
        </div>
      <Footer/>
      </Fade>
    </div>
  );
}

export default UpdatePassword;

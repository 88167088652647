import React, { useEffect, useState } from 'react';
import './Agenda.css'
import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import { useParams } from "react-router-dom";
import Constant from '../../networking/Constant'
import fetch from 'node-fetch'
import Cookies from 'universal-cookie'
import { Fade } from "react-awesome-reveal";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import arrow from '../../image/ic_arrow_down_white.png'
import { useLoading, Rings } from '@agney/react-loading';
import AnimateHeight from 'react-animate-height';
import { FiMapPin } from "react-icons/fi";
import { useIntl } from 'react-intl';

import Navigation from '../navBar/NavDark'

import Sponsor from '../sponsor/Sponsor'
import Footer from '../footer/Footer'
const cookies = new Cookies();

function Agenda(props) {
  const intl = useIntl();

  const { language } = useParams();

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Rings width="100" color="#FC8016" />,
  });

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [id, setId] = useState(null)
  const [agenda, setAgenda] = useState([])
  const [temp, setTemp] = useState([])
  const [height, setHeight] = useState(0)
  const [filter, setFilter] = useState([])

  const { setLocale } = props;

  useEffect(() => {
    if (language === "fr") {
      setLocale(language);
    } else if (language === "nl") {
      setLocale(language);
    }
  }, [language, setLocale]);

  useEffect(() => {
    let isMounted = true;
  
    const getAgenda = () => {
      setLoading(true);
  
      fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/GetEvents/', {
        method:'POST',
        headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ Language : language ? language : 'fr', SizeImage: 'small', Jwt : cookies.get('access') })
      })
      .then(response => response.json())
      .then(res => {
        if (isMounted) {
          if (res.error === true) {
            setLoading(false)
          } else if (res.error === false) {
            setAgenda(res.data);
            setTemp(res.data);
            setLoading(false);
          }
        }
      })
      .catch((e) => {
        if (isMounted) {
          setLoading(false);
        }
      });
  
      fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/GetListClubs/', {
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
      })
      .then(response => response.json())
      .then(res => {
        if (isMounted) {
          if (res.error === true) {
            setLoading(false)
          } else if (res.error === false) {
            setList(res.data);
          }
        }
      })
      .catch((e) => {
        if (isMounted) {
          setLoading(false);
        }
      });
    };
  
    getAgenda();
  
    return () => {
      isMounted = false; // Mettez le flag à false lors du nettoyage
    };
  
  }, [language, cookies]);

  const checkIsSubscribe = (item) => {
    if(item.IsSubscribe === "subscribe" || item.IsSubscribe === "pending")
      return(intl.formatMessage({id: "Inscrit" }))
    return("")
  }

  const checkLanguage = (item) => {
    if (item)
      return item.Language.map(res => res.toUpperCase()).join(" ")
    return('')
  }

  const open = () => {
    setHeight(height === 0 ? 'auto' : 0)
  }

  const isSelected = (city) => {
    var copyFilter = [...filter]
    var index = copyFilter.findIndex(res => res === city)
    if (index === -1)
    {
      copyFilter.push(city)
      setFilter(copyFilter)
      return ;
    }
    copyFilter.splice(index, 1)
    setFilter(copyFilter)
    return ;
  }

  const renderAgendaFilter = () => {
    if (agenda){
      var agendaFilter = [];
      var agendaCopy = [...agenda]
      if (filter.length === 0)
        agendaFilter = agendaCopy;
      else {
        agendaFilter = agendaCopy.filter(res => {
          if (filter.findIndex(region => region === "fl" ? (region === res.Location.RegionB19 || 'anv' === res.Location.RegionB19) : (region === res.Location.RegionB19)) !== -1)
            return (true)
          return (false)
        })
      }
      return (
        agendaFilter.map((item) => {
          return(

              <Col xl={6} lg={6} md={12} sm={12} key={item.Id} >
                  <Fade>
                <div className="shadow" style={{backgroundColor: '#FFFFFF', padding: 15, borderRadius: 5, marginTop: 10, marginBottom: 50}}>
                  <div style={{position: 'relative'}}>
                    <div style={{position: 'absolute', left: 0, backgroundColor: '#082331', borderRadius: 5}}>
                      {
                        item.IsSubscribe === "subscribe" || item.IsSubscribe === "pending" ?
                        <p style={{color: 'white', fontWeight: 'bold', margin: 8 }}>{checkIsSubscribe(item)}</p>
                        :
                        <>
                        </>
                      }
                    </div>
                  </div>
                  {
                    item.Closed === true ?
                    <div style={{position: 'relative'}}>
                      <div style={{position: 'absolute', right: 0, backgroundColor: 'grey', borderRadius: 5  }}>
                        <p style={{color: 'white', fontWeight: 'bold', margin: 8}}>{intl.formatMessage({id: 'Complet'})}</p>
                      </div>
                    </div>
                    :
                    <>
                    </>
                  }                              
                  <p style={{marginTop: 10}}>{item.DateDisplayEvent}</p>
                  
                  <div style={{position: 'relative'}}>
                    <a onClick={() => props.history.push({pathname: `/agenda/${language ? language : 'fr'}/${item.Id}`, state: [{data: {item} }] }) } style={{color: 'black', textDecoration: 'none'}}>
                      <LazyLoadImage effect="blur" style={{objectFit: "contain", padding: 5, borderRadius: 10,  width:'100%'}} src={item.Image}>
                      </LazyLoadImage>
                    </a>
                    <div style={{position: 'relative'}}>
                      <div style={{position: 'absolute', right: 0}}>
                        <p style={{color:"#082331", fontWeight: 'bold', margin: 8}}>{checkLanguage(item)}</p>
                      </div>
                    </div>
                  </div>
                  <div className='align-me'>
                    <p className="limit" style={{textAlign: 'center', marginTop: 10, width:'60%', fontWeight:'bold'}}>{item.Name}</p>
                  </div>
                  <Button onClick={() => props.history.push({pathname: `/agenda/${language}/${item.Id}`, state: [{data: {item} }] }) }  style={{fontWeight: 'bold',  marginTop: 10, backgroundColor: '#FC8016', borderWidth: 0}}>{intl.formatMessage({id: 'Subscribe'})}</Button>
                </div>
                </Fade>
              </Col>

          )
        })
      )
    }
    return (<></>)
  }

  const SelectRegion = () => {
    if (filter.length === 0)
      return intl.formatMessage({id: 'SelectYourRegion'})
    return filter.map(res => intl.formatMessage({id: res})).join(', ')
  }

  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>

      <Navigation/>

      <div style={{paddingTop: "100px", backgroundColor: "#F6F7FB"}}>

        <Container>
          <Row>
            <Col>
              <h3 style={{textAlign: 'left'}}>{intl.formatMessage({id: 'AgendaMin'})} </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p style={{textAlign: 'left', marginTop: 20}}>{intl.formatMessage({id: 'DisplayEventOf'})}</p>
                <div className="shadow filtre" style={{width: "100%",  border: 0, marginTop: 15, borderRadius: 15}}>
                  <button className="filtre" onClick={() => open()} style={{width: "100%",  border: 0,  borderRadius: 15}}>
                    <div  style={{display: "flex", flexDirection: 'row', alignItems: 'center', marginLeft: 5}}>
                    <FiMapPin size={20} color={"white"}/>
                    <p  style={{color:'#F8b083', textAlign: 'left', padding: 10, margin: 0}}>{SelectRegion()}</p>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 5}}>
                      {
                        height === 0 ?
                        <img  src={arrow} style={{width:"20px"}}/>
                        :
                        <img class="rotate90" src={arrow} style={{width:"20px"}}/>
                      }
                    </div>
                    </div>
                  </button>
                  <AnimateHeight
                    id="example"
                    duration={500}
                    height = {height}>
                  <fieldset>
                    <Form.Group as={Row}>
                      <Col>
                        <div style={{textAlign: 'left'}}>
                        <Form.Check
                          label={intl.formatMessage({id: 'bxl'})}
                          name="formHorizontalRadios"
                          id="formHorizontalRadios1"
                          onChange={() => isSelected("bxl")}
                          style={{color: 'white', textAlign: 'left', margin: 10}}
                        />
                        </div>
                        <div style={{textAlign: 'left', marginTop: 20}}>
                        <Form.Check
                          label={intl.formatMessage({id: 'luik'})}
                          name="formHorizontalRadios"
                          id="formHorizontalRadios2"
                          onChange={() => isSelected("luik")}
                          style={{color: 'white', textAlign: 'left', margin: 10}}
                        />
                      </div>
                        <div  style={{textAlign: 'left', marginTop: 20}}>
                        <Form.Check
                          label={intl.formatMessage({id: 'fl'})}
                          name="formHorizontalRadios"
                          id="formHorizontalRadios4"
                          onChange={() => isSelected("fl")}
                          style={{color: 'white', textAlign: 'left', margin: 10}}
                        />
                      </div>
                      <div  style={{textAlign: 'left', marginTop: 20}}>
                      <Form.Check
                        label={intl.formatMessage({id: 'lux'})}
                        name="formHorizontalRadios"
                        id="formHorizontalRadios5"
                        onChange={() => isSelected("lux")}
                        style={{color: 'white', textAlign: 'left', margin: 10}}
                      />
                    </div>
                      </Col>
                    </Form.Group>

                  </fieldset>
                  </AnimateHeight>
                </div>

            </Col>
            <p style={{textAlign: 'left', marginTop: 25}}>{intl.formatMessage({id: 'Soon'})}</p>
          </Row>

          {
            loading === true ?
            <div className="height"> <section {...containerProps}> {indicatorEl} </section></div>
            :
            <Row style={{marginTop: 10, display: 'flex', justifyContent:'space-between'}}>
              {renderAgendaFilter()}
            </Row>
          }
         {/* <MyVerticallyCenteredModal
            show={modalShow}
            list={list}
            setId={setId}
            setLanguage={setLanguage}
            startFilter={startFilter}
            onHide={() => setModalShow(false)}
          />
 */}
        <Container style={{backgroundColor: '#ff5f00', height: 3, width: '20%'}}/>
        </Container>

      <div style={{backgroundColor: '#FFFFFF'}}>
      <Sponsor/>
      </div>
      <Footer/>
      </div>

    </div>
  );
}

export default Agenda;

import React, { useState, useEffect } from 'react';
import './Profil.css'
import { Fade } from "react-awesome-reveal";
import { Button, Row, Container, Col, Image } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { Redirect, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Constant from '../../networking/Constant'
import { useLoading, Rings } from '@agney/react-loading';
import EmptyProfil from '../../image/placeholder_man.png'

import Navigation from '../navBar/NavDark'
import Sponsor from '../sponsor/Sponsor'
import Footer from '../footer/Footer'

import { RiMapPin2Fill } from "react-icons/ri";
import { BsFillBriefcaseFill } from "react-icons/bs";
import { ImPhone } from "react-icons/im";
import { IoMail, IoCalendar } from "react-icons/io5";
import { BiCommentDetail } from "react-icons/bi";
import { useIntl } from 'react-intl';


const cookies = new Cookies();

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.intl.formatMessage({id: 'DeleteAccountTitle'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({id : 'Attention'})}</h4>
        <p style={{marginTop: 25}}>
          {props.intl.formatMessage({id: 'DeleteAccountTxt'})}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={props.onDelete}>OUI</Button>
        <Button style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} onClick={props.onHide}>NON</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Profil(props) {
  const intl = useIntl();

  const { language } = useParams();

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Rings width="100" color="#FC8016" />,
  });

  const [loading, setLoading] = useState(true)
  const [bgd, setBgd] = useState('36.5%')

  const [registrationType, setRegistrationType] = useState(null)

  // Personnal Information

  const [image, setImage] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [zipCode, setZipCode] = useState(null)
  const [city, setCity] = useState(null)
  const [street, setStreet] = useState(null)
  const [tel, setTel] = useState(null)
  const [email, setEmail] = useState(null)
  const [date, setDate] = useState(null)
  const [commentary, setCommentary] = useState(null)

  // Profesionnal Information

  const [society, setSociety] = useState(null)
  const [tva, setTva] = useState(null)
  const [status, setStatus] = useState(null)
  const [sector, setSector] = useState(null)
  const [zipCodePro, setZipCodePro] = useState(null)
  const [cityPro, setCityPro] = useState(null)
  const [streetPro, setStreetPro] = useState(null)
  const [telPro, setTelPro] = useState(null)
  const [emailPro, setEmailPro] = useState(null)

  //Complementary Information

  const [datePro, setDatePro] = useState(null)
  const [localisation, setLocalisation] = useState(null)
  const [sizeOfSociety, setSizeOfSociety] = useState(null)

  const [error, setError] = useState(null)
  const [modalShow, setModalShow] = useState(false);

  const createSector = (data) => {
    if(data === '1')
      return(intl.formatMessage({id: 'Immobilier'}))
    if(data === '2')
      return(intl.formatMessage({id: 'Banques'}))
    if(data === '3')
      return(intl.formatMessage({id: 'Industriel'}))
    if(data === '4')
      return(intl.formatMessage({id: 'It'}))
    if(data === '5')
      return(intl.formatMessage({id: 'Biens'}))
    if(data === '6')
      return(intl.formatMessage({id: 'Commerces'}))
    if(data === '7')
      return(intl.formatMessage({id: 'Avocat'}))
    if(data === '8')
      return(intl.formatMessage({id: 'Consultance'}))
    if(data === '9')
      return(intl.formatMessage({id: 'Autres'}))
  }

  const createSizeOfSociety = (data) => {
    if(data === '-10')
      return(intl.formatMessage({id: 'less10'}))
    if(data === '10/50')
      return(intl.formatMessage({id: 'between10'}))
    if(data === '50/200')
      return(intl.formatMessage({id: 'to50'}))
    if(data === '200')
      return(intl.formatMessage({id: 'to200'}))
  }

  const setInfo = async (data) => {
    setFirstName(setMaj(data.Name.First))
    setLastName(setMaj(data.Name.Last))
    setZipCode(data.Adress.Private.ZipCode)
    setCity(data.Adress.Private.City)
    setStreet(data.Adress.Private.Street)
    setTel(data.Tel.Private)
    setEmail(data.Email.Privates)
    setDate(data.Date)
    setSociety(setMaj(data.Society.Name))
    setTva(data.Society.Tva)
    setCommentary(data.Commentary)
    setStatus(data.Society.Status)
    setSector(data.Society.Sector)
    setStreetPro(data.Adress.Pro.Street)
    setZipCodePro(data.Adress.Pro.ZipCode)
    setCityPro(data.Adress.Pro.City)
    setTelPro(data.Tel.Pro)
    setEmailPro(data.Email.Pro)
    setDatePro(data.Society.Date)
    setLocalisation(data.Society.Localisation)
    setSizeOfSociety(data.Society.SizeOfSociety)
  }

  const setMaj = (string) => {
    try {
      if (string !== null) {
        // Divise la chaîne par les tirets et les espaces
        const newString = string.split(/[-\s]/)
        for (var i = 0; i < newString.length; i++) {
          newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1).toLowerCase();
        }
        // Rejoignez les morceaux avec les tirets et les espaces appropriés
        const newStringJoin = newString.join(" ").replace(/ - /g, "-");
        return newStringJoin;
      }
    } catch (e) {
      return " ";
    }
  };

  const setMajCompanyName = (str) => {
    try{
      const wordsToCapitalize = ["srl", "sa", "sprl", "sc", "asbl"]; // Ajoutez les mots que vous souhaitez transformer en majuscules
      return str.split(' ').map(word => {
        // Met en majuscule la première lettre du mot
        let capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        // Vérifie si le mot doit être entièrement en majuscules
        return wordsToCapitalize.includes(word.toLowerCase()) ? word.toUpperCase() : capitalizedWord;
      }).join(' ');
    }catch(e){
      return(" ")
    }
  };


  const setRegister = (data) => {
    setRegistrationType(data.RegistrationType)
    setImage(data.image)
  }

  const showModalDelete = () => {
    setModalShow(true)
  }

  const deleteAccount = () => {
      fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/DeleteAccount',{
        method:'POST',
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json'},
        body:JSON.stringify({
          Jwt: cookies.get('access')
          })
      }).then(response=>response.json()).then(res=>{
        if(res.error === false){
          alert(intl.formatMessage({id: 'AccountDeleteSucces'}))
          cookies.remove('access', {path: '/'})
          setModalShow(false)
        }
        else {
          alert(intl.formatMessage({id: 'ErrorTryLater'}))
          setModalShow(false)
          
        }
      }).catch((e) => {
        setLoading(false)
        setError({network: "ErrorNetwork"})
      })
  }


  const fetchData = () => {
    fetch(Constant.IP_BACKEND_DNS + '/getMemberInfo',{
      method:'POST',
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json'},
      body:JSON.stringify({accesToken: cookies.get('access'), sizeImage: 'large'})
    }).then(response=>response.json()).then(res=>{
      if(res.error === false){
        setLoading(false)
        setRegister(res.data)
        setInfo(res.data.UserInformation)
      } else {
        setLoading(false)
      }
    }).catch((e) => {
      setLoading(false)
      alert(intl.formatMessage({id: "ErrorNetwork" }))
    })
  }

  const { setLocale } = props;



  useEffect(() => {
    fetchData()
    if (language === "fr") {
      setLocale(language);
    } else if (language === "nl") {
      setLocale(language);
    }
    if (window.screen.width >= 1250)
      setBgd('36.5%')
    else
      setBgd('100%')
},[language, setLocale]);

  const formatName = (lastName) => {
    if (typeof lastName !== 'string' || !lastName.trim()) return "";
    const parts = lastName.trim().split(" ");
    if (parts.length === 1) {
      return lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
    }
    return parts.map((part, index) => {
      if (index === 0 && part.toLowerCase() === "de") {
        return part.toLowerCase();
      } else {
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
      }
    }).join(" ");
  };

  const joinWithComma = (...args) => {
    return args.filter(arg => arg && arg.trim() !== '').join(', ');
  }


  if(cookies.get('access') === undefined)
    return <Redirect push to={`/login/${language}`}/>

  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
      <Navigation/>
        <div style={{backgroundColor: "#F6F7FB"}}>
        <Container  style={{justifyContent:'center', width:bgd, paddingTop: "100px", paddingBottom: 80}}>
          <Row>

          <div style={{}}>
            <h3 style={{textAlign: 'left'}}>{intl.formatMessage({id: 'ProfilMin'})}</h3>
          </div>

          {
            loading === true ?
            <div className="height"> <section {...containerProps}> {indicatorEl} </section></div>
            :
            <div className="shadow" style={{backgroundColor: '#FFFFFF', padding: 15, borderRadius: 5, marginTop: 30}}>

            <Row >
              <Fade>

            {/*Personnal information Section*/}

            <Col style={{textAlign:'left', marginTop: 20}}>

            <div style={{textAlign: 'center'}}>
              <a href={image ? image : EmptyProfil} data-lightbox="photos">
            <Image style={{objectFit: "contain",borderRadius: 10, marginTop: 10, height: '250px'}} fluid src={image ? image : EmptyProfil} />
            </a>
            </div>

            <div style={{textAlign: 'left', marginTop: 25}}>
            <p style={{fontWeight: 'bold', color:'#FC8016'}}>{intl.formatMessage({id: 'MyProfil'})}</p>
            <h5>{(firstName)} {formatName(lastName)}</h5>
            </div>

            <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'flex-start', marginTop: 20}}>
            <div style={{backgroundColor: '#ff5f00', height: 2, width: '20%'}}/>
            </div>

            <p style={{fontWeight: 'bold', color:'#FC8016', marginTop: 20}}>{intl.formatMessage({id: 'PersonalInfo'})}</p>

            <div style={{marginTop: 25}}>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <RiMapPin2Fill size={25}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{joinWithComma(street, zipCode, city)}</p>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <ImPhone size={25}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{tel}</p>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <IoMail size={25}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{email}</p>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <IoCalendar size={25}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{date}</p>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <BiCommentDetail size={25}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{commentary}</p>
              </div>
            </div>

            </div>
            </Col>

            <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'flex-start', marginTop: 20}}>
            <div style={{backgroundColor: '#ff5f00', height: 2, width: '20%'}}/>
            </div>

            {/*Profesionnal information Section*/}

            <Col style={{textAlign:'left', marginTop: 20}}>
            <p style={{fontWeight: 'bold', color:'#FC8016'}}>{intl.formatMessage({id: 'ProInfo'})}</p>

            <div style={{marginTop: 25}}>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <BsFillBriefcaseFill size={25}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>
                {setMajCompanyName(society)} 
                {status && status.trim() !== '' ? ` - ${setMaj(status)}` : ''}
              </p>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <BsFillBriefcaseFill size={25}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{createSector(sector)}</p>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <RiMapPin2Fill size={25}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{streetPro}, {zipCodePro}, {cityPro}</p>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <ImPhone size={25}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{telPro}</p>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <IoMail size={25}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{emailPro}</p>
              </div>
            </div>

            </div>

            <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'flex-start', marginTop: 20}}>
            <div style={{backgroundColor: '#ff5f00', height: 2, width: '20%'}}/>
            </div>

            <p style={{fontWeight: 'bold', color:'#FC8016', marginTop: 20}}>{intl.formatMessage({id: 'ComplInfo'})}</p>

            <div style={{marginTop: 25}}>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
              <p style={{fontWeight: 'bold'}}>{intl.formatMessage({id: 'TypeOfInscription'})}</p>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{registrationType}</p>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
              <p style={{fontWeight: 'bold'}}>{intl.formatMessage({id: 'DateSociety'})}</p>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{datePro === 'null/null/null' ? "" : datePro}</p>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
              <p style={{fontWeight: 'bold'}}>{intl.formatMessage({id: 'Localisation'})}</p>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{localisation}</p>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
              <p style={{fontWeight: 'bold'}}>{intl.formatMessage({id: 'SizeSociety'})}</p>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{}}>{createSizeOfSociety(sizeOfSociety)}</p>
              </div>
            </div>

            </div>

            <Row style={{display: 'flex', marginTop: 50, justifyContent: 'center'}}>
              <Col>
                <Button
                href={`/modify/${language ? language : 'fr'}`}
                style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0, margin: 10}} type="submit">
                {intl.formatMessage({id: 'ModifyProfil'})}
                </Button>
                <Button
                href={`/updatePassword/${language ? language : 'fr'}`}
                style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0, margin: 10}} type="submit">
                {intl.formatMessage({id: 'ModifyPassword'})}
                </Button>
                <Button
                href={`/updateEmail/${language ? language : 'fr'}`}
                style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0, margin: 10}} type="submit">
                {intl.formatMessage({id: 'ModifyEmail'})}
                </Button>
                <Button
               
                onClick={() => showModalDelete()}
                style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0, margin: 10}} type="submit">
                {intl.formatMessage({id: 'DeleteButton'})}
                </Button>
              </Col>
            </Row>

            </Col>
          </Fade>

            </Row>

            </div>
          }
          </Row>

          <MyVerticallyCenteredModal
            show={modalShow}
            error={error}
            intl={intl}
            onDelete={() => deleteAccount()}
            onHide={() => setModalShow(false)}
          />
        </Container>
        </div>
        <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%'}}/>
        <div style={{backgroundColor: '#FFFFFF'}}>
        <Sponsor/>
        </div>
      <Footer/>
    </div>
  );
}

export default Profil;

import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import Constant from '../../networking/Constant'
import { CircularProgress } from '@material-ui/core';
import Navigation from '../navBar/NavDark'
import Footer from '../footer/Footer'
import Sponsor from '../sponsor/Sponsor'
import { useIntl } from 'react-intl';


function ForgetPassword(props) {
  const intl = useIntl();

  const { language } = useParams(); 

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false)

  const [connected, setConnected] = useState(false)
  const [error, setError]= useState('')
  const [bgd, setBgd] = useState('35%')

  const [email, setEmail] = useState("");

  const { setLocale } = props;

  useEffect(() => {
    if (window.screen.width >= 1250){
      setBgd('35%')
    } else {
      setBgd('100%')
    }
    if (language === "fr") {
      setLocale(language);
    } else if (language === "nl") {
      setLocale(language);
    }
}, [language, setLocale]);


  const sendRequestRecoverPassword = () => {
    setLoading(true)

      fetch(Constant.IP_BACKEND_DNS_CLIENT + '/users/ForgetPassword/First/Step',{
        method:'POST',
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json'},
        body:JSON.stringify({Email: email.trim()})
      }).then(response=>response.json()).then(res=>{
        if(res.error === true){
          setLoading(false)
          if(res.message === "Already asked to change password")
            props.history.push({pathname: `/forgetPasswordToken/${language}`, state:{email : email} })
          if(res.message === "Email not valid")
            setError('EmailInvalid')
          if(res.message === "No Id found")
            setError('EmailNotExist')
          //setError({info : res.message})
        }
        else if(res.error === false){
          setLoading(false)
          props.history.push({pathname: `/forgetPasswordToken/${language}`, state:{email : email}})
        }
      }).catch((e) => {
        setLoading(false)
        setError("ErrorNetwork")
      })
    }



  const handleSubmit = (event) => {
    event.preventDefault();
    //clear()
    sendRequestRecoverPassword()
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
  };


  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
      <Navigation/>
      <Fade>

      <div style={{backgroundColor: "#F6F7FB", paddingTop: "100px"}}>
        <Container className="shadow" style={{width:bgd, backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
        <h2 style={{textAlign: 'left'}}>{intl.formatMessage({id: 'MotDePasseOublieTitre'})}</h2>
        <p style={{textAlign: 'left'}}>{intl.formatMessage({id: 'MotDePasseOublieMessage'})}</p>
        <p style={{color:'red', fontWeight:'bold', marginTop: 20}}>{intl.formatMessage({id: error ? error : "Space" })}</p>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="formGridlastName" style={{marginTop: 20}}>
              <Form.Control
              required
              type="e-mail"
              placeholder={intl.formatMessage({id: 'EntrezEmail'})}
              value={email}
              onChange={(event) => setEmail(event.target.value)}/>
            </Form.Group>
          </Row>
          <Row>
          </Row>

          <Button
          style={{margin: 20, marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}} type="submit">
          {
            loading === true ?
            <div style={{position: 'relative'}}>
              <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                <CircularProgress color="white" size={20}/>
              </div>
              <div>
                <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: 'Continuer'})}</p>
              </div>
            </div>
            :
            <p style={{margin: 0}}>{intl.formatMessage({id: 'Continuer'})}</p>
          }
          </Button>
        </Form>

        </Container>
          <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%', marginTop: 80}}/>
        </div>

        <div style={{backgroundColor: '#FFFFFF'}}>
        <Sponsor/>
        </div>

      <Footer/>

      </Fade>
    </div>
  );
}

export default ForgetPassword;

import React from "react";
import { Row, Container, Col, Button, Image } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Cookies from "universal-cookie";
import Boost from "../../image/ThumbLogo.png";
import { FaArrowRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import {
  FacebookIcon,
  LinkedinIcon,
} from "react-share";


const cookies = new Cookies();

function Language(props) {
  const history = useHistory();
  const createCookie = (name, value) => {
    cookies.set(name, value, {
      sameSite: "strict",
      path: "/",
      expires: new Date(new Date().getTime() + 60 * 60 * 24 * 31 * 12 * 1000),
    });
  };

  const ChangeLanguage = (lg) => {
    props.setLocale(lg);
    props.info === true ? props.changePage() : console.log("");
    history.push(`/agenda/language/${lg}`);
  };

  return (
    <div style={{ fontFamily: "Typold Regular", paddingTop: "70px" }}>
      <Container>
        <Row>
          <Col>
            <LazyLoadImage
              style={{ width: "150px", objectFit: "contain" }}
              alt="Boost_your_business"
              src={Boost}
            />
            <h1
              className="businessCorporateClub"
              style={{ margin: 0, paddingTop: "20px", paddingBottom: "20px" }}
            >
              BUSINESS CLUB
            </h1>
          </Col>
        </Row>
      </Container>

      <Container style={{ marginTop: "80px" }}>
        <Row>
          <Col>
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => ChangeLanguage("nl")}
              >
                <h2 style={{ fontWeight: "bold", padding: "50px" }}>
                  IK SPREEK NEDERLANDS
                </h2>
                <FaArrowRight size={25} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => ChangeLanguage("fr")}
              >
                <h2 style={{ fontWeight: "bold", padding: "50px" }}>
                  JE PARLE FRANCAIS
                </h2>
                <FaArrowRight size={25} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <div
              style={{
                marginTop: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: '15px'
              }}
            >
              <h4>FOLLOW US</h4>

              <a
                style={{ marginLeft: "15px" }}
                href=" https://www.facebook.com/B19BusinessClub"
              >
                <FacebookIcon size={45} round />
              </a>
              <a
                style={{ marginLeft: "15px" }}
                href="https://www.linkedin.com/company/b19-country-club/"
              >
                <LinkedinIcon size={45} round />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    region : state._state_reducer_home_page.region
  }
}


export default connect(mapStateToProps)(Language)


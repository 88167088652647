import { useEffect } from 'react';

const RedirectComponent = () => {
  useEffect(() => {
    window.location.replace('https://b19.be');
  }, []);

  return null;
};

export default RedirectComponent;
import React, { useState } from 'react';
import { Fade } from "react-awesome-reveal";
import { Row, Container} from 'react-bootstrap';
import Navigation from '../navBar/NavDark'
import Footer from '../footer/Footer'
import arrow from '../../image/ic_arrow_down_white.png'
import AnimateHeight from 'react-animate-height';
import { useIntl } from 'react-intl';
import './DeleteAccount.css'


function Faq() {
  const intl = useIntl();

  const [height, setHeight] = useState(0)
  const [height1, setHeight1] = useState(0)
  const [height2, setHeight2] = useState(0)
  const [height3, setHeight3] = useState(0)
  const [height4, setHeight4] = useState(0)
  const [height5, setHeight5] = useState(0)

  const telNumber = {
    bxl: "+32 2 375 00 09",
    luik: "+32 497 246 913",
    lux: "+352 621 356 450",
    fl: "+32 2 375 00 09",
  }

  const open = () => {
    setHeight(height === 0 ? 'auto' : 0)
  }

  const open1 = () => {
    setHeight1(height1 === 0 ? 'auto' : 0)
  }

  const open2 = () => {
    setHeight2(height2 === 0 ? 'auto' : 0)
  }

  const open3 = () => {
    setHeight3(height3 === 0 ? 'auto' : 0)
  }

  const open4 = () => {
    setHeight4(height4 === 0 ? 'auto' : 0)
  }

  const open5 = () => {
    setHeight5(height5 === 0 ? 'auto' : 0)
  }

  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
    <Navigation/>
      <Fade>
      <div style={{backgroundColor: "#F6F7FB", paddingTop: "100px"}}>
        <Container style={{backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
        <corps>
        <h2 id="ValidationWaitingSection">Validation pending</h2>
        <p>The process of registering your information takes only a few seconds. On our end, we make every effort to process your account as quickly as possible, often within hours. However, there are times when this may take longer due to the large number of requests. We apologize for this. Once your account is validated, you will be notified by e-mail.</p>
        <Row>
        <div className="shadow filtre" style={{width: "100%",  border: 0, marginTop: 15, borderRadius: 5, margin: '2px'}}>
          <button className="filtre" onClick={() => open3()} style={{width: "100%",  border: 0,  borderRadius: 5}}>
            <div  style={{display: "flex", flexDirection: 'row', alignItems: 'center', marginLeft: 5}}>
            <p  style={{ textAlign: 'left', padding: 10, margin: 0, color: "white", fontWeight: "bold"}}>WHY IS MY ACCOUNT PENDING VALIDATION?</p>
            <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 5}}>
              {
                height3 === 0 ?
                <img  src={arrow} style={{width:"20px"}}/>
                :
                <img class="rotate90" src={arrow} style={{width:"20px"}}/>
              }
            </div>
            </div>
          </button>
          <AnimateHeight
            id="example3"
            duration={500}
            height = {height3}>
            <p style={{color: "white"}}>
            If your account is pending validation, it may be due to several reasons:<br/><br/>
            - Your request has still not been processed.<br/>
            - You have not yet received the invoice.<br/>
            - You have not yet paid the annual fee.<br/>
            </p>
          </AnimateHeight>
        </div>
        </Row>

    <h2 style={{marginTop: "4rem"}}>Cancel my registration request</h2>
    <p>When you'll delete your registration request, it will permanently delete all personal information associated with it.</p>

    <Row>
    <div   className="shadow filtre" style={{width: "100%",  border: 0, marginTop: 15, borderRadius: 5, margin: '2px'}}>
      <button className="filtre" onClick={() => open4()} style={{width: "100%",  border: 0,  borderRadius: 5}}>
        <div  style={{display: "flex", flexDirection: 'row', alignItems: 'center', marginLeft: 5}}>
        <p  style={{ textAlign: 'left', padding: 10, margin: 0, color: "white", fontWeight: 'bold'}}>HOW LONG DOES IT TAKE TO DELETE MY REGISTRATION REQUEST ?</p>
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 5}}>
          {
            height4 === 0 ?
            <img  src={arrow} style={{width:"20px"}}/>
            :
            <img class="rotate90" src={arrow} style={{width:"20px"}}/>
          }
        </div>
        </div>
      </button>
      <AnimateHeight
        id="example4"
        duration={500}
        height = {height4}>
        <p style={{color: "white"}}>The deletion of the registration request is instantaneous.</p>
      </AnimateHeight>
    </div>
    </Row>
    <Row>
    <div className="shadow filtre" style={{width: "100%",  border: 0, marginTop: 15, borderRadius: 5, margin: '2px'}}>
      <button className="filtre" onClick={() => open5()} style={{width: "100%",  border: 0,  borderRadius: 5}}>
        <div  style={{display: "flex", flexDirection: 'row', alignItems: 'center', marginLeft: 5}}>
        <p  style={{ textAlign: 'left', padding: 10, margin: 0, color: "white", fontWeight: 'bold'}}>HOW DO I REQUEST THE DELETION OF MY REGISTRATION REQUEST ?</p>
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 5}}>
          {
            height5 === 0 ?
            <img  src={arrow} style={{width:"20px"}}/>
            :
            <img class="rotate90" src={arrow} style={{width:"20px"}}/>
          }
        </div>
        </div>
      </button>
      <AnimateHeight
        id="example3"
        duration={500}
        height = {height5}>
        <p style={{color: "white"}}>
        The process is very simple. If you are sure you want to do it, just follow <a href="/deleteSub" style={{color:"black", fontWeight:"bold"}}> this link to begin. </a>Then, follow the instructions
        </p>
      </AnimateHeight>
    </div>
    </Row>

    <h2 id="DeleteAccountSection" style={{marginTop: "4rem"}}>Delete account</h2>
    <p>When you delete your account it will permanently delete all personal information associated with it. You will no longer be able to access your account and this operation is irrevocable.</p>
    <Row>
    <div className="shadow filtre" style={{width: "100%",  border: 0, marginTop: 15, borderRadius: 5, margin: '2px'}}>
      <button className="filtre" onClick={() => open()} style={{width: "100%",  border: 0,  borderRadius: 5}}>
        <div  style={{display: "flex", flexDirection: 'row', alignItems: 'center', marginLeft: 5}}>
        <p  style={{ textAlign: 'left', padding: 10, margin: 0, color: "white", fontWeight: 'bold'}}>HOW LONG DOES IT TAKE TO DELETE AN ACCOUNT ?</p>
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 5}}>
          {
            height === 0 ?
            <img  src={arrow} style={{width:"20px"}}/>
            :
            <img class="rotate90" src={arrow} style={{width:"20px"}}/>
          }
        </div>
        </div>
      </button>
      <AnimateHeight
        id="example"
        duration={500}
        height = {height}>
        <p style={{color: "white"}}>The deletion of the account is instantaneous.</p>
      </AnimateHeight>
    </div>

    </Row>
    <Row>
    <div className="shadow filtre" style={{width: "100%",  border: 0, marginTop: 15, borderRadius: 5, margin: '2px'}}>
      <button className="filtre" onClick={() => open1()} style={{width: "100%",  border: 0,  borderRadius: 5}}>
        <div  style={{display: "flex", flexDirection: 'row', alignItems: 'center', marginLeft: 5}}>
        <p  style={{ textAlign: 'left', padding: 10, margin: 0, color: "white", fontWeight: 'bold'}}>HOW DO I REQUEST THE DELETION OF MY ACCOUNT ?</p>
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 5}}>
          {
            height1 === 0 ?
            <img  src={arrow} style={{width:"20px"}}/>
            :
            <img class="rotate90" src={arrow} style={{width:"20px"}}/>
          }
        </div>
        </div>
      </button>
      <AnimateHeight
        id="example1"
        duration={500}
        height = {height1}>
        <p style={{color: "white"}}>
        Deleting your account is a big decision, but the process is very simple.<br/><br/>
        If you are sure you want to do this, simply log into your account from the app or the B19 website to get started.<br/><br/>
        Then go to the profile section and press the "Delete my account" button.<br/><br/>
        Finally, be aware that deleting your account will delete all your data.
        </p>
      </AnimateHeight>
    </div>
    </Row>

    </corps>

    </Container>
    <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%', marginTop: 80}}/>
    </div>

    <Footer/>
    </Fade>
    </div>
  );
}

export default Faq;

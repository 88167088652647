import { useState, useEffect } from "react";
import { Row, Container, Col } from 'react-bootstrap';
import Navigation from '../navBar/NavDark'
import Footer from '../footer/Footer'
import axios from  'axios'
import { useLoading, Rings } from '@agney/react-loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Constant from '../../networking/Constant'
import { useIntl } from 'react-intl';
import { Fade } from "react-awesome-reveal";
import qs from 'qs';
import Sponsor from '../sponsor/Sponsor'
import './Gallery.css'
import { useParams } from 'react-router-dom'

function GalleryImage(props) {
  const intl = useIntl();

  const { language } = useParams();

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Rings width="100" color="#FC8016" />,
  });

  const [loading, setLoading] = useState(true)
  const [image, setImage] = useState(null)
  const [lastKey, setLastKey] = useState(null)
  const url = "https://api.unsplash.com/photos/random?client_id=QckWGK7oBP5iHrOBALQzKtcfu6T_ULXzi_Bd9LdJnuU&count=100"

  const data = { 'NumberOfResult': 20,  'LastKey': lastKey, 'Language': language ? language : "fr"};
  const options = {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'},
    data: qs.stringify(data),
    url,
  };

  const getTest = async (lg) => {
    setLoading(true)
    await axios({
        method: 'post',
        url: Constant.IP_BACKEND_DNS_CLIENT + '/users/GetGallery/',
        data: {Language: language || 'ALL',
        Target: language ? (language === 'nl' ? 'NL' : 'FR') : 'ALL'
       }
    })
    .then(function (reponse) {
        setLoading(false)
        setImage(reponse.data.data)
    })
    .catch(function (erreur) {
      setLoading(false)
      alert(intl.formatMessage({id: "ErrorHappened"}))
    });
}

const { setLocale } = props;

  useEffect(() => {
    getTest(intl.locale)
    if (language === "fr") {
      setLocale(language);
    } else if (language === "nl") {
      setLocale(language);
    }
  }, [intl.locale, language, setLocale])

  return (
    <div className="App" style={{fontFamily: 'Typold Regular'}}>
    <Navigation/>

    <div style={{paddingTop: "100px", backgroundColor: "#F6F7FB"}}>


    {
      loading === true ?
      <div className="height"> <section {...containerProps}> {indicatorEl} </section></div>
      :
      <>
      <Container>
        <h3 style={{textAlign: 'left'}}>{intl.formatMessage({id: "GaleriePhoto" })}</h3>
      </Container>
      <Container>
        <Row style={{marginTop: 30}}>
          {image && image.map((img, index) => {
            return(
                <Col xl={4} lg={4} md={12} sm={12} key={index}>
                  <Fade>
                  <a onClick={() => props.history.push({pathname: `/consultingAlbum/${language ? language : "fr"}/${img.Id}`, state: [{data: {img} }] }) } style={{color: 'black', textDecoration: 'none'}}>
                    <LazyLoadImage
                      effect="blur"
                      src={img.Image}
                      alt={img.Image}
                      key={img.Id}
                      style={{padding: 20, objectFit: "cover", width:'100%', borderRadius: 25}}>
                    </LazyLoadImage>
                  </a>
                  <p className="texteName" style={{fontWeight: 'bold'}}>{img.Name}</p>
                  <p style={{fontWeight: 'bold'}}>{img.City}</p>
                  <p>{img.Date}</p>
                  </Fade>
                </Col>
            )
          })}
        </Row>
      </Container>
      </>
    }
  </div>
  <div style={{backgroundColor: '#FFFFFF'}}>
  <Sponsor/>
  </div>
      <Footer/>
    </div>
  );
}

export default GalleryImage;

import { createStore, combineReducers } from 'redux'

import _state_reducer_home_page from './ReducerHomePage'

const allReducers = combineReducers({
    _state_reducer_home_page,
})

const store = createStore(allReducers)

export default store

import React, { useState, useEffect } from "react";
import "./Login.css";
import { Fade } from "react-awesome-reveal";
import { Button, Row, Container, Col, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Redirect, useParams, useHistory } from "react-router-dom";
import Constant from "../../networking/Constant";
import { CircularProgress } from "@material-ui/core";
import Navigation from "../navBar/NavDark";
import Footer from "../footer/Footer";
import Sponsor from "../sponsor/Sponsor";
import fetch from "node-fetch";
import Cookies from "universal-cookie";
import { loadStripe } from "@stripe/stripe-js";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Request from "../../networking/Config";

const stripePromiseBxl = loadStripe(
  "pk_live_51J4hxHL0SWLMklZ3E53r2s856WjJNqxvY0ElyxyQQm8c62OAXkBDqKc1AmuN9auWfldnDdV0Oe0uCjYqR03Ffo2100uIY1TDh3"
);
//const stripePromise = loadStripe('pk_test_51J4hxHL0SWLMklZ3MgLaZGEqlDloUu4AMByjzBiI9d4PtY8idpytAHddFYCbs40FmSyx99pMmDHT9c6xcSrv4Xic00kTljgnSm');
const stripePromiseLuik = loadStripe(
  "pk_live_51JCOAJEbzcAf2J36UHWIEnEMW0euy8JWvuhjLUtvAfq3iP5K2UqsKuhS7FECNvCfEwS8IZhW8vCbPyCxkq0mWnUt00BjxzCUih"
);
const cookies = new Cookies();

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.intl.formatMessage({ id: "Facturation" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.intl.formatMessage({ id: "Send" })}</h4>
        <p style={{ marginTop: 25 }}>
          {props.intl.formatMessage({ id: "FacturationSend" })}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            fontWeight: "bold",
            backgroundColor: "#FC8016",
            borderWidth: 0,
          }}
          onClick={props.onHide}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Login(props) {
  const intl = useIntl();

  const { language, idEvent } = useParams(); // 'eventId' correspond au segment de l'URL
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [connected, setConnected] = useState(false);
  const [modifyProfil, setModifyProfil] = useState(false);
  const [error, setError] = useState("");
  const [bgd, setBgd] = useState("35%");
  const [buttonPayment, setButtonPayment] = useState(false);
  const [accesToken, setAccesToken] = useState("");
  const [paymentToken, setPaymentToken] = useState("");
  const [single, setSingle] = useState(false);
  const [business, setBusiness] = useState(false);
  const [registrationType, setRegistrationType] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [region, setRegion] = useState(null);
  const [accept, setAccept] = useState(false);
  const [id, setId] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (cookies.get("access")) setConnected(true);
    else setConnected(false);
  });

  useEffect(() => {
    if (language === "fr") {
      props.setLocale(language);
    } else if (language === "nl") {
      props.setLocale(language);
    }
  }, [language]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const createCookie = (name, value, region) => {
    localStorage.setItem("region", region);
    cookies.set(name, value, {
      sameSite: "strict",
      path: "/",
      expires: new Date(new Date().getTime() + 60 * 60 * 24 * 7 * 1000),
    });
  };

  const pressEnter = (event) => {
    if (event.key === "Enter") connexion();
  };

  const createAcces = (acces, region) => {
    createCookie("access", acces, region);
  };

  const clear = () => {
    setError("");
    setButtonPayment(false);
    setSingle(false);
    setBusiness(false);
    setAccesToken("");
    setPaymentToken("");
  };

  const connexion = () => {
    clear();
    setLoading(true);

    // Request.defaults.headers['Authorization'] = "Basic " + Buffer.from(email + ":" + password).toString("base64");
    /*Request.post('/login', {email: email, password : password})
    .then(res => {
      console.log("pp", res)
    }).catch((e) => {
      console.log("edzfdsfsfd",e.response.data)
    })*/

    fetch(Constant.IP_BACKEND_DNS + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email: email, password: password }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === false) {
          createAcces(res.data.accesToken, res.data.region);
          if (res.data.firstTime === true) setModifyProfil(true);
          else {
            if (idEvent) {
              // Redirigez vers la page de l'événement
              history.push(`/agenda/${language}/${idEvent}`);
            }
            setConnected(true);
          }
        } else {
          setLoading(false);
          if (res.region) {
            setRegion(res.region);
            setId(res.id);
            setAccept(res.accept);
          }
          if (res.message.email) {
            setError(res.message.email);
            return;
          }
          if (res.message.password) {
            setError(res.message.password);
            return;
          }
          if (
            res.message.payment === "NotApproved" ||
            res.message.payment === "BusinessNotApproved"
          ) {
            setAccesToken(res.data.accesToken);
            setError(res.message.payment);
            setSingle(true);
            setButtonPayment(true);
            setRegistrationType(res.data.registrationType);
            setPaymentToken(res.paymentToken);
          }
          if (res.message.payment === "BusinessNotApproved") {
            setAccesToken(res.data.accesToken);
            setError(res.message.payment);
            setButtonPayment(true);
            setRegistrationType(res.data.registrationType);
            setBusiness(true);
            setPaymentToken(res.paymentToken);
          }
          if (res.message.payment === "CoupleNotApproved") {
            setAccesToken(res.data.accesToken);
            setError(res.message.payment);
            setButtonPayment(true);
            setRegistrationType(res.data.registrationType);
            setBusiness(true);
            setPaymentToken(res.paymentToken);
          }
          if (
            res.message.payment === "NotApproved" ||
            res.message.payment === "ErrorContactStaff"
          )
            setError(res.message.payment);
          if (res.message.payment === "BusinessNotPay")
            setError(res.message.payment);
          if (res.message.payment === "CoupleNotPay")
            setError(res.message.payment);
          if (res.message.payment === "NotPay") setError(res.message.payment);
          return;
        }
      })
      .catch((e) => {
        setLoading(false);
        setError("ErrorNetwork");
      });
  };

  const payment = async () => {
    setLoadingPayment(true);
    var type = "";
    if (single === true) type = "Single";
    else if (business === true) type = "Business";
    else {
      setError("ErrorHappened");
      return;
    }
    var url = accept === false ? "/payment" : "/paymentSubscription";
    var body =
      accept === false
        ? {
            email: email,
            accesToken: accesToken,
            registrationType: registrationType,
            region: region,
          }
        : { id: id };
    const response = await fetch(Constant.IP_BACKEND_DNS + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });
    const session = await response.json();
    if (session.error === true) {
      if (url === "/payment") {
        setLoadingPayment(false);
        alert(intl.formatMessage({ id: "ErrorHappened" }));
        return;
      } else {
        setLoadingPayment(false);
        alert(intl.formatMessage({ id: "ErrorHappened" }));
        return;
      }
    }
    let stripe = null;
    setLoadingPayment(false);
    if (
      session.region === "bxl" ||
      session.region === "anv" ||
      session.region === "fl"
    ) {
      stripe = await stripePromiseBxl;
    }
    if (session.region === "luik") {
      stripe = await stripePromiseLuik;
    }
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      setLoadingPayment(false);
      alert(intl.formatMessage({ id: result.error }));
    }
  };

  const billing = async () => {
    fetch(Constant.IP_BACKEND_DNS + "/updateRegisterStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        paymentToken: paymentToken,
        status: "pending",
        method: "Billing",
        registrationType: registrationType,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === false) {
          setModalShow(true);
          clear();
        } else {
          alert("ErrorStaff");
        }
      });
  };

  useEffect(() => {
    if (window.screen.width >= 1250) {
      setBgd("35%");
    } else {
      setBgd("100%");
    }
  }, []);

  if (modifyProfil === true) {
    return <Redirect push to={`/modify/${language ? language : "fr"}`} />;
  }
  if (connected === true && idEvent) {
    return (
      <Redirect push to={`/agenda/${language ? language : "fr"}/${idEvent}`} />
    );
  }
  if (connected === true) {
    return (
      <Redirect push to={`/agenda/language/${language ? language : "fr"}`} />
    );
  }

  return (
    <div className="App" style={{ fontFamily: "Typold Regular" }}>
      <Navigation />
      <Fade>
        <div style={{ backgroundColor: "#F6F7FB", paddingTop: 130 }}>
          <Container
            className="shadow"
            style={{
              width: bgd,
              backgroundColor: "#FFFFFF",
              borderRadius: 5,
              padding: 50,
            }}
          >
            <h2 style={{ textAlign: "left", marginLeft: 15 }}>
              {intl.formatMessage({ id: "ConnexionTitle" })}
            </h2>
            <p style={{ color: "red", fontWeight: "bold", marginTop: 25 }}>
              {intl.formatMessage({ id: error ? error : "Space" })}
            </p>

            {/*
        {
          buttonPayment === true ?
          <Row>
            {
              region === "lux" ?
              <>
              </>
              :
              <Col style={{marginTop: 25, marginBottom: 25}}>
                <Button
                  onClick={() => payment()}
                  variant="outline-dark" style={{backgroundColor: '#082331', color: "white", fontWeight: 'bold', padding: 10}}>
                  {
                    loadingPayment === true ?
                    <div style={{position: 'relative'}}>
                      <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                        <CircularProgress color="white" size={20}/>
                      </div>
                      <div>
                        <p style={{margin: 0, color: 'transparent'}}>{intl.formatMessage({id: 'PayerEnLigne'})}</p>
                      </div>
                    </div>
                    :
                    <p style={{margin: 0}}>{intl.formatMessage({id: 'PayerEnLigne'})}</p>
                  }
                </Button>
              </Col>
            }
            <Col style={{marginTop: 25, marginBottom: 25}}>
              <Button
                onClick={() => billing()}
                variant="outline-dark" style={{backgroundColor: '#082331', color: "white", fontWeight: 'bold', padding: 10}}>
                FACTURE
              </Button>
            </Col>
        </Row>
          :
          <>
          </>
        }
          */}

            <MyVerticallyCenteredModal
              show={modalShow}
              intl={intl}
              onHide={() => setModalShow(false)}
            />
            <Form>
              <Row>
                <Form.Group
                  as={Col}
                  controlId="formGridEmail"
                  style={{ padding: 25 }}
                >
                  <Form.Control
                    type="email"
                    placeholder={intl.formatMessage({ id: "PlaceHolderLogin" })}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  controlId="formGridPassword"
                  style={{ padding: 25 }}
                >
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      onKeyPress={(event) => pressEnter(event)}
                      type={showPassword ? "text" : "password"}
                      placeholder={intl.formatMessage({
                        id: "PlaceHolderPassword",
                      })}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: 10,
                        top: "50%",
                        transform: "translateY(-50%)",
                        border: "none",
                        padding: 0,
                        background: "none",
                        outline: "none",
                        boxShadow: "none",
                        color: 'black'
                      }}
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                      />
                    </Button>
                  </div>
                </Form.Group>
              </Row>
              <Button
                href={`/subscription/${language}`}
                style={{
                  margin: 20,
                  marginTop: 50,
                  fontWeight: "bold",
                  backgroundColor: "#FC8016",
                  borderWidth: 0,
                }}
              >
                {intl.formatMessage({ id: "DevenirMembre" })}
              </Button>
              <Button
                style={{
                  margin: 20,
                  marginTop: 50,
                  fontWeight: "bold",
                  backgroundColor: "#FC8016",
                  borderWidth: 0,
                }}
                onClick={() => connexion()}
              >
                {loading === true ? (
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                    >
                      <CircularProgress color="white" size={20} />
                    </div>
                    <div>
                      <p style={{ margin: 0, color: "transparent" }}>
                        {intl.formatMessage({ id: "SeConnecter" })}
                      </p>
                    </div>
                  </div>
                ) : (
                  <p style={{ margin: 0 }}>
                    {intl.formatMessage({ id: "SeConnecter" })}
                  </p>
                )}
              </Button>
            </Form>

            <Col style={{ marginTop: 25, padding: 5 }}>
              <Button
                href={`/forgetPassword/${language ? language : "fr"}`}
                variant="link"
                style={{ color: "black" }}
              >
                {intl.formatMessage({ id: "ForgetPassword" })}
              </Button>
            </Col>
          </Container>
          <Container
            style={{
              backgroundColor: "#ff5f00",
              height: 2,
              width: "20%",
              marginTop: 80,
            }}
          />
        </div>

        <div style={{ backgroundColor: "#FFFFFF" }}>
          <Sponsor />
        </div>

        <Footer />
      </Fade>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    region: state._state_reducer_home_page.region,
  };
};

export default connect(mapStateToProps)(Login);
